import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import ErrorPage from './ErrorPage';

interface Props {
  analyticsClient: AnalyticsClient;
}

interface State {
  error: any | null;
}

/**
 * Stolen from https://docs.sentry.io/platforms/javascript/react/
 */
export default class ErrorBoundary extends Component<Props, State> {
  static pageId = 'unexpectedErrorPage';
  state: State = { error: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    this.props.analyticsClient.operationalEvent({
      page: ErrorBoundary.pageId,
      action: 'loadPageFailure',
      subject: 'sli',
      attributes: {
        errorName: error.name,
      },
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPage title="Something went wrong" />;
    }
    return this.props.children;
  }
}
