import React, { FormEvent } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';
import Helmet from 'react-helmet';

import { State as MainState } from '../reducers/index';

import {
  Dispatch,
  URLParameters,
  InjectedAnalyticsProps,
  InjectedRouterProps,
  ChangePasswordFieldErrors,
  CsrfToken,
  MessageDescriptor,
  ChangePasswordError,
} from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import messages from './ChangePasswordPage.messages';
import queryString from 'query-string';
import TallButton from '../components/TallButton';
import { captureErrorMessage } from '../utilities/analytics/error-reporting';
import WhiteboxPage from '../components/WhiteboxPage';
import PasswordField from '../components/PasswordField';
import {
  scoreWordSplitter,
  AsyncLoadedPasswordStrengthMeter,
} from '../components/PasswordStrengthMeter';
import FieldGroup from '../components/FieldGroup';
import styled from '@emotion/styled';
import { ChangePasswordType, requestChangePassword } from '../actions/change-password-actions';
import {
  SupportLink,
  UserNoticeLink,
  PrivacyPolicyLink,
  SELF_SERVICE_LINK_URL,
  PASSWORD_LEAKED_LINK,
} from '../components/links';
import FormError from '../components/FormError';
import { getMicrobrandingFromUrl } from '../utilities/applications';
import { gridSize } from '@atlaskit/theme';
import trustAndSecurity from '../images/trust-and-security.svg';
import Bull from '../components/BulletSeparator';

const TRANSITION_DURATION = 400;
const PasswordFieldGroup = styled(FieldGroup)`
  height: auto;
`;

const SecurityContainer = styled.div`
  background: url(${trustAndSecurity}) no-repeat center;
  height: ${gridSize() * 14.5}px;
  transition: visibility ${TRANSITION_DURATION}ms;
  margin-bottom: ${gridSize() * 1.5}px;
`;

export type ChangePasswordMessage = 'bitbucket_migration';
export type ChangeEmailFlowMessage = 'change_email';
export type SecurityWasMeForcedResetPasswordMessage = 'security_wasme_reset';
export type SecurityWasNotMeForcedResetPasswordMessage = 'security_wasnotme_reset';

const knownMessageKeys: URLParameters['message'][] = [
  'change_email',
  'bitbucket_migration',
  'security_wasme_reset',
  'security_wasnotme_reset',
];

interface OwnProps {}

interface PropsFromState {
  email: string;
  fieldErrors: ChangePasswordFieldErrors;
  error?: ChangePasswordError;
  isLoading: boolean;
  csrfToken: CsrfToken;
}

interface PropsFromDispatch {
  changePassword: (args: { csrfToken: string; password: string }) => void;
  dispatch: Dispatch;
}

function chooseTitle(message: URLParameters['message']): MessageDescriptor {
  switch (message) {
    case 'bitbucket_migration':
      return messages.bitbucketMigrationTitle;
    case 'security_wasme_reset':
      return messages.securityResetTitle;
    case 'security_wasnotme_reset':
      return messages.securityResetTitle;
    default:
      return messages.title;
  }
}

function chooseParagraph(message: URLParameters['message']): MessageDescriptor | undefined {
  switch (message) {
    case 'bitbucket_migration':
      return messages.bitbucketMigrationParagraph;
    default:
      return undefined;
  }
}

function chooseButtonText(message: URLParameters['message']): MessageDescriptor {
  switch (message) {
    case 'bitbucket_migration':
      return messages.bitbucketMigrationButton;
    case 'security_wasme_reset':
      return messages.button;
    case 'security_wasnotme_reset':
      return messages.button;
    default:
      return messages.button;
  }
}

function chooseAnalyticsEventName(message: URLParameters['message']): string {
  switch (message) {
    case 'bitbucket_migration':
      return 'bitbucketMigration';
    case 'security_wasme_reset':
      return 'suspiciousWasUser';
    case 'security_wasnotme_reset':
      return 'suspiciousWasNotUser';
    case 'change_email':
      return 'changeEmail';
    default:
      return 'userRequest';
  }
}

function chooseChangePasswordType(message: URLParameters['message']): ChangePasswordType {
  switch (message) {
    case 'security_wasme_reset':
      return 'security-password-reset';
    case 'security_wasnotme_reset':
      return 'security-password-reset';
    default:
      return 'password-reset';
  }
}

const BitbucketMigrationParagraph = styled.p`
  margin-bottom: ${gridSize() * 2}px;
`;

type InjectedProps = InjectedIntlProps & InjectedAnalyticsProps & InjectedRouterProps;

type Props = OwnProps & PropsFromState & PropsFromDispatch & InjectedProps;

interface State {
  password: string;
}

export class ChangePasswordPage extends React.Component<Props, State> {
  static pageId = 'setNewPasswordScreen';
  passwordInput: PasswordField | null | undefined;

  constructor(props: Props) {
    super(props);

    const query = this.getQueryParams();
    this.props.analyticsClient.pageViewedEvent(ChangePasswordPage.pageId, {
      origin: chooseAnalyticsEventName(query.message),
    });

    this.state = {
      password: '',
    };

    if (query.message && !knownMessageKeys.includes(query.message)) {
      captureErrorMessage('change_password_missing_error_message', { key: query.message });
    }
  }

  getQueryParams = () => {
    return queryString.parse(this.props.location.search) as URLParameters;
  };

  renderPasswordChangeError = error => {
    const { formatMessage } = this.props.intl;

    if (error.type === 'PASSWORD_LEAKED') {
      return (
        <FormattedHTMLMessage
          {...messages.passwordLeaked}
          values={{ passwordLeakedLink: PASSWORD_LEAKED_LINK }}
        />
      );
    } else {
      return error.message ? error.message : formatMessage(messages.errorMessage);
    }
  };

  renderWhiteboxFooter = () => {
    return (
      <a
        id="changepassword-selfservice-link"
        href={SELF_SERVICE_LINK_URL}
        onClick={() =>
          this.props.analyticsClient.linkClickedEvent(
            ChangePasswordPage.pageId,
            'stillHavingTroubleLogin'
          )
        }>
        <FormattedMessage
          id="changepassword.self.service"
          defaultMessage="Still having trouble logging in?"
        />
      </a>
    );
  };

  renderFooter = () => {
    return (
      <ul>
        <li>
          <SupportLink />
        </li>
        <Bull />
        <li>
          <UserNoticeLink isMobileLink={false} />
        </li>
        <Bull />
        <li>
          <PrivacyPolicyLink isMobileLink={false} />
        </li>
      </ul>
    );
  };

  handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const query = this.getQueryParams();
    this.props.analyticsClient.buttonClickedEvent(
      ChangePasswordPage.pageId,
      'continueToSetNewPassword',
      { origin: chooseAnalyticsEventName(query.message) }
    );

    const { password } = this.state;
    const { csrfToken } = this.props;

    this.props.changePassword({ csrfToken, password });
  };

  render() {
    const { email, intl, isLoading, location, error } = this.props;
    const { formatMessage, formatHTMLMessage } = intl;

    const query = queryString.parse(location.search) as URLParameters;
    const title = formatMessage(chooseTitle(query.message));
    const paragraphMessage = chooseParagraph(query.message);
    const paragraph: string | undefined = paragraphMessage
      ? formatMessage(paragraphMessage)
      : undefined;
    const securityResetWasMeHtml = formatHTMLMessage(messages.securityWasMeParagraph, {
      email,
    });
    const securityResetWasNotMeHtml = formatHTMLMessage(messages.securityWasNotMeParagraph, {
      email,
    });

    return (
      <WhiteboxPage
        pageId={ChangePasswordPage.pageId}
        header={<h5>{title}</h5>}
        footer={this.renderFooter()}
        whiteboxFooter={this.renderWhiteboxFooter()}>
        {(query.message === 'security_wasme_reset' ||
          query.message === 'security_wasnotme_reset') && <SecurityContainer />}

        <Helmet title={formatMessage(chooseTitle(query.message))} />

        {error && (
          <FormError id="primary-form-error" className="form-error" history={this.props.history}>
            {this.renderPasswordChangeError(error)}
          </FormError>
        )}

        {query.message === 'security_wasme_reset' && (
          <div
            id="security-wasme-reset-message"
            dangerouslySetInnerHTML={{ __html: securityResetWasMeHtml }}
          />
        )}
        {query.message === 'security_wasnotme_reset' && (
          <div
            id="security-wasnotme-reset-message"
            dangerouslySetInnerHTML={{ __html: securityResetWasNotMeHtml }}
          />
        )}
        {paragraph && <BitbucketMigrationParagraph>{paragraph}</BitbucketMigrationParagraph>}

        <form autoComplete="on" onSubmit={this.handleSubmit}>
          <PasswordFieldGroup>
            <PasswordField
              id="password"
              name="password"
              autoComplete="new-password"
              placeholder={formatMessage(messages.passwordPlaceholder)}
              label={formatMessage(messages.passwordLabel)}
              isLabelHidden={true}
              isInvalid={!!this.props.fieldErrors.password}
              invalidMessage={this.props.fieldErrors.password}
              onChange={this.handlePasswordChange}
              value={this.state.password}
              ref={input => (this.passwordInput = input)}
              smallPlaceholder
            />
            <AsyncLoadedPasswordStrengthMeter
              intl={this.props.intl}
              password={this.state.password}
              scoreWords={scoreWordSplitter([
                email.trim(), // We want the user's email to be weak
              ])}
            />
          </PasswordFieldGroup>
          <TallButton
            appearance="primary"
            id="change-password"
            type="submit"
            isFullWidth
            isLoading={isLoading}>
            {formatMessage(chooseButtonText(query.message))}
          </TallButton>
        </form>
      </WhiteboxPage>
    );
  }
}

const mapStateToProps = (state: MainState): PropsFromState => ({
  email: state.changePassword.email,
  isLoading: state.changePassword.isLoading,
  fieldErrors: state.changePassword.fieldErrors,
  error: state.changePassword.error,
  csrfToken: state.csrfToken,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: InjectedProps & OwnProps
): PropsFromDispatch => ({
  changePassword: ({ password, csrfToken }: { password: string; csrfToken: CsrfToken }) => {
    const { location, intl, analyticsClient } = ownProps;
    const { signature, message } = queryString.parse(location.search) as URLParameters;

    dispatch(
      requestChangePassword({
        signature: signature || '',
        csrfToken,
        password,
        resetType: chooseChangePasswordType(message),
        microbranding: getMicrobrandingFromUrl(location),
        intl,
        analyticsClient,
      })
    );
  },
  dispatch,
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  ChangePasswordPage
);
