import { defineMessages } from 'react-intl';

export default defineMessages({
  // Flags
  verifyEmailFlagSuccessTitle: {
    id: 'verify.email.sent.flag.success.title',
    defaultMessage: "We've sent you an email!",
    description: '',
  },
  verifyEmailFlagSuccessDesc: {
    id: 'verify.email.sent.flag.success.description',
    defaultMessage: 'The verification email is on its way!',
    description: '',
  },
  verifyEmailFlagErrorTitle: {
    id: 'verify.email.sent.flag.error.title',
    defaultMessage: 'Something went wrong.',
    description: '',
  },
  verifyEmailFlagErrorDesc: {
    id: 'verify.email.sent.flag.error.description',
    defaultMessage: 'Please try again later.',
    description: '',
  },
  headTitle: {
    id: 'verify.email.sent.head.title',
    defaultMessage: 'Verify your email',
  },
  migrationVerifyHeader: {
    id: 'migration.welcome.sent.title',
    defaultMessage: 'Check your inbox to log in',
    description: 'Informing the user to check their inbox to login.',
  },
  migrationVerifyMessage: {
    id: 'migration.welcome.sent.signup.link',
    defaultMessage:
      'To complete setup and log in, click the verification link in the email we’ve sent to',
    description: 'Informing user that another welcome email has been sent for migration.',
  },
  resumeMigrationVerifyMessage: {
    id: 'resume.migration.welcome.sent.signup.link',
    defaultMessage:
      'Your {product} account now requires you to log in with your new Atlassian account.',
    description: 'Informing migrating user that they need to log in with their Atlassian account',
  },
  button: {
    id: 'welcome.sent.resend.signup.link',
    defaultMessage: 'Resend verification email',
    description:
      'Text telling the user that they can resend the welcome email again for migration.',
  },
});
