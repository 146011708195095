import '@atlaskit/css-reset';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ContextPath, Dispatch, InjectedRouterProps, InjectedAnalyticsProps } from '../types';
import { State as MainState } from '../reducers/index';
import { State as MicrobrandingState } from '../reducers/microbranding-reducer';
import { State as CobrandingState } from '../reducers/cobranding-reducer';
import SecurityExpiredLinkPage from './SecurityExpiredLinkPage';
import SecuritySuccessPage from './SecuritySuccessPage';

import {
  CancelAccountDeletionPage,
  DeletionRequestConfirmationPage,
  ExpiredPasswordPage,
  LoginComponents,
  LoginPages,
  MfaAppsPage,
  MfaEnrollment,
  MfaEnrollmentPage,
  MfaPages,
  MultiFactorPage,
  InactiveAccountPage,
  PendingDeletionPage,
} from './LoadableComponents';

import ScreenSizeListener from '../components/ScreenSizeListener';
import FlagContainer from './FlagContainer';
import AnalyticsClientProvider from './AnalyticsClientProvider';

import { InjectedIntlProps } from 'react-intl';
import { wrapComponent } from '../utilities/wrap-component';
import messages from './App.messages';
import loginHintSelector, { LoginHint, LoginHintType } from '../selectors/login-hint';
import applicationDestinationSelector, {
  ApplicationDestination,
} from '../selectors/application-destination';
import { CobrandingProvider, getCurrentCobranding } from '../utilities/cobranding/cobranding';
import ChangePasswordPage from './ChangePasswordPage';
import SocialLoginRecoveryPage from './SocialLoginRecoveryPage';
import VerifyOrReverifyEmailSentPage from './VerifyOrReverifyEmailSentPage';
import VerifyOrReverifyEmailErrorPage from './VerifyOrReverifyEmailErrorPage';
import WelcomeSentPage from './WelcomeSentPage';
import GoogleLogin from './GoogleLogin';
import LogoutPage from './LogoutPage';
import StepUpStartPage from './StepUpStartPage';
import EmailConfirmationPage from './EmailConfirmationPage';
import AuthCallbackErrorPage from './AuthCallbackErrorPage';
import { SocialLoginFlowType } from '../utilities/social-login';

interface OwnProps {
  children?: React.ReactNode;
}

interface PropsFromState {
  contextPath: ContextPath;
  microbranding: MicrobrandingState;
  cobranding: CobrandingState;
  loginHint: LoginHint | null;
  applicationDestination: ApplicationDestination | null;
  tenantCloudId: string | undefined;
  userId: string | undefined;
  loginErrorShown: boolean;
  ffsId: string;
}

interface PropsFromDispatch {
  dispatch: Dispatch;
}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = OwnProps & PropsFromState & PropsFromDispatch & InjectedProps;

class App extends Component<Props> {
  renderLoginPage = () => {
    const { loginHint, applicationDestination } = this.props;

    if (loginHint && loginHint.type === LoginHintType.NotEmailAddress) {
      return (
        <LoginComponents
          component={LoginPages.UnauthorizedPage}
          applicationDestination={applicationDestination}
          loginHint={loginHint}
        />
      );
    }

    return <LoginComponents component={LoginPages.LoginPage} />;
  };

  render() {
    const { contextPath, microbranding, cobranding, tenantCloudId, userId, ffsId } = this.props;
    const { formatMessage, locale } = this.props.intl;
    const defaultTitle = formatMessage(messages.headTitle);
    return [
      <Helmet key="helmet" defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />,
      <AnalyticsClientProvider
        locale={locale}
        tenantCloudId={tenantCloudId}
        userId={userId}
        microbranding={microbranding}
        cobranding={cobranding}
        ffsId={ffsId}
        key="analyticsWebClientProvider">
        <CobrandingProvider
          value={{
            cobranding: getCurrentCobranding(cobranding),
            isMobileApp: microbranding.isMobileApp,
            isAnyMobile: microbranding.isAnyMobile,
          }}>
          <ScreenSizeListener key="screenSizeListener">
            <Switch>
              <Route
                path={`${contextPath}/2step/enrollment/1-get-app`}
                render={props => <MfaEnrollment component={MfaPages.GetAppPage} {...props} />}
              />
              <Route
                path={`${contextPath}/2step/enrollment/2-configure-app`}
                render={() => <MfaEnrollment component={MfaPages.ConfigureAppPage} />}
              />
              <Route
                path={`${contextPath}/2step/enrollment/2-configure-phone`}
                render={() => <MfaEnrollment component={MfaPages.ConfigurePhonePage} />}
              />
              <Route
                path={`${contextPath}/2step/enrollment/3-connect-phone`}
                render={() => (
                  <MfaEnrollment component={MfaPages.ConfigureAppPage} isConnectPhone={true} />
                )}
              />
              <Route
                path={`${contextPath}/2step/enrollment/4-save-recovery-key`}
                render={props => (
                  <MfaEnrollment component={MfaPages.SaveRecoveryKeyPage} {...props} />
                )}
              />
              <Route path={`${contextPath}/2step/enrollment`} component={MfaEnrollmentPage} />
              <Route path="/2step/recover/expired" render={() => <MultiFactorPage isExpired />} />
              <Route
                path="/2step/recover"
                render={props => <LoginComponents component={LoginPages.RecoveryPage} {...props} />}
              />
              <Route path={`${contextPath}/2step`} component={MfaAppsPage} />
              <Route
                path="/login/resetpassword"
                render={props => (
                  <LoginComponents component={LoginPages.ResetPasswordPage} {...props} />
                )}
              />
              <Route
                path="/login/continue-as"
                render={props => (
                  <LoginComponents component={LoginPages.ContinueAsPage} {...props} />
                )}
              />
              <Route
                path="/login/select-account"
                render={props => (
                  <LoginComponents component={LoginPages.SelectAccountPage} {...props} />
                )}
              />
              <Route
                path="/login/remove-account"
                render={props => (
                  <LoginComponents component={LoginPages.RemoveAccountPage} {...props} />
                )}
              />
              <Route path="/login/cancel-account-deletion" component={CancelAccountDeletionPage} />
              <Route
                path="/login/deletion-request-confirmation"
                component={DeletionRequestConfirmationPage}
              />
              <Route path="/login/resetexpiredpassword" component={ExpiredPasswordPage} />
              <Route path="/login/changepassword" component={ChangePasswordPage} />
              <Route path="/login/google" component={GoogleLogin} />
              <Route path="/login/inactive" component={InactiveAccountPage} />
              <Route path="/login/pending-deletion" component={PendingDeletionPage} />
              <Route
                path="/login/callback"
                render={props => <AuthCallbackErrorPage error="invalid-relaystate" {...props} />}
              />
              <Route path="/login/mfa" component={MultiFactorPage} />
              <Route
                path="/login/social/confirmation"
                render={props => (
                  <LoginComponents
                    component={LoginPages.CreateAccountConfirmationPage}
                    {...props}
                    loginFlowType={SocialLoginFlowType.InHouse}
                  />
                )}
              />
              <Redirect
                from="/login/*"
                to={{ pathname: '/login', search: this.props.location.search }}
              />
              <Route path="/login" render={this.renderLoginPage} />
              <Route path="/logout" component={LogoutPage} />
              <Route path="/mf" component={MultiFactorPage} />
              <Route
                path="/signup/migrate-confirmation"
                render={props => (
                  <LoginComponents
                    component={LoginPages.MigrateAccountConfirmationPage}
                    {...props}
                  />
                )}
              />
              <Route
                path="/signup/confirmation"
                render={props => (
                  <LoginComponents
                    component={LoginPages.CreateAccountConfirmationPage}
                    {...props}
                    loginFlowType={SocialLoginFlowType.Auth0}
                  />
                )}
              />
              <Route path="/signup/welcome/sent" component={WelcomeSentPage} />
              <Route
                path="/signup/welcome"
                render={props => <LoginComponents component={LoginPages.WelcomePage} {...props} />}
              />
              <Route
                path="/signup"
                render={props => <LoginComponents component={LoginPages.SignupPage} {...props} />}
              />
              <Route path="/verify-email/change-email" component={EmailConfirmationPage} />
              <Route path="/verify-email/sent" component={VerifyOrReverifyEmailSentPage} />
              <Route path="/verify-email" component={VerifyOrReverifyEmailErrorPage} />
              <Route path="/security/expiredlink" component={SecurityExpiredLinkPage} />
              <Route
                path="/security/successfulreset"
                render={props => <SecuritySuccessPage {...props} successType="password-reset" />}
              />
              <Route
                path="/security/successfulverification"
                render={props => <SecuritySuccessPage {...props} successType="verification" />}
              />
              <Route path="/social/recovery" component={SocialLoginRecoveryPage} />
              <Route path="/error" component={AuthCallbackErrorPage} />
              <Route path="/step-up/start" component={StepUpStartPage} />
              <Redirect to={{ pathname: '/login', search: this.props.location.search }} />
            </Switch>
            <FlagContainer />
          </ScreenSizeListener>
        </CobrandingProvider>
      </AnalyticsClientProvider>,
    ];
  }
}

const mapStateToProps = (state: MainState): PropsFromState => {
  const loginHint = loginHintSelector(state);
  const applicationDestination = applicationDestinationSelector(state);
  return {
    contextPath: state.appConfig.contextPath,
    microbranding: state.microbranding,
    cobranding: state.cobranding,
    loginHint,
    applicationDestination,
    tenantCloudId: state.context.tenantCloudId,
    userId: state.context.userId,
    loginErrorShown: state.login.isErrorShown,
    ffsId: state.ffsId,
  };
};

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withIntl: true,
  },
  App
);
