import React, { ReactNode } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { State as MainState } from '../reducers';
import { wrapComponent } from '../utilities/wrap-component';
import { OidcContext } from '../types';
import { isMobileOidc } from '../utilities/oidc/oidc';
import ErrorPage from '../components/ErrorPage';

interface OwnProps {
  children: ReactNode;
}

interface PropsFromState {
  oidcContext: OidcContext | null;
}

type Props = PropsFromState & OwnProps & InjectedIntlProps;

const isUnSupportedMobileBrowser = () => {
  return /UC.*Browser|UCWEB/.test(window.navigator.userAgent);
};

export const UnSupportedMobileBrowserInterceptor: React.FC<Props> = ({ oidcContext, children }) => {
  if (isMobileOidc(oidcContext) && isUnSupportedMobileBrowser()) {
    return (
      <ErrorPage
        title={
          <FormattedMessage
            id="unsupported.mobile.browser.error.message.heading"
            defaultMessage="We’re unable to log you in"
          />
        }>
        <FormattedMessage
          id="unsupported.mobile.browser.error.message.content"
          defaultMessage="You are currently using an unsupported browser. Please use a supported browser such as Chrome, Firefox, Opera or Samsung Internet."
        />
      </ErrorPage>
    );
  }
  return <>{children}</>;
};

function mapStateToProps(state: MainState) {
  return {
    oidcContext: state.microbranding.oidcContext,
  };
}

export default wrapComponent<PropsFromState, {}, OwnProps, InjectedIntlProps>(
  {
    mapStateToProps,
  },
  UnSupportedMobileBrowserInterceptor
);
