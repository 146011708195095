import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import ErrorPage from '../components/ErrorPage';
import Button from '@atlaskit/button';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';
import messages from './EmailConfirmationPage.messages';
import { Link } from 'react-router-dom';

export const pageId = 'emailConfirmationInvalidTokenErrorPage';

export const EmailConfirmationInvalidTokenErrorPage: React.FC = () => {
  usePageViewedEvent(pageId);

  return (
    <ErrorPage title={<FormattedMessage {...messages.invalidTokenTitle} />}>
      <p>
        <FormattedHTMLMessage {...messages.invalidTokenDescription} />
      </p>
      <p className="error-action">
        <Link to="/login">
          <Button appearance="primary">
            <FormattedMessage {...messages.invalidTokenReturnToLoginButton} />
          </Button>
        </Link>
      </p>
    </ErrorPage>
  );
};

export default EmailConfirmationInvalidTokenErrorPage;
