import confluenceImageLeft from '../../../images/cobranding/confluence/cobrand-id-confluence-left.svg';
import confluenceImageRight from '../../../images/cobranding/confluence/cobrand-id-confluence-right.svg';
import { colors } from '@atlaskit/theme';
import { ConfluenceLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  blueLogo,
  CobrandingComponents,
  CobrandingLogoStyles,
} from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

const logoStyles: CobrandingLogoStyles = { ...blueLogo, textColor: colors.N800 };

export const ConfluenceBackground = backgroundWithSideImages(
  confluenceImageLeft,
  confluenceImageRight
);

const ConfluenceCobranding: CobrandingComponents = {
  background: ConfluenceBackground,
  logo: { component: ConfluenceLogo, desktopStyles: logoStyles, mobileStyles: logoStyles },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Confluence, Jira, Trello',
};

export default ConfluenceCobranding;
