import styled from '@emotion/styled';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { screenSizeMatcher } from '../utilities/style-utils';
import { screenSizeUpdated } from '../actions/screen-size-actions';
import { Dispatch } from '../types';

const FullHeightContainer = styled.div`
  height: 100%;
`;

class DeviceTypeListener extends Component<{ dispatch: Dispatch; children?: React.ReactNode }> {
  componentDidMount() {
    if (screenSizeMatcher) {
      screenSizeMatcher.addListener(this.onScreenSizeUpdated);
    }
  }

  onScreenSizeUpdated = (matchChanged: any) => {
    this.props.dispatch(screenSizeUpdated(matchChanged.matches ? 'mobile' : 'desktop'));
  };

  componentWillUnmount() {
    if (screenSizeMatcher) {
      screenSizeMatcher.removeListener(this.onScreenSizeUpdated);
    }
  }

  render() {
    return <FullHeightContainer>{this.props.children}</FullHeightContainer>;
  }
}

// @ts-ignore type 'Dispatch' is not assignable to type 'Dispatch<AnyAction> extends Dispatch ? Dispatch : Dispatch<AnyAction>'.
export default connect()(DeviceTypeListener);
