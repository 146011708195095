import auth0GuardianJS from 'auth0-guardian-js';
import { formPostHelper } from 'auth0-guardian-js';

import {
  guardianEnrollmentDetailsResponse,
  guardianRecoverError,
  guardianRecoverResponse,
  guardianVerifyError,
  guardianVerifyResponse,
} from '../actions/multi-factor-actions';

import { Middleware } from 'redux';
import { Action, Dispatch } from '../types';
import { addFlag } from '../actions/flag-actions';
import { State } from '../reducers';
import messages from '../containers/MultiFactorPage.messages';

// TODO: Fix this up
interface Transaction {
  getEnrollments: () => any[];
  requestAuth: any;
}

export type GuardianMiddleware = Middleware<State, Action, Dispatch>;

export default ({
  serviceUrl,
  ticket,
  requestToken,
  issuer,
  globalTrackingId,
}: {
  serviceUrl: string;
  ticket: string;
  requestToken: string;
  issuer: {
    label: string;
    name: string;
  };
  globalTrackingId: string;
}): GuardianMiddleware => {
  const promise = new Promise((resolve, reject) => {
    auth0GuardianJS({
      serviceUrl,
      ticket,
      requestToken,
      issuer,
      globalTrackingId,
      stateCheckingMechanism: 'polling',
    }).start((err, transaction) => {
      if (err) {
        reject(err);
      } else {
        resolve(transaction);
      }
    });
  }).then(
    (transaction: Transaction) =>
      new Promise((resolve, reject) => {
        const enrollment = transaction.getEnrollments()[0];

        transaction.requestAuth(enrollment, (err, authenticator) => {
          if (err) {
            reject(err);
          }
          resolve({ transaction, authenticator });
        });
      })
  );

  return () => next => action => {
    switch (action.type) {
      case 'GUARDIAN_ENROLLMENT_DETAILS_REQUEST':
        next(action);
        // @ts-ignore
        return next(() => {
          promise
            .then(
              ({ transaction }) =>
                new Promise(() => {
                  const enrollment = transaction.getEnrollments()[0];
                  const enrollmentMethod = enrollment.getMethods()[0];
                  const phoneNumber = enrollment.getPhoneNumber();

                  next(
                    guardianEnrollmentDetailsResponse({ method: enrollmentMethod, phoneNumber })
                  );
                })
            )
            .catch(() => handleGuardianError(next));
        });
      case 'GUARDIAN_VERIFY':
        next(action);
        // @ts-ignore
        return next(() => {
          promise
            .then(
              ({ transaction, authenticator }) =>
                new Promise(() => {
                  transaction
                    .removeAllListeners()
                    .on('error', err => {
                      action.analyticsClient.errorShownEvent(
                        '2svChallengeScreen',
                        '2svIncorrectCode',
                        { mfaBackend: 'auth0' }
                      );
                      next(guardianVerifyError(err));
                    })
                    .on('auth-response', payload => {
                      next(guardianVerifyResponse(payload));
                    });

                  authenticator.verify({ otpCode: action.otpCode });
                })
            )
            .catch(() => handleGuardianError(next));
        });
      case 'GUARDIAN_RESEND_SMS':
        next(action);
        // @ts-ignore
        return next(() => {
          promise
            .then(
              ({ transaction }) =>
                new Promise((resolve, reject) => {
                  const enrollment = transaction.getEnrollments()[0];

                  transaction.requestAuth(enrollment, err => {
                    if (err) {
                      reject(err);
                    } else {
                      next(
                        addFlag(
                          'success',
                          {
                            id: 'mf.mfform.sms.resend.success.flag.title',
                            defaultMessage: 'Check your phone!',
                          },
                          {
                            id: 'mf.mfform.sms.resend.success.flag.description',
                            defaultMessage: "We've resent your one-time password via SMS.",
                          }
                        )
                      );
                    }
                  });
                })
            )
            .catch(() => handleGuardianError(next));
        });
      case 'GUARDIAN_RECOVER':
        next(action);
        // @ts-ignore
        return next(() => {
          promise
            .then(
              ({ transaction }) =>
                new Promise((resolve, reject) => {
                  transaction
                    .removeAllListeners()
                    .on('error', reject)
                    .on('auth-response', resolve)
                    .recover({ recoveryCode: action.recoveryCode });
                })
            )
            .then(
              payload => {
                // @ts-ignore
                next(guardianRecoverResponse(payload));
              },
              err => {
                next(guardianRecoverError(err));
              }
            )
            .catch(() => handleGuardianError(next));
        });
      case 'GUARDIAN_CONFIRMED_RECOVER':
        next(action);
        formPostHelper(window.postActionURL, {
          signature: action.signature,
        });
        break;
      default:
        return next(action);
    }
  };
};

function handleGuardianError(dispatch: Dispatch) {
  return dispatch(addFlag('error', messages.mfVerifyErrorTitle, messages.mfVerifyErrorDesc));
}
