import { defineMessages } from 'react-intl';

export default defineMessages({
  bodyMessageUnableToLoginWithThisEmailAddress: {
    id: 'social.recovery.body.unable.with.this.email.address',
    defaultMessage: 'We’re unable to log you in to {product} with this email address.',
    description: `Message describing to the user that when they logged in via a social
      provider (eg. Log in with Google) they selected an account that we didn't expect.
      This occurs when we know that we want the user to log in with myemail@gmail.com, but
      in the social provider they log in with otheraccount@gmail.com. 
      If we can't detect the product, we use 'your Atlassian account' as {product}.
      `,
  },
  bodyMessageUnableToLoginWithFoundEmail: {
    id: 'social.recovery.body.unable.with.found.email',
    defaultMessage: 'We’re unable to log you in to {product} with {foundEmail}.',
    description: `Message describing to the user that when they logged in via a social
      provider (eg. Log in with Google) they selected an account that we didn't expect.
      This occurs when we know that we want the user to log in with myemail@gmail.com, but
      in the social provider they log in with otheraccount@gmail.com.
      If we can't detect the product, we use 'your Atlassian account' as {product}.
      `,
  },
  bodyMessageUseExpectedEmail: {
    id: 'social.recovery.body.use.expected.email',
    defaultMessage: 'To continue, select <b>Try again</b> and use {expectedEmail}.',
    description: `Message instructing the user that they logged in via a social
      provider (eg. Log in with Google) and selected an account that we didn't expect.
      This occurs when we know that we want the user to log in with myemail@gmail.com, but
      in the social provider they log in with otheraccount@gmail.com. They should press the Try again button and use the expected email address.
      `,
  },
  documentationLinkMessage: {
    id: 'social.recovery.documentation.link',
    defaultMessage: 'Get login help',
    description: 'Link to documentation page with help information',
  },
  productFallback: {
    id: 'social.recovery.product-fallback',
    defaultMessage: 'your Atlassian account',
    description: `If we can't determine the product the user is attempting to land on, then we
    will fall back to this value. Usage: We’re unable to log you in to your Atlassian account with unexpectedemail@example.com`,
  },
  buttonText: {
    id: 'social.recovery.button',
    defaultMessage: 'Try again',
    description: 'Button for user to try log in again.',
  },
  unknownErrorMessage: {
    id: 'social.recovery.error.unknown',
    defaultMessage: 'Oops, something went wrong. Try that again in a moment.',
  },
  tokenErrorMessage: {
    id: 'social.recovery.error.token-error',
    defaultMessage: "We're unable to service your request. Please try again.",
  },
  expiredTokenErrorMessage: {
    id: 'social.recovery.error.token-expired',
    defaultMessage: 'Your session has expired. Please try again.',
  },
});
