import React, { Component, ReactNode } from 'react';
import AKAvatar from '@atlaskit/avatar';
import styled from '@emotion/styled';
import { colors, typography } from '@atlaskit/theme';

export interface HasPrimary {
  primary?: boolean;
}

export interface Props extends AvatarProps {
  displayName?: string;
  children?: ReactNode;
  onClick?: (_: React.MouseEvent<HTMLElement>) => void;
  email: string;
  icon?: ReactNode;
  id?: string;
}

export interface AccountContainerProps extends HasPrimary {
  onClick?: (_: React.MouseEvent<HTMLElement>) => void;
  id?: string;
}

export interface AvatarProps extends HasPrimary {
  avatarUrl?: string;
}

export const AccountContainer = styled.div<AccountContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${props => (props.primary ? '0' : '')};
  padding-bottom: ${props => (props.primary ? '24px' : '')};

  ${props =>
    !!props.onClick
      ? `:hover {
      border-radius: 3px;
      transition: background 0s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
      cursor: pointer;
      background-color: ${colors.N30A};
    }`
      : ''}

  > * {
    width: 100%;
  }

  > * + * {
    margin: 24px 0 0 0;
  }
`;

const primaryAvatarSize = '72px';
const defaultAvatarSize = '36px';

export const AvatarContainer = styled.div<HasPrimary>`
  margin: 0 0 0 ${props => (props.primary ? '0' : '8px')};
  width: ${props => (props.primary ? primaryAvatarSize : defaultAvatarSize)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.primary &&
    `
    div, span {
      padding: 0;
      width: ${primaryAvatarSize} !important;
      height: ${primaryAvatarSize} !important;
    }
  `}
`;

const Avatar = (props: AvatarProps) => (
  <AvatarContainer primary={props.primary}>
    <AKAvatar
      appearance="circle"
      src={props.avatarUrl}
      size={props.primary ? 'xlarge' : 'medium'}
    />
  </AvatarContainer>
);

export const RowContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
`;

export const RowButtonContainer = styled(RowContainer)`
  padding: 8px 0;
`;

export const TextContainer = styled.section`
  flex-grow: 1;

  min-width: 0;
  // IE11 expands width to the parent container until the overflow kicks in
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 0;
  }

  display: inline-block;
  margin: 0 16px;

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Header = styled.h2<HasPrimary>`
  ${props => (props.primary ? typography.h600() : typography.h400())};
  font-size: ${props => (props.primary ? '20px' : '14px')};
  line-height: ${props => (props.primary ? '24px' : '20px')};
  color: ${colors.N800};
`;

export const Paragraph = styled.p<HasPrimary>`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.N700};
  margin-top: ${props => (props.primary ? '4px' : '0')};
`;

export const IconContainer = styled.section`
  margin-right: 8px;
`;

export default class Account extends Component<Props> {
  render() {
    const { avatarUrl, children, displayName, email, icon, id, onClick, primary } = this.props;
    const header = displayName ? displayName : email;
    const paragraph = displayName ? email : undefined;
    return (
      <AccountContainer
        id={id}
        className="account-entry" // account-entry used by pollinators
        primary={primary}
        onClick={onClick}>
        <RowContainer>
          <Avatar avatarUrl={avatarUrl} primary={primary} />
          <TextContainer>
            <Header primary={primary}>{header}</Header>
            {paragraph && <Paragraph primary={primary}>{paragraph}</Paragraph>}
          </TextContainer>
          <IconContainer>{icon}</IconContainer>
        </RowContainer>
        {children}
      </AccountContainer>
    );
  }
}
