import React from 'react';
import * as queryString from 'query-string';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';
import { wrapComponent } from '../utilities/wrap-component';
import errorImage from '../images/Error.svg';
import { InjectedAnalyticsProps, URLParameters } from '../types';
import messages from './CsrfTokenMismatchErrorPage.messages';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import ButtonContainer from '../components/ButtonContainer';
import { AnalyticsClient, usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

type InjectedProps = InjectedIntlProps & InjectedAnalyticsProps;
interface OwnProps {
  analyticsClient: AnalyticsClient;
}
type Props = InjectedProps & OwnProps;

const pageId = 'atlassianAccountCsrfTokenMismatchErrorScreen';

export const CsrfTokenMismatchErrorPage: React.FC<Props> = ({ intl, analyticsClient }) => {
  const preservedParamsKeys = [
    'application',
    'continue',
    'display',
    'email',
    'login_hint',
    'tenant',
  ];
  const originalParams: URLParameters = queryString.parse(window.location.search);
  const preservedParams: URLParameters = Object.keys(originalParams)
    .filter(key => preservedParamsKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = originalParams[key];
      return obj;
    }, {});

  usePageViewedEvent(pageId, { application: preservedParams.application });

  const tryLoginAgainAction = () => {
    analyticsClient.buttonClickedEvent(pageId, 'csrfTokenMismatchTryAgainButton', {
      application: preservedParams.application,
    });
    const queryParamsString = queryString.stringify(preservedParams);
    window.location.assign('/login' + (queryParamsString ? `?${queryParamsString}` : ''));
  };

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <h4 className="error-title">{intl.formatMessage(messages.csrfMismatchTitle)}</h4>
          <br />
          <div>
            <img src={errorImage} height="150" alt="" className="error-image" />
          </div>
        </div>
      }>
      <div className="error-description">
        <FormattedHTMLMessage {...messages.csrfMismatchDescriptionFirstLine} />
        <ul>
          <li>
            <FormattedHTMLMessage
              {...messages.csrfMismatchDescriptionSecondLine}
              values={{ siteName: window.location.hostname }}
            />
          </li>
          <li>
            <FormattedHTMLMessage {...messages.csrfMismatchDescriptionThirdLine} />
          </li>
        </ul>
      </div>
      <ButtonContainer>
        <TallButton
          appearance="primary"
          id="try-again-button"
          isFullWidth
          onClick={tryLoginAgainAction}>
          {intl.formatMessage(messages.csrfMismatchButtonText)}
        </TallButton>
      </ButtonContainer>
    </WhiteboxPage>
  );
};

export default wrapComponent<{}, {}, OwnProps, InjectedProps>(
  {
    withIntl: true,
  },
  CsrfTokenMismatchErrorPage
);
