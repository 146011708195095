import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import Page from '@atlaskit/page';
import { borderRadius, colors, gridSize } from '@atlaskit/theme';

import { media } from '../utilities/style-utils';
import { colorPalette } from '../utilities/color-palette';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 500px;
  margin: 0 auto;
  width: ${gridSize() * 52}px;

  &::before {
    content: '';
    flex-shrink: 0;
    height: ${gridSize() * 6}px;
  }

  ${media.handheld`
    padding: 0 ${gridSize()}px;
    width: ${gridSize() * 40}px;

    &::before {
      height: ${gridSize() * 2}px;
    }
  `}
`;
const Header = styled.header`
  color: ${colors.B50};
  text-align: center;

  h1 {
    color: ${colors.B50};
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
  ${media.handheld`
    color: ${colorPalette.primaryNavy};
    margin-top: ${gridSize() * 6}px;

    h1 {
      color: ${colors.N300};
      font-size: 1.14285714em;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.006em;
      margin-top: ${gridSize() * 2}px;
    }
  `}
`;

interface ContentProps {
  wide?: boolean;
}

const Content = styled.section<ContentProps>`
  margin: ${gridSize() * 4}px 0;
`;

interface MainContentProps {
  noShadow?: boolean;
  noPadding?: boolean;
}
export const MainContent = styled.div<MainContentProps>`
  background: ${colorPalette.primaryWhite};
  border-radius: ${borderRadius()}px;
  box-shadow: ${props => (props.noShadow ? 'none' : '0 10px 10px rgba(0, 0, 0, 0.1)')};
  box-sizing: border-box;
  color: ${colorPalette.primaryNavy};
  padding: ${props => (props.noPadding ? '0' : `${gridSize() * 6}px`)};

  ${media.handheld`
    box-shadow: none;
    padding: 0;
  `}
`;
const baseFooterStyles = () => css`
  color: ${colors.B50};

  a {
    color: ${colors.B50};

    &:hover {
      color: ${colorPalette.primaryWhite};
    }
    &.primary-action {
      color: ${colorPalette.primaryWhite};
    }
  }
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  ${media.handheld`
    color: ${colors.B400};

    a,
    a.primary-action {
      color: ${colors.B400};

      &:hover {
        color: ${colors.B300};
      }
      &:active {
        color: ${colors.B500};
      }
    }
  `}
`;
export const MainContentFooter = styled.div`
  ${baseFooterStyles};
  margin-top: ${gridSize() * 6}px;
  text-align: center;
`;
const Footer = styled.footer`
  ${baseFooterStyles};
  margin: auto 0 0;
  padding-bottom: ${gridSize() * 4}px;
  text-align: center;

  ul + p {
    margin-top: ${gridSize() * 3}px;
  }
  .fine-print {
    font-size: 12px;
  }
  ${media.handheld`
    padding-bottom: ${gridSize() * 2}px;
  `}
`;

class UnauthenticatedPage extends Component<{
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}> {
  static defaultProps = {
    showBranding: true,
  };

  render() {
    const { header, children, footer } = this.props;
    return (
      <Page>
        <Global
          styles={css`
            body {
              background-color: ${colorPalette.primaryWhite};
              color: ${colorPalette.primaryNavy};
            }
          `}
        />
        <Container>
          <Header>{header}</Header>
          <Content role="main">{children}</Content>
          <Footer>{footer}</Footer>
        </Container>
      </Page>
    );
  }
}

export default UnauthenticatedPage;
