import { ThunkAction } from '../types';
import submitFormHack from '../utilities/submit-form';

export const changeEmailConfirmation = (email: string, password: string): ThunkAction => (
  dispatch,
  getState
) => {
  submitFormHack({
    method: 'POST',
    payload: { email, password },
  });
  return Promise.resolve();
};
