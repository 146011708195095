import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Page from '@atlaskit/page';
import { borderRadius, colors, gridSize } from '@atlaskit/theme';

import { media } from '../utilities/style-utils';
import { colorPalette } from '../utilities/color-palette';
import { CobrandingProps, withCobranding } from '../utilities/cobranding/cobranding';
import { getCobrandedComponents } from './whitebox-cobranding';

import {
  linkStyles,
  listStyles,
  renderProductLogo,
  whiteboxWidthDesktop,
  whiteboxWidthHandheld,
} from './whitebox-cobranding/shared-cobranding';
import FooterComponent from './whitebox-cobranding/FooterComponent';
import { FootnoteComponent } from './whitebox-cobranding/FootnoteComponent';
import Spinner from '@atlaskit/spinner';
import { getLoginExperimentEligibility } from '../utilities/feature-flags';

export interface Props {
  pageId: string;
  children?: React.ReactNode;
  header: React.ReactNode;
  whiteboxFooter?: React.ReactNode;
  footer?: React.ReactNode;
  noFooterMarginTop?: boolean;
  isLoading?: boolean;
  showLoginExperimentBackgroundStyles?: boolean;
  showLoginExperimentWhiteboxStyles?: boolean;
}

/*
 * Exported for tests only
 */
export const WhiteboxFooter = styled.div<{ noMarginTop?: boolean }>`
  color: ${colors.N200};
  padding-top: ${gridSize() * 2}px;
  margin-top: ${props => (props.noMarginTop ? '0' : `${gridSize() * 4}px`)};
  border-top: #d5d8de solid 1px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;

  ${listStyles}
`;

/*
 * Exported for tests only
 */
export const WhiteboxHeader = styled.div<{ showExperimentStyles?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: ${gridSize() * 2}px;
  color: ${props => (props.showExperimentStyles ? colors.N800 : colors.N300)};
  font-size: ${props => (props.showExperimentStyles ? '24' : '16')}px;
  .heading-prefix {
    font-size: ${props => (props.showExperimentStyles ? '20px' : 'initial')};
  }
  ${props =>
    props.showExperimentStyles &&
    css`
      .text-spill-new {
        height: 24px;
        font-weight: initial;
      }
    `};
`;

const Whitebox = styled.section<{ showExperimentStyles?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${gridSize() * 3}px;
  width: ${whiteboxWidthDesktop};
  padding: ${gridSize() * 4}px ${gridSize() * 5}px;

  background: ${colorPalette.primaryWhite};
  border-radius: ${borderRadius()}px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${colors.N300};
  ${linkStyles(colors.B400)}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => (props.showExperimentStyles ? colors.N800 : colors.N300)};
    font-weight: ${props => (props.showExperimentStyles ? 'initial' : 'bold')};
  }

  ${media.handheld`
    padding: 0 ${gridSize()}px;
    background: transparent;
    box-shadow: none;
    width: ${whiteboxWidthHandheld};
  `}
`;

const PageHeader = styled.header`
  text-align: center;
  padding: ${gridSize() * 5}px 0 ${gridSize() * 5}px;
  color: ${colorPalette.primaryWhite};

  ${media.handheld`
    padding: ${gridSize() * 3}px 0 !important;
    color: ${colors.N200};
  `}
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${gridSize() * 3}px;
`;

export class CobrandedWhiteboxPage extends React.Component<Props & CobrandingProps> {
  render() {
    const {
      header,
      children,
      footer,
      noFooterMarginTop,
      whiteboxFooter,
      cobranding,
      isMobileApp,
      isAnyMobile,
      pageId,
      isLoading,
    } = this.props;
    const {
      background: Background,
      logo,
      footnoteStyles,
      footerStyles,
      footerProducts,
    } = getCobrandedComponents(cobranding);
    const { showExperimental: showExperimentStyles } = getLoginExperimentEligibility(
      isAnyMobile,
      cobranding
    );

    return (
      <Page>
        <Background />
        <Container>
          <div>
            <PageHeader>{renderProductLogo(logo)}</PageHeader>
            <Whitebox role="main" showExperimentStyles={showExperimentStyles}>
              {isLoading ? (
                <SpinnerContainer>
                  <Spinner size="large" />
                </SpinnerContainer>
              ) : (
                <>
                  <WhiteboxHeader showExperimentStyles={showExperimentStyles}>
                    {header}
                  </WhiteboxHeader>
                  {children}
                  {whiteboxFooter && (
                    <WhiteboxFooter noMarginTop={noFooterMarginTop}>
                      {whiteboxFooter}
                    </WhiteboxFooter>
                  )}
                </>
              )}
            </Whitebox>
            {footer && <FootnoteComponent {...footnoteStyles}>{footer}</FootnoteComponent>}
          </div>
          <FooterComponent
            styles={footerStyles}
            renderText={!isMobileApp}
            pageId={pageId}
            footerProducts={footerProducts}
          />
        </Container>
      </Page>
    );
  }
}

export default withCobranding<Props>(CobrandedWhiteboxPage);
