import React from 'react';
import { Redirect } from 'react-router';
import queryString from 'query-string';
import { LoginComponents, LoginPages } from '../containers/LoadableComponents';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers';
import { captureErrorMessage } from '../utilities/analytics/error-reporting';
import { UserFlow } from '../types';
import { SocialLoginFlowType } from '../utilities/social-login';

const PARAMS_WHITELIST = ['access_token', 'error', 'id_token'];

interface OwnProps {}

interface PropsFromState {
  loginErrorShown?: boolean;
}

type Props = OwnProps & PropsFromState;

interface RecoveryState {
  expects: string;
  token: string;
  source: string;
}

interface CallbackStateFromParams {
  csrfToken: string | null;
  source?: string | null;
  loginType?: string | null;
  query?: string | null;
  anonymousId?: string | null;
  userFlow?: UserFlow | null;
  recovery?: string | RecoveryState | null;
  expects?: string | null;
}

interface CallbackState {
  csrfToken: string;
  source?: string | null;
  loginType?: string | null;
  query?: string | null;
  anonymousId?: string | null;
  userFlow?: string | null;
  recovery?: string | null;
  expects?: string | null;
  recoveryToken?: string | null;
  sourceFlow?: string | null;
}

export interface AuthCallback {
  query: string;
  state: CallbackState | null;
}

export const getAuthCallback = (querystring: string): AuthCallback => {
  const params = queryString.parse(querystring);

  if (params.state) {
    try {
      const {
        csrfToken,
        source,
        loginType,
        anonymousId,
        userFlow,
        recovery,
        expects,
        query = '',
      } = JSON.parse(atob(params.state)) as CallbackStateFromParams;
      if (!csrfToken) {
        throw new Error('No CRSF Token Provided');
      }
      const state = Object.entries<string>(params).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(PARAMS_WHITELIST.includes(key) && { [key]: value }),
        }),
        {}
      ) as CallbackState;
      state.csrfToken = csrfToken;
      state.source = source;
      state.loginType = loginType;
      state.anonymousId = anonymousId;
      state.userFlow = userFlow;

      if (typeof recovery === 'string') {
        state.expects = expects;
        state.recovery = recovery;
      } else if (typeof recovery === 'object' && recovery !== null) {
        state.expects = recovery.expects;
        state.recoveryToken = recovery.token;
        state.sourceFlow = recovery.source;
      }

      return { query: query || '', state };
    } catch (error) {
      captureErrorMessage('Google Auth Callback Failed', { error: error.message });
      // if it fails - fallback to pipe-separated string approach for backward compatibility
      const [csrfToken, query = ''] = params.state.split('|');
      const state = { csrfToken };
      return { query, state };
    }
  }
  return { query: '', state: null };
};

export const GoogleLogin: React.FC<Props> = ({ loginErrorShown }) => {
  const { query, state: callbackState } = getAuthCallback(window.location.hash);

  if (callbackState) {
    // Auto-submitting hidden form
    return (
      <form
        method="POST"
        action={window.location.pathname + query}
        ref={node => node?.submit()}
        style={{ display: 'none' }}>
        {Object.entries(callbackState)
          .filter(([, value]) => value != null)
          .map(([key, value]) => (
            <input type="hidden" key={key} name={key} value={value} />
          ))}
      </form>
    );
  } else if (loginErrorShown) {
    return <Redirect to={{ pathname: '/login', search: window.location.search }} />;
  } else {
    return (
      <LoginComponents
        component={LoginPages.CreateAccountConfirmationPage}
        loginFlowType={SocialLoginFlowType.Google}
      />
    );
  }
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  loginErrorShown: state.login.isErrorShown,
});

export default wrapComponent<PropsFromState, {}, OwnProps>({ mapStateToProps }, GoogleLogin);
