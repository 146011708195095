import React from 'react';
import FormTextField, { FormTextFieldProps } from './FormTextField';
import styled from '@emotion/styled';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';

import FieldIconContainer from './FieldIconContainer';

export const StyledFieldIconContainer = styled(FieldIconContainer)`
  float: right;
  margin-top: -31px;
  margin-right: 6px;
  position: relative;
`;

const StyledDiv = styled.div<{ smallPlaceholder?: boolean }>`
  input {
    margin-right: 26px !important;
    ${props => props.smallPlaceholder && 'font-size: 14px !important;'}
`;

interface Props extends FormTextFieldProps {
  smallPlaceholder?: boolean;
}

interface State {
  type: 'password' | 'text';
}

class PasswordField extends React.Component<Props, State> {
  input: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      type: 'password',
    };
  }

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  inputRef = (fieldTextStateless: HTMLInputElement) => (this.input = fieldTextStateless);

  handleVisibilityToggle = () => {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    });
    this.focus();
  };

  render() {
    const toggleIcon =
      this.state.type === 'text' ? <WatchFilledIcon label="" /> : <WatchIcon label="" />;
    return (
      <div>
        <StyledDiv smallPlaceholder={this.props.smallPlaceholder}>
          <FormTextField
            {...this.props}
            type={this.state.type}
            autoComplete={this.state.type === 'password' ? this.props.autoComplete || 'off' : 'off'}
            spellCheck={false}
            ref={this.inputRef}
          />
          {!this.props.isInvalid && (
            <StyledFieldIconContainer onClick={this.handleVisibilityToggle}>
              {toggleIcon}
            </StyledFieldIconContainer>
          )}
        </StyledDiv>
      </div>
    );
  }
}

export default PasswordField;
