import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { State as MainState } from '../reducers';
import { InjectedAnalyticsProps, InjectedRouterProps } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import { isMobileOidc } from '../utilities/oidc/oidc';
import WhiteboxPage from '../components/WhiteboxPage';
import ButtonContainer from '../components/ButtonContainer';
import TallButton from '../components/TallButton';
import { Link } from 'react-router-dom';
import { SecurityWall } from '../components/SecurityWall';
import { idacOrigin } from '../utilities/env';

interface PropsFromState {}

interface PropsFromDispatch {}

function chooseTitle(successType: SecuritySuccessType): JSX.Element {
  switch (successType) {
    case 'password-reset':
      return (
        <FormattedMessage
          id="security-successfulreset-title"
          defaultMessage="Password reset successfully"
        />
      );
    case 'verification':
      return (
        <FormattedMessage
          id="security-successfulverification-title"
          defaultMessage="Help us protect your account"
        />
      );
  }
}

function chooseParagraph(successType: SecuritySuccessType): JSX.Element {
  switch (successType) {
    case 'password-reset':
      return (
        <FormattedMessage
          id="security-successfulreset-message"
          defaultMessage="Your password had been reset successfully!"
        />
      );
    case 'verification':
      return (
        <FormattedMessage
          id="security-successfulverification-message"
          defaultMessage="Thanks for letting us know it was you."
        />
      );
  }
}

function chooseAnalyticsEventName(successType: SecuritySuccessType): string {
  switch (successType) {
    case 'password-reset':
      return 'passwordResetSuccessfulScreen';
    case 'verification':
      return 'securityVerificationSuccessfulScreen';
    default:
      return 'unknown';
  }
}

type SecuritySuccessType = 'password-reset' | 'verification';

export interface OwnProps {
  successType: SecuritySuccessType;
}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export class SecuritySuccessPage extends Component<Props> {
  componentDidMount() {
    this.props.analyticsClient.pageViewedEvent(chooseAnalyticsEventName(this.props.successType));
  }

  buildHeader = () => {
    return <b>{chooseTitle(this.props.successType)}</b>;
  };

  buildContinueToLogin = () => {
    const { location } = this.props;
    const loginLink = '/login' + location.search;
    return (
      <Link
        to={loginLink}
        id="security-reset-password-continue"
        onClick={() =>
          this.props.analyticsClient.buttonClickedEvent(
            chooseAnalyticsEventName(this.props.successType),
            'continueToLoginSecurity'
          )
        }>
        <FormattedMessage id="continue.to.log.in" defaultMessage="Continue to Log in" />
      </Link>
    );
  };

  buildSecuritySettingsLink() {
    const securityLink = idacOrigin + '/manage-profile/security';
    return (
      <a
        href={securityLink}
        id="security-reset-password-security-settings"
        onClick={() =>
          this.props.analyticsClient.buttonClickedEvent(
            chooseAnalyticsEventName(this.props.successType),
            'reviewSecuritySettings'
          )
        }>
        <FormattedMessage id="security-review" defaultMessage="Review your security settings" />
      </a>
    );
  }

  render() {
    return (
      // @ts-ignore no id
      <WhiteboxPage
        pageId={chooseAnalyticsEventName(this.props.successType)}
        header={this.buildHeader()}
        whiteboxFooter={this.buildSecuritySettingsLink()}
        footer={this.buildContinueToLogin()}>
        <SecurityWall
          id="security-successful-password-reset-wall"
          securityWallStyle={'password-reset'}
        />
        <p>{chooseParagraph(this.props.successType)}</p>

        <p>
          <FormattedMessage
            id="security-successfulreset-learn-more"
            defaultMessage="Take a few minutes to learn more about how to enhance the security of your account and avoid this issue in the future."
          />
        </p>
        <SecurityButton />
      </WhiteboxPage>
    );
  }
}

function SecurityButton() {
  return (
    <ButtonContainer id="security-learn-more">
      <TallButton
        href="https://confluence.atlassian.com/x/nwkvOg"
        appearance="primary"
        type="button"
        isFullWidth
        onClick={() =>
          this.props.analyticsClient.buttonClickedEvent(
            chooseAnalyticsEventName(this.props.successType),
            'learnMoreAboutSecurity'
          )
        }>
        <FormattedMessage id="security-learn" defaultMessage="Learn more about security" />
      </TallButton>
    </ButtonContainer>
  );
}

const mapStateToProps = (state: MainState): PropsFromState => ({
  isMobileApp: isMobileOidc(state.microbranding.oidcContext) || state.microbranding.isEmbedded,
});

export default wrapComponent<PropsFromState, PropsFromState, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  SecuritySuccessPage
);
