import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'changepassword.title',
    defaultMessage: 'Choose a new password',
  },
  bitbucketMigrationTitle: {
    id: 'changepassword.bitbucket.migration.title',
    defaultMessage: 'Update your password',
  },
  button: {
    id: 'changepassword.button',
    defaultMessage: 'Continue',
  },
  bitbucketMigrationButton: {
    id: 'changepassword.bitbucket.migration.button',
    defaultMessage: 'Update password',
  },
  bitbucketMigrationParagraph: {
    id: 'changepassword.bitbucket.migration.paragraph',
    defaultMessage: "You're almost done setting up your account.",
  },
  passwordPlaceholder: {
    id: 'changepassword.password.placeholder',
    defaultMessage: "A few words you'll find easy to remember",
  },
  passwordLabel: {
    id: 'changepassword.password.label',
    defaultMessage: 'Create password',
  },
  errorMessage: {
    id: 'changepassword.error.message',
    defaultMessage:
      'Something went wrong while attempting to change your password. Please try again.',
  },
  emptyPasswordErrorMessage: {
    id: 'changepassword.error.password.empty.message',
    defaultMessage: 'Please enter a password',
  },
  securityWasMeParagraph: {
    id: 'changepassword.security.wasme.reset.paragraph',
    defaultMessage: `<p>Thanks for helping us keep your account secure.</p>
<p>To prevent unauthorized access to your account, your password has been invalidated.</p>
<p>Create a new, unique password to access your account:</p>
<p style="overflow: hidden; text-overflow: ellipsis"><strong>{email}</strong></p>`,
  },
  securityWasNotMeParagraph: {
    id: 'changepassword.security.wasnotme.reset.paragraph',
    defaultMessage: `<p>To prevent unauthorized access to your account, your password has been invalidated.</p>
<p>Create a new, unique password to access your account: </p>
<p style="overflow: hidden; text-overflow: ellipsis"><strong>{email}</strong></p>`,
  },
  securityResetTitle: {
    id: 'changepassword.security.reset.title',
    defaultMessage: 'Reset your password',
  },
  passwordLeaked: {
    id: 'changepassword.password.leaked.paragraph',
    defaultMessage: `<p>This password has appeared in an external data breach. To continue, enter another password. <a href="{passwordLeakedLink}">Learn more</a></p>`,
  },
});
