import React from 'react';

import {
  Header,
  search,
  secondaryNav,
  pageBreadcrumbs,
  pageActions,
  pageContent,
} from '../../../images/cobranding/confluence-experiment';

import {
  Background,
  HeaderContainer,
  Search,
  HeaderLine,
  SecondaryNavBackground,
  SecondaryNav,
  PageBreadcrumbs,
  PageActions,
  PageContentWrapper,
  PageContent,
} from './ConfluenceWireframeStyles';

export const ConfluenceWireframe = () => {
  return (
    <Background>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Search src={search} />
      <HeaderLine />
      <SecondaryNavBackground />
      <SecondaryNav src={secondaryNav} />
      <PageBreadcrumbs src={pageBreadcrumbs} />
      <PageActions src={pageActions} />
      <PageContentWrapper>
        <PageContent src={pageContent} />
      </PageContentWrapper>
    </Background>
  );
};
