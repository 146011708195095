import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { State as MainState } from '../reducers/index';
import { withAnalyticsWebClient } from './analytics/analytics-web-client';
import { Dispatch } from 'redux';
import { Action } from '../types';

interface ConnectSettings<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}> {
  mapStateToProps?: (state: MainState, ownProps?: TOwnProps) => TStateProps;
  mapDispatchToProps?: (dispatch: Dispatch<Action>, ownProps?: TOwnProps) => TDispatchProps;
  withRouter?: boolean;
  withIntl?: boolean;
  withAnalyticsClient?: boolean;
}

const defaultSettings: ConnectSettings<{}> = {
  withIntl: true,
};

export function wrapComponent<
  TStateProps = {},
  TDispatchProps = {},
  TOwnProps = {},
  TInjectedProps = {}
>(
  settings: ConnectSettings<TStateProps, TDispatchProps, TOwnProps>,
  component: React.ComponentType<TStateProps & TDispatchProps & TOwnProps & TInjectedProps>
): React.ComponentType<TOwnProps> {
  const {
    mapStateToProps,
    mapDispatchToProps,
    withIntl,
    withRouter: useRouter,
    withAnalyticsClient,
  } = Object.assign({}, defaultSettings, settings);

  let result = component;

  if (mapStateToProps || mapDispatchToProps) {
    // @ts-ignore
    result = connect(mapStateToProps, mapDispatchToProps)(result);
  }

  if (withAnalyticsClient === true) {
    // @ts-ignore
    result = withAnalyticsWebClient(result);
  }

  if (withIntl === true) {
    // @ts-ignore
    result = injectIntl(result);
  }

  if (useRouter === true) {
    // @ts-ignore
    result = withRouter(result);
  }

  // @ts-ignore
  return result;
}
