import { createSelector, Selector } from 'reselect';
import { ParameterizedLocation, State } from '../reducers/index';
import { URLParameters } from '../types';
import queryString from 'query-string';
import { State as CobrandingState } from '../reducers/cobranding-reducer';
import { lookupApplication } from '../utilities/applications';

export interface ApplicationDestination {
  destinationHostname: string | null;
  applicationName: string;
}

const parseContinueUrl = (location: ParameterizedLocation): URL | null => {
  const params: URLParameters = queryString.parse(location.search);
  if (params.continue) {
    try {
      return new URL(params.continue);
    } catch {}
  }
  return null;
};

export const combiner = (
  location: ParameterizedLocation,
  cobranding: CobrandingState
): ApplicationDestination | null => {
  const continueUrl: URL | null = parseContinueUrl(location);
  const destinationHostname: string | null = (continueUrl && continueUrl.hostname) || null;
  const applicationData = lookupApplication(cobranding);

  if (applicationData) {
    if (applicationData.application === 'jira' || applicationData.application === 'confluence') {
      return {
        destinationHostname,
        applicationName: applicationData.name,
      };
    }
    return {
      destinationHostname: null,
      applicationName: applicationData.name,
    };
  }

  return null;
};

const selector: Selector<State, ApplicationDestination | null> = createSelector(
  (state: State): ParameterizedLocation => state.router.location,
  (state: State): CobrandingState => state.cobranding,
  combiner
);

export default selector;
