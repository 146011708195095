import Cookie from 'js-cookie';

/**
 * https://github.com/segmentio/analytics.js-core/blob/1e769c658d427e547e9fbe1e640fa0a5c15baaab/lib/user.js#L102
 */
const USER_ID_COOKIE_KEY = 'ajs_anonymous_id';

export function getAnonymousId(): string | undefined {
  const anonymousId = Cookie.get(USER_ID_COOKIE_KEY);
  return anonymousId && anonymousId.replace(/(^")|("$)/g, '');
}

export interface AtlGlobalAce {
  analytics: {
    SegmentIO: {
      getAnonymousId: () => string | undefined;
    };
    Initializer: {
      initAnalytics: (writeKey: string, loadOptions?: object) => void;
    };
  };
}

export default function getUserId(w: Window = window): Promise<string | undefined> {
  return createSegmentIoInstance(w).then(() => extractUserId());
}

function extractUserId(): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const ace = window.ace;
    if (ace) {
      resolve(ace.analytics.SegmentIO.getAnonymousId());
    } else {
      reject('Segment has not been loaded, or the segment user entity does not exist');
    }
  });
}

function createSegmentIoInstance(window: Window): Promise<void> {
  const segmentIoKey = window.segmentIoKey;
  const segmentIoTimeout = window.segmentIoTimeout || 5000;

  return new Promise<void>((resolve, reject) => {
    if (segmentIoKey) {
      const rejectAfterTimeout = setTimeout(reject, segmentIoTimeout);
      loadAtlGlobal(segmentIoKey, () => {
        clearTimeout(rejectAfterTimeout);
        resolve();
      });
    } else {
      reject('Segment could not be loaded');
    }
  });
}

function loadAtlGlobal(segmentIoKey, onSegmentIoLoaded) {
  if (typeof window.ace !== 'undefined') {
    if (window.console && console.error) {
      console.error('SegmentIO snippet included twice.');
      return window.ace;
    }
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://atl-global.atlassian.com/js/atl-global.min.js';
  script.onload = function () {
    if (typeof window.ace !== 'undefined') {
      window.ace.analytics.Initializer.initAnalytics(segmentIoKey);
      if (onSegmentIoLoaded) {
        onSegmentIoLoaded();
      }
    }
  };
  document.body.appendChild(script);
}
