import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';
import { InjectedRouterProps, URLParameters } from '../types';
import queryString from 'query-string';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers/index';
import ErrorPage from '../components/ErrorPage';
import messages, { auth0ErrorToKeyMapping } from './AuthCallbackErrorPage.messages';
import { useOperationalEvent } from '../utilities/analytics/analytics-web-client';
import Button from '@atlaskit/button';
import styled from '@emotion/styled';
import { isMobileOidc } from '../utilities/oidc/oidc';

interface PropsFromState {
  isMobileApp: boolean | undefined;
}

export interface OwnProps {
  error?: string;
}

type InjectedProps = InjectedIntlProps & InjectedRouterProps;

export type Props = PropsFromState & OwnProps & InjectedProps;

export const pageId = 'authCallbackErrorPage';

export const AuthCallbackErrorPage: React.ComponentType<Props> = props => {
  const { isMobileApp, location, intl, error } = props;
  const errorDescription =
    error || (queryString.parse(location.search) as URLParameters).errorDescription;

  useOperationalEvent({
    page: pageId,
    action: 'auth0FlowFailed',
    subject: 'sli',
    attributes: {
      errorName: errorDescription,
    },
  });

  const errorMapping = auth0ErrorToKeyMapping.find(mapping =>
    errorDescription?.match(mapping.regex)
  );
  const errorContent = errorMapping?.content || messages.authCallbackDefaultContent;

  return (
    <ErrorPage title={intl.formatMessage(messages.authCallbackTitle)}>
      <ContentContainer>
        <FormattedHTMLMessage {...errorContent} />
      </ContentContainer>
      {!isMobileApp && (
        <p>
          <Button href="/login" appearance="primary">
            <FormattedMessage {...messages.authCallbackRetryButton} />
          </Button>
        </p>
      )}
    </ErrorPage>
  );
};

const ContentContainer = styled.div`
  ul {
    list-style-position: inside;
  }
`;

const mapStateToProps = (state: MainState): PropsFromState => ({
  isMobileApp: isMobileOidc(state.microbranding.oidcContext) || state.microbranding.isEmbedded,
});

export default wrapComponent<PropsFromState, {}, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withRouter: true,
    withIntl: true,
  },
  AuthCallbackErrorPage
);
