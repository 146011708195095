import queryString from 'query-string';
import OriginTracing from '@atlassiansox/origin-tracing';

interface OriginTracingAttributes {
  originId: string;
}

interface Options {
  mapAttribute: (value: any) => any;
}

const cache = new Map();
export default (url?: string, options?: Options): OriginTracingAttributes => {
  if (!cache.has(url)) {
    cache.set(url, getOriginTracingAttributesFromUrl(url, options));
  }
  return cache.get(url);
};

function getOriginTracingAttributesFromUrl(
  url?: string,
  options?: Options
): OriginTracingAttributes {
  let mapAttribute = (v: any) => v;

  if (options && options.mapAttribute) {
    mapAttribute = options.mapAttribute;
  }

  let origin = OriginTracing.createEmpty();
  try {
    const urlOrigin = OriginTracing.fromUrl(url);
    if (!urlOrigin.isEmpty()) {
      origin = urlOrigin;
    }

    const continueUrl = getQueryParam(url, 'continue');
    const continueOrigin = OriginTracing.fromUrl(continueUrl);
    if (!continueOrigin.isEmpty()) {
      origin = continueOrigin;
    }
    // @ts-ignore Argument of type 'string | string[]' is not assignable to parameter of type 'string'.
    const destUrl = getQueryParam(continueUrl, 'dest-url');
    const destOrigin = OriginTracing.fromUrl(destUrl);
    if (!destOrigin.isEmpty()) {
      origin = destOrigin;
    }
  } catch (e) {
    //will fail only on malformed urls, we don't care about that
  }
  return origin.toAnalyticsAttributes({ transformValue: mapAttribute });
}

function getQueryParam(url: string | undefined, paramName: string): string | string[] | undefined {
  if (!url) {
    throw new TypeError('Bad URL');
  }

  // Base is only needed for URL() to resolve relative paths. It could be arbitrary.
  const BASE = 'http://atlassian.com/';
  const query = queryString.parse(new URL(url, BASE).search);
  return query[paramName] || undefined;
}
