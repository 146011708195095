import {
  Action,
  LoginFieldError,
  LoginGlobalError,
  JWT,
  CheckUserNameRedirectType,
  CheckUserNamePasswordlessType,
} from '../types';
import { Locale } from '../utilities/marketing-consent';

export interface State {
  isUsernameChecked: boolean;
  passwordless: CheckUserNamePasswordlessType;
  usernameExists: boolean;
  isCaptchaRequired: boolean;
  isCheckingUsername: boolean;
  isErrorShown: boolean;
  error?: LoginGlobalError;
  fieldErrors: LoginFieldError;
  isLoggingIn: boolean;
  redirectUrl: string;
  redirectType: CheckUserNameRedirectType | null;
  restrictedEmail: boolean;
  nextAttempt: number;
  showResetHint: boolean;
  localePromise?: Promise<Locale>;
  recoveryToken?: JWT;
}

export const defaultState: State = {
  isUsernameChecked: false,
  passwordless: CheckUserNamePasswordlessType.Disallowed,
  isCaptchaRequired: false,
  isCheckingUsername: false,
  isErrorShown: false,
  fieldErrors: {},
  isLoggingIn: false,
  redirectUrl: '',
  redirectType: null,
  restrictedEmail: false,
  nextAttempt: 1,
  showResetHint: false,
  usernameExists: false,
};

const login = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'LOGIN_REQUEST_CHECK_USERNAME':
      return {
        ...state,
        isCheckingUsername: true,
        isErrorShown: false,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_REDIRECT':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: false,
        usernameExists: true,
        redirectUrl: action.redirectUrl,
        redirectType: action.redirectType,
        passwordless: CheckUserNamePasswordlessType.Disallowed,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_SIGNUP':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: true,
        usernameExists: false,
        redirectUrl: '',
        redirectType: null,
        passwordless: CheckUserNamePasswordlessType.Disallowed,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_NO_ACTION':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: false,
        fieldErrors: {},
        isUsernameChecked: true,
        usernameExists: true,
        redirectUrl: '',
        redirectType: null,
        passwordless: action.passwordless,
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_FAILED':
      return {
        ...state,
        isErrorShown: false,
        isCheckingUsername: false,
        fieldErrors: { email: action.error },
      };
    case 'LOGIN_REQUEST_CHECK_USERNAME_ERROR':
      return {
        ...state,
        isCheckingUsername: false,
        isErrorShown: true,
        error: action.error,
      };
    case 'LOGIN_RESET':
      return {
        ...state,
        isCaptchaRequired: false,
        isUsernameChecked: false,
        usernameExists: false,
        isErrorShown: false,
        nextAttempt: 1,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: true,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_FAILED':
      return {
        ...state,
        isErrorShown: true,
        isLoggingIn: false,
        error: action.error,
      };
    case 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_SUCCESS':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
        redirectUrl: action.redirectUrl,
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: true,
        fieldErrors: {},
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_FAILED':
      return {
        ...state,
        isErrorShown: true,
        isLoggingIn: false,
        error: action.error,
      };
    case 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_SUCCESS':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
      };
    case 'LOGIN_REDIRECT_USER':
      return {
        ...state,
        isErrorShown: false,
        isLoggingIn: false,
        fieldErrors: {},
        redirectUrl: action.redirectUrl,
      };
    case 'LOGIN_SHOW_LOGIN_FORM_ERRORS':
      return {
        ...state,
        fieldErrors: action.errors,
      };
    case 'UPDATE_LOGIN_ATTEMPT_DETAILS':
      return {
        ...state,
        nextAttempt: action.nextAttempt,
      };
    case 'LOGIN_UPDATE_CAPTCHA_REQUIREMENT':
      return {
        ...state,
        isCaptchaRequired: action.isCaptchaRequired,
      };
    default:
      return state;
  }
};

export default login;
