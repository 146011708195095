import { defineMessages } from 'react-intl';

export default defineMessages({
  stepUpStartTitle: {
    id: 'stepUp.start.title',
    defaultMessage: 'Verify your identity',
  },
  stepUpStartEnterCodePrompt: {
    id: 'stepUp.start.form.code.prompt',
    defaultMessage: 'Enter code',
  },
  stepUpStartFlagSuccessTitle: {
    id: 'stepUp.start.sent.flag.success.title',
    defaultMessage: 'Email resent',
  },
  stepUpStartFlagSuccessDesc: {
    id: 'stepUp.start.sent.flag.success.description',
    defaultMessage: 'You should have a new code waiting for you in your inbox.',
  },
  stepUpStartFlagErrorTitle: {
    id: 'stepUp.start.sent.flag.error.title',
    defaultMessage: 'Something went wrong.',
  },
  stepUpStartFlagErrorDesc: {
    id: 'stepUp.start.sent.flag.error.description',
    defaultMessage: 'Please try again later.',
  },
  stepUpStartResendCode: {
    id: 'stepUp.start.resend.code',
    defaultMessage: "Didn't get the code? Resend it",
  },
  stepUpStartReturnToLogin: {
    id: 'stepUp.start.return.to.login',
    defaultMessage: 'Need to log in to a different account?',
  },
  stepUpStartEmailSentHeader: {
    id: 'stepUp.start.email.sent.header',
    defaultMessage: 'To access, enter the verification code that we emailed to:',
  },
  stepUpStartVerify: {
    id: 'stepUp.start.verify',
    defaultMessage: 'Verify',
  },
});
