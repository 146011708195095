import React, { useEffect } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';

import EmailSent from '../components/EmailSent';
import Button from '@atlaskit/button/standard-button';
import queryString from 'query-string';
import {
  Flag,
  InjectedAnalyticsProps,
  InjectedRouterProps,
  URLParameters,
  RedirectType,
} from '../types';
import { addFlag } from '../actions/flag-actions';
import { wrapComponent } from '../utilities/wrap-component';
import messages from './VerifyEmailSentPage.messages';
import { Dispatch } from 'redux';
import { State as MainState } from '../reducers/index';
import WhiteboxPage from '../components/WhiteboxPage';
import ExperimentWhiteboxPage from '../components/WhiteboxPageExperiment';
import {
  useFeatureExposedEvent,
  usePageViewedEvent,
} from '../utilities/analytics/analytics-web-client';
import {
  Application,
  applications,
  ApplicationData,
  lookupApplication,
} from '../utilities/applications';
import {
  getLoginExperimentFeatureFlag,
  isEligibleForLoginExperiment,
  LoginPageExperimentCohort,
  loginPageExperimentGroups,
  getResolvedCohortLoginExperiment,
  getLoginExperimentEligibility,
} from '../utilities/feature-flags';

const HeaderWrapper = styled.div`
  text-align: center;
`;

interface PropsFromState {
  email: string;
  redirectType: RedirectType;
  showBrowserWarning: boolean;
  migratingApplications?: Application[];
  applicationData: ApplicationData | null;
  isAnyMobile: boolean;
}

interface PropsFromDispatch {
  addFlag: (flag: Flag) => void;
}

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export const pageId = 'welcomeSentScreen';
export const buttonClickedEventId = 'resendWelcomeSentEmail';
export const WelcomeSentSuccessPage: React.FC<Props> = props => {
  const {
    email,
    intl: { formatMessage },
    analyticsClient,
    location,
    addFlag,
    redirectType,
    showBrowserWarning,
    migratingApplications,
    isAnyMobile,
    applicationData,
  } = props;

  const loginPageExperimentCohort = getLoginExperimentFeatureFlag(
    'aid-frontend.growth.login-page-optimisation'
  );
  const {
    cohortV2,
    isEligibleForV2,
    isEnrolledV2,
    resolvedCohortV2,
    showExperimental,
    showExperimentalBackgroundStyles,
  } = getLoginExperimentEligibility(isAnyMobile, applicationData?.application, redirectType);

  usePageViewedEvent(pageId, {
    browserWarningMessageShown: showBrowserWarning,
    redirectType,
    loginPageExperimentCohort,
    loginPageExperimentCohortV2: cohortV2,
    loginExperimentResolvedCohort: getResolvedCohortLoginExperiment(
      isAnyMobile,
      applicationData?.application,
      redirectType
    ),
    loginExperimentResolvedCohortV2: resolvedCohortV2,
  });

  function handleResendVerificationEmail() {
    const { token } = queryString.parse(location.search) as URLParameters;

    analyticsClient.buttonClickedEvent(pageId, buttonClickedEventId, {
      redirectType,
    });

    return fetch(`/rest/signup/welcome/resend${location.search}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    })
      .then(response => {
        if (response.status === 204) {
          addFlag({
            type: 'success',
            title: messages.verifyEmailFlagSuccessTitle,
            description: messages.verifyEmailFlagSuccessDesc,
          });
        } else {
          throw new Error(pageId + ' Invalid Token');
        }
      })
      .catch(() => {
        addFlag({
          type: 'error',
          title: messages.verifyEmailFlagErrorTitle,
          description: messages.verifyEmailFlagErrorDesc,
        });
      });
  }

  const isInEligibleCohorts = (cohortGroup: LoginPageExperimentCohort[]) =>
    isEligibleForLoginExperiment(
      cohortGroup,
      props.isAnyMobile,
      props.applicationData?.application,
      redirectType
    );

  const showLoginExperimentVariationStyles = () => showExperimental;

  const showLoginExperimentBackgroundStyles = () => showExperimentalBackgroundStyles;

  const Whitebox = showLoginExperimentBackgroundStyles() ? ExperimentWhiteboxPage : WhiteboxPage;

  const loginExperimentEnrollmentReason = {
    hasMobileScreenSize: isAnyMobile,
    product: applicationData?.application,
    redirectType,
  };

  useFeatureExposedEvent(pageId, {
    ruleId: 'aid-frontend.growth.login-page-optimisation',
    flagKey: 'aid-frontend.growth.login-page-optimisation',
    value: loginPageExperimentCohort,
    loginExperimentIsEnrolled: isInEligibleCohorts(loginPageExperimentGroups.enrolled),
    loginExperimentEnrollmentReason,
    loginExperimentResolvedCohort: getResolvedCohortLoginExperiment(
      isAnyMobile,
      applicationData?.application,
      redirectType
    ),
  });

  useEffect(() => {
    if (isEligibleForV2) {
      analyticsClient.featureExposedEvent(pageId, {
        ruleId: 'aid-frontend.growth.login-page-optimisation-v2',
        flagKey: 'aid-frontend.growth.login-page-optimisation-v2',
        value: cohortV2,
        loginExperimentIsEnrolled: isEnrolledV2,
        loginExperimentEnrollmentReason,
        loginExperimentResolvedCohortV2: resolvedCohortV2,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = () =>
    showLoginExperimentVariationStyles() ? (
      <HeaderWrapper>{formatMessage(messages.migrationVerifyHeader)}</HeaderWrapper>
    ) : (
      <h5>{formatMessage(messages.migrationVerifyHeader)}</h5>
    );

  return (
    <Whitebox
      pageId={pageId}
      header={renderHeader()}
      showLoginExperimentBackgroundStyles={showLoginExperimentBackgroundStyles()}
      whiteboxFooter={
        <Button
          id="welcome-send-validation-submit"
          appearance="link"
          spacing="none"
          className="link-color-override"
          onClick={handleResendVerificationEmail}>
          {formatMessage(messages.button)}
        </Button>
      }>
      <Helmet title={formatMessage(messages.headTitle)} />
      <EmailSent
        email={email}
        message={<MigrationVerifyMessage migratingApplications={migratingApplications} />}
        warningMessage={
          showBrowserWarning && (
            <FormattedMessage
              id="welcome.email.sent.browser.warning"
              defaultMessage="Make sure to verify your email on this device and browser."
              description="Telling the user to open the verification email sent by this page on the same device and browser"
            />
          )
        }
      />
    </Whitebox>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  addFlag(flag: Flag) {
    dispatch(addFlag(flag.type, flag.title, flag.description));
  },
});

const mapStateToProps = (state: MainState): PropsFromState => ({
  email: state.welcomeSent.email,
  redirectType: state.welcomeSent.redirectType,
  showBrowserWarning: state.welcomeSent.showBrowserWarning,
  migratingApplications: state.welcomeSent.migratingApplications,
  applicationData: lookupApplication(state.cobranding),
  isAnyMobile: state.microbranding.isAnyMobile,
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  WelcomeSentSuccessPage
);

type MigratingVerifyMessageProps = {
  migratingApplications: Application[] | undefined;
};
const MigrationVerifyMessage: React.FC<MigratingVerifyMessageProps> = ({
  migratingApplications,
}) => {
  return (
    <>
      {migratingApplications !== undefined && (
        <>
          <FormattedMessage
            {...messages.resumeMigrationVerifyMessage}
            values={{
              product: migratingApplications.includes('trello')
                ? applications['trello'].name
                : applications[migratingApplications[0]]?.name ?? migratingApplications[0],
            }}
          />{' '}
        </>
      )}

      <FormattedMessage {...messages.migrationVerifyMessage} />
    </>
  );
};
