/*
 * Its confusing that we have two analytic clients, so this comment may help to understand what is going on.
 * This is a new client which we are migrating to. We should have both for a transition period.
 */

import { currentEnv } from '../env';
import { getUserId } from '../user';
import { captureException, addBreadcrumb, setLocale } from './error-reporting';

import AnalyticsWebClient, {
  envType,
  originType,
  tenantType,
  userType,
  platformType,
} from '@atlassiansox/analytics-web-client';
import { dangerouslyCreateSafeString } from '@atlassiansox/analytics';
import React, { useCallback, useContext, useEffect } from 'react';
import {
  State as Microbranding,
  defaultState as defaultMicrobranding,
} from '../../reducers/microbranding-reducer';
import {
  State as Cobranding,
  defaultState as defaultCobranding,
} from '../../reducers/cobranding-reducer';
import { InjectedAnalyticsProps, LoginInfoCode, RedirectType, UserFlow } from '../../types';
import { isMobileOidc } from '../oidc/oidc';
import getOriginTracingPropertiesFromUrl from './tracing';
import { LoginHintType } from '../../selectors/login-hint';
import { lookupApplication } from '../applications';
import { getFirstContentfulPaint, getFirstPaint } from '../performance';
import { LoginType } from '../social-login';
import {
  FeatureFlags,
  LoginPageExperimentCohort,
  LoginPageExperimentCohortV2,
} from '../feature-flags';

const tags = ['identity'];

function getEnv(): string {
  switch (currentEnv) {
    case 'local':
      return envType.LOCAL;
    case 'ddev':
    case 'adev':
      return envType.DEV;
    case 'stg':
      return envType.STAGING;
    case 'prod':
      return envType.PROD;
    default:
      return envType.DEV;
  }
}

function getHost(): string {
  switch (currentEnv) {
    case 'prod':
      return 'id.atlassian.com/gateway/api/gasv3/api/v1';
    default:
      return 'id.stg.internal.atlassian.com/gateway/api/gasv3/api/v1';
  }
}

export interface EventAttributes {
  // Used to track slow rollout
  ffsId?: string;
  // These are used for exposure events
  flagKey?: string;
  reason?: string;
  ruleId?: string;
  // These are a long list of other potential attributes, to be documented
  redirectReason?: string;
  firstProductAccessed?: string;
  isMobileApp?: boolean;
  isEnterprise?: boolean;
  successfulAuthentication?: boolean;
  errorCode?: string;
  value?: any;
  referer?: string;
  redirectedFromLogin?: boolean;
  accountCount?: number;
  accountIndex?: number;
  loginHintType?: LoginHintType;
  loginType?: LoginType;
  cobrandingConfluence?: string;
  cobrandingJira?: string;
  hideLogout?: boolean;
  sessionCount?: number;
  sessionIndex?: number;
  origin?: string;
  status?: 'checked' | 'unchecked' | 'not_shown';
  linkExpired?: boolean;
  isMicrosoftButtonEnabled?: boolean;
  isAppleButtonEnabled?: boolean;
  isSlackButtonEnabled?: boolean;
  reasonViewingScreen?: string;
  requestAccessLinkShown?: boolean;
  marketingConsent?: string;
  errorName?: string;
  isAppleHiddenEmail?: boolean;
  recovKeyOption?: string;
  confirmationCreateAccountNeeded?: boolean;
  confirmationVerifyEmailNeeded?: boolean;
  verificationCodeEntered?: boolean;
  flow?: string;
  application?: string;
  isAuthenticatedUser?: boolean;
  redirectType?: string;
  source?: string;
  browserWarningMessageShown?: boolean;
  mfaBackend?: string;
  loginPageExperimentCohort?: LoginPageExperimentCohort;
  loginPageExperimentCohortV2?: LoginPageExperimentCohortV2;
  multifactorMethod?: string;
  loginExperimentEnrollmentReason?: EnrollmentReasonLoginExperiment;
  loginExperimentIsEnrolled?: boolean;
  loginExperimentResolvedCohort?: string;
  loginExperimentResolvedCohortV2?: string;
  noAccess?: boolean;
  accounts?: Array<string>;
}

export interface FeatureExposedAttributes {
  /** A feature flag ruleId, which may be hard coded for now. */
  ruleId: string;
  /** The feature flag being evaluated */
  flagKey: keyof FeatureFlags;
  /** The resolved value of the feature flag or cached value */
  value: FeatureFlags[keyof FeatureFlags];

  /** Added for login optimisation experiment. Remove me when experiment over. */
  loginExperimentIsEnrolled?: boolean;
  loginExperimentEnrollmentReason?: EnrollmentReasonLoginExperiment;
  loginExperimentResolvedCohort?: string;
  loginExperimentResolvedCohortV2?: string;
}

type EnrollmentReasonLoginExperiment = {
  hasMobileScreenSize: boolean;
  hasContentTitle?: boolean;
  product?: string;
  contentType?: string;
  redirectType?: RedirectType | LoginInfoCode;
};

export type UiEventSubject = 'button' | 'form' | 'input' | 'error' | 'link' | 'checkbox';

export type UiEventAction = 'submitted' | 'clicked' | 'changed' | 'shown';

export interface UiEvent {
  page: string;
  action: UiEventAction;
  subject: UiEventSubject;
  // Unique id of event subject (ex. signupFormSubmitButton)
  subjectId?: string;
  attributes?: EventAttributes;
}

/*
 * Tracking event could be anything (like user created), so all fields are plain strings
 * ex.
 * {
 *   page:      'createAccountPage',
 *   action:    'created',
 *   subject:   'user',
 *   subjectId: '<userId>',
 * }
 */
export interface TrackingEvent {
  page: string;
  action: string;
  subject: string;
  subjectId?: string;
  attributes?: EventAttributes;
}

/*
 * Operational event is similar to tracking event in structure
 * but is for events that are not necessarily triggered by the user
 * ex.
 * {
 *   page:      'createAccountPage',
 *   action:    'created',
 *   subject:   'user',
 *   subjectId: '<userId>',
 * }
 */
export interface OperationalEvent {
  page: string;
  action: string;
  subject: string;
  subjectId?: string;
  attributes?: EventAttributes;
}

export type ApdexType = 'initialLoad' | 'transition';

export interface ApdexEvent {
  task: string;
  taskId?: string;
  type: ApdexType;
  additionalAttributes?: object;
}

export function appendReferer(eventAttributes: EventAttributes): EventAttributes {
  try {
    const referer = new URL(document.referrer);
    referer.search = ''; // lets minimise possibility of tokens in referer by cleaning up query params
    return {
      ...eventAttributes,
      referer: referer.toString(),
    };
  } catch (e) {
    return eventAttributes;
  }
}

export function appendReasonViewingScreen(
  eventAttributes: EventAttributes,
  userFlow?: UserFlow
): EventAttributes {
  if (userFlow) {
    return {
      ...eventAttributes,
      reasonViewingScreen: userFlow,
    };
  }

  return eventAttributes;
}

function determinePlatform(microbranding: Microbranding) {
  if (isMobileOidc(microbranding.oidcContext) || microbranding.isEmbedded) {
    return platformType.MOBILE_WEB;
  }

  return platformType.WEB;
}

function createDefaultWebClient(
  microbranding: Microbranding,
  locale?: string,
  tenantCloudId?: string,
  userId?: string
): AnalyticsWebClient {
  const client = new AnalyticsWebClient(
    {
      env: getEnv(),
      product: 'identity',
      origin: originType.WEB,
      platform: determinePlatform(microbranding),
      locale: locale || 'en',
    },
    {
      apiHost: getHost(),
    }
  );

  const idForAnalytics = getUserId() || userId;
  if (idForAnalytics) {
    client.setUserInfo(userType.ATLASSIAN_ACCOUNT, idForAnalytics);
  }

  if (tenantCloudId) {
    client.setTenantInfo(tenantType.CLOUD_ID, tenantCloudId);
  } else {
    client.setTenantInfo(tenantType.NONE);
  }

  return client;
}

interface AnalyticsWebClient {
  sendTrackEvent: (_: any, callback?: any) => void;
  sendOperationalEvent: (_: any, callback?: any) => void;
  sendScreenEvent: (_: any, callback?: any) => void;
  sendUIEvent: (_: any, callback?: any) => void;
  stopApdexEvent: any;
  setUserInfo: any;
  setTenantInfo: any;
}

export interface AnalyticsClient {
  featureExposedEvent(page: string, featureExposedAttributes: FeatureExposedAttributes);
  formSubmittedEvent(page: string, formId: string, attributes?: EventAttributes): Promise<void>;
  buttonClickedEvent(page: string, buttonId: string, attributes?: EventAttributes): Promise<void>;
  linkClickedEvent(page: string, linkId: string, attributes?: EventAttributes): Promise<void>;
  checkboxChangedEvent(
    page: string,
    checkboxId: string,
    attributes?: EventAttributes
  ): Promise<void>;
  trackingEvent(event: TrackingEvent): void;
  operationalEvent(event: OperationalEvent, callback?: (error?: Error) => void): void;
  pageViewedEvent(page: string, eventAttributes?: EventAttributes): void;
  errorShownEvent(
    page: string,
    subjectId: string,
    eventAttributes?: EventAttributes
  ): Promise<void>;
  uiEvent(event: UiEvent): Promise<void>;
  stopApdexEvent(apdexEvent: ApdexEvent): void;
  stopInitialLoadApdexEvent(task: string): void;
}

type ConstructorArgs = {
  microbranding: Microbranding;
  cobranding: Cobranding;
  locale: string | undefined;
  tenantCloudId: string | undefined;
  userId: string | undefined;
  client?: AnalyticsWebClient;
  ffsId: string;
};

export class AnalyticsClientImpl implements AnalyticsClient {
  private readonly _client: AnalyticsWebClient;
  private readonly microbranding: Microbranding;
  private readonly cobranding: Cobranding;
  private readonly ffsId: string;

  constructor(args: ConstructorArgs) {
    const {
      microbranding,
      cobranding,
      locale,
      tenantCloudId,
      userId,
      client = createDefaultWebClient(microbranding, locale, tenantCloudId, userId),
      ffsId,
    } = args;

    try {
      setLocale(locale);
      this.microbranding = microbranding;
      this.cobranding = cobranding;
      this._client = client;
      this.ffsId = ffsId;
    } catch (error) {
      captureException(error);
    }
  }

  /**
   * This function sends the operational event required by the Experimentation Platform.
   * In the future, this would be done automatically if we adopt the client side
   * feature flag client that sends these transparently.
   *
   * KIRBY-2032 - This feature exposed event is currently sent as a tracking event, but it should be an operational event.
   * This is for legacy reasons, and should be fixed in the (near) future.
   */
  featureExposedEvent(page: string, featureExposedAttributes: FeatureExposedAttributes) {
    this.trackingEvent({
      page,
      action: 'exposed',
      subject: 'feature',
      attributes: {
        flagKey: featureExposedAttributes.flagKey,
        reason: 'RULE_MATCH',
        ruleId: featureExposedAttributes.ruleId,
        value: featureExposedAttributes.value,
        loginExperimentIsEnrolled: featureExposedAttributes.loginExperimentIsEnrolled,
        loginExperimentEnrollmentReason: featureExposedAttributes.loginExperimentEnrollmentReason,
        loginExperimentResolvedCohort: featureExposedAttributes.loginExperimentResolvedCohort,
        loginExperimentResolvedCohortV2: featureExposedAttributes.loginExperimentResolvedCohortV2,
      },
    });
  }

  formSubmittedEvent(page: string, formId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'submitted',
      subject: 'form',
      subjectId: formId,
      attributes,
    });
  }

  buttonClickedEvent(page: string, buttonId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'clicked',
      subject: 'button',
      subjectId: buttonId,
      attributes,
    });
  }

  linkClickedEvent(page: string, linkId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'clicked',
      subject: 'link',
      subjectId: linkId,
      attributes,
    });
  }

  checkboxChangedEvent(
    page: string,
    checkboxId: string,
    attributes?: EventAttributes
  ): Promise<void> {
    return this.uiEvent({
      page,
      action: 'changed',
      subject: 'checkbox',
      subjectId: checkboxId,
      attributes,
    });
  }

  trackingEvent(event: TrackingEvent) {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'tracking',
      message: `UI ${event.action} tracking event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    try {
      this._client.sendTrackEvent({
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        attributes,
        tags,
      });
    } catch (error) {
      captureException(error, { event });
    }
  }

  operationalEvent(event: OperationalEvent, callback?: (error?: Error) => void) {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'operational',
      message: `${event.action} operational event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    try {
      this._client.sendOperationalEvent(
        {
          source: event.page,
          actionSubject: event.subject,
          action: event.action,
          actionSubjectId: event.subjectId,
          attributes,
          tags,
        },
        callback
      );
    } catch (error) {
      captureException(error, { event });
      if (callback) {
        callback(error);
      }
    }
  }

  pageViewedEvent(page: string, eventAttributes?: EventAttributes) {
    const attributes = this.enrichAttributes(eventAttributes);

    addBreadcrumb({
      category: 'pageViewed',
      message: 'Page viewed event of page id ' + page,
      data: {
        source: page,
        ...attributes,
      },
    });

    try {
      this._client.sendScreenEvent({
        name: page,
        attributes,
      });
    } catch (error) {
      captureException(error, { page });
    }
  }

  errorShownEvent(page: string, subjectId: string, eventAttributes?: EventAttributes) {
    return this.uiEvent({
      page,
      action: 'shown',
      subject: 'error',
      subjectId,
      attributes: eventAttributes,
    });
  }

  uiEvent(event: UiEvent): Promise<void> {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'ui',
      message: `UI ${event.action} event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    return new Promise(resolve => {
      try {
        this._client.sendUIEvent(
          {
            source: event.page,
            actionSubject: event.subject,
            action: event.action,
            actionSubjectId: event.subjectId,
            attributes,
            tags,
          },
          () => resolve()
        );
      } catch (error) {
        captureException(error, { event });
        // error is already captured, no point in putting the burden upon consumers by rejecting since they likely want to proceed
        resolve();
      }
    });
  }

  stopApdexEvent(apdexEvent: ApdexEvent) {
    try {
      this._client.stopApdexEvent(apdexEvent);
    } catch (error) {
      captureException(error, { event: apdexEvent });
    }
  }

  stopInitialLoadApdexEvent(task: string) {
    this.stopApdexEvent({
      task,
      type: 'initialLoad',
      additionalAttributes: {
        properties: {
          firstPaint: getFirstPaint(),
          firstContentfulPaint: getFirstContentfulPaint(),
        },
      },
    });
  }

  private enrichAttributes(eventAttributes?: EventAttributes): EventAttributes {
    const originTracingProperties = getOriginTracingPropertiesFromUrl(window.location.href, {
      mapAttribute: value =>
        typeof value === 'string' ? dangerouslyCreateSafeString(value) : value,
    });
    const applicationData = lookupApplication(this.cobranding);
    const application = applicationData ? applicationData.application : 'unknown';
    const firstProductAccessed = { firstProductAccessed: application };

    return {
      ...firstProductAccessed,
      ...originTracingProperties,
      isMobileApp: this.microbranding.isMobileApp,
      ...eventAttributes,
      ffsId: this.ffsId,
    };
  }
}

function createNoopWebClient(): AnalyticsWebClient {
  function logCall(functionName: string) {
    return function () {
      console.log(
        `You are using noop analytics web client. ${functionName} called. Check that you provide AnalyticsClientContext for you React component tree.`,
        arguments
      );
    };
  }

  return {
    sendTrackEvent: logCall('sendTrackEvent'),
    sendOperationalEvent: logCall('sendOperationalEvent'),
    sendScreenEvent: logCall('sendScreenEvent'),
    sendUIEvent: logCall('sendUIEvent'),
    stopApdexEvent: logCall('stopApdexEvent'),
    setUserInfo: logCall('setUserInfo'),
    setTenantInfo: logCall('setTenantInfo'),
  };
}

export const AnalyticsClientContext = React.createContext<AnalyticsClient>(
  new AnalyticsClientImpl({
    microbranding: defaultMicrobranding,
    cobranding: defaultCobranding,
    locale: undefined,
    tenantCloudId: undefined,
    userId: undefined,
    client: createNoopWebClient(),
    ffsId: '',
  }) // default value
);

export const useAnalyticsClient = () => useContext(AnalyticsClientContext);

export const withAnalyticsWebClient = <P extends {}>(
  WrappedComponent: React.ComponentClass<P & InjectedAnalyticsProps>
): React.ComponentType<P> => {
  const WithAnalyticsWebClient = props => {
    const analyticsClient = useAnalyticsClient();
    return <WrappedComponent {...props} analyticsClient={analyticsClient} />;
  };
  WithAnalyticsWebClient.displayName = `withAnalyticsClient(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithAnalyticsWebClient;
};

/** Send the given event when the component is mounted. Will only be sent once, make sure that the event is fully constructed. */
export const useFeatureExposedEvent = (
  page: string,
  featureExposedAttributes: FeatureExposedAttributes
) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.featureExposedEvent(page, featureExposedAttributes);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

/** Send the given event when the component is mounted. Will only be sent once, make sure that the event is fully constructed. */
export const useTrackingEvent = (event: TrackingEvent) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.trackingEvent(event);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const usePageViewedEvent = (page: string, eventAttributes?: EventAttributes) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.pageViewedEvent(page, eventAttributes);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useButtonClickedEvent = (
  page: string,
  buttonId: string,
  attributes?: EventAttributes
) => {
  const analyticsClient = useAnalyticsClient();

  return useCallback(() => {
    analyticsClient.buttonClickedEvent(page, buttonId, attributes);
  }, [analyticsClient, page, buttonId, attributes]);
};

export const useOperationalEvent = (
  operationalEvent: OperationalEvent,
  callback?: (error?: Error) => void
) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.operationalEvent(operationalEvent, callback);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
