import { Action, CsrfToken } from '../types';

export type State = CsrfToken;

export const defaultState: State = '';

const csrfToken = (state: State = defaultState, action: Action): CsrfToken =>
  action.type === 'CSRF_TOKEN_UPDATE' ? action.csrfToken : state;

export const hashedCsrfToken = (state: State = defaultState): CsrfToken => state;

export default csrfToken;
