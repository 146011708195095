import { Cobranding } from '../../utilities/cobranding/cobranding';
import { CobrandingComponents } from './shared-cobranding';

import DefaultCobranding from './DefaultCobranding';
import BitbucketCobranding from './bitbucket/BitbucketCobranding';
import CompassCobranding from './compass/CompassCobranding';
import ConfluenceCobranding from './confluence/ConfluenceCobranding';
import JiraCobranding from './jira/JiraCobranding';
import OpsgenieCobranding from './opsgenie/OpsgenieCobranding';
import StatuspageCobranding from './statuspage/StatuspageCobranding';
import TrelloCobranding from './trello/TrelloCobranding';
import WacCobranding from './wac/WacCobranding';
import AtlasCobranding from './atlas/AtlasCobranding';

const cobrandingMap: { [K in Cobranding]: CobrandingComponents } = {
  'no-cobranding': DefaultCobranding,
  bitbucket: BitbucketCobranding,
  compass: CompassCobranding,
  confluence: ConfluenceCobranding,
  jira: JiraCobranding,
  opsgenie: OpsgenieCobranding,
  statuspage: StatuspageCobranding,
  trello: TrelloCobranding,
  wac: WacCobranding,
  atlas: AtlasCobranding,
};

export const getCobrandedComponents = (cobranding: Cobranding): CobrandingComponents =>
  cobrandingMap[cobranding];
