import { currentEnv, Env } from '.././utilities/env';
import MetalClient, {
  BrowserMetricsPlugin,
  catalog,
  Env as MetalEnvType,
  envTypes as metalEnvType,
} from '@atlassiansox/metal-client';
import { captureException } from './analytics/error-reporting';

export let metalClient: MetalClient | undefined;

export type EnvMappingType = {
  [key in Env]: MetalEnvType;
};

const internalToMetalEnvMap: EnvMappingType = {
  unknown: metalEnvType.LOCAL,
  local: metalEnvType.LOCAL,
  adev: metalEnvType.DEV,
  ddev: metalEnvType.DEV,
  stg: metalEnvType.STAGING,
  prod: metalEnvType.PROD,
};

export const getMetalEnvironment = (): MetalEnvType => {
  return internalToMetalEnvMap[currentEnv] || 'local';
};

const productInfo = {
  metalId: '106040e7-7aa1-40d4-af50-1464ab63b642', // (OBSHELP-3455)
  version: process.env.REACT_APP_VERSION || 'no-version-found',
  env: getMetalEnvironment(),
};

export const startMetalClient = () => {
  try {
    metalClient = new MetalClient({
      settings: {
        getPage: () => window.location.pathname.slice(1),
      },
      plugins: [
        new BrowserMetricsPlugin({
          rootId: 'root',
          histograms: {
            [catalog.performance.FIRST_MEANINGFUL_PAINT]: '2000',
            [catalog.performance.FIRST_CONTENTFUL_PAINT]: '2000',
            [catalog.performance.TIME_TO_INTERACTIVE]: '3800',
          },
        }),
      ],
      productInfo,
    });
  } catch (e) {
    console.error(`Failed to Submit metal Metric ${e}`);
    captureException(e);
  }
};
