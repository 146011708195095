import { GoogleLoginType, InHouseSocialLoginType } from '../utilities/social-login';
import { Action, ConfirmationErrorCode, ConfirmationFieldError, UserFlow } from '../types';

interface BaseState {
  error: ConfirmationErrorCode | undefined;
  fieldErrors: ConfirmationFieldError;
  redirectTo: string | null;
  isLoading?: boolean;
  userFlow?: UserFlow;
}

export interface GoogleState extends BaseState {
  source: string;
  loginType: GoogleLoginType;
  displayName: string;
  email: string;
  accessToken: string;
  idToken: string;
  createNeeded: boolean;
  verifyNeeded: boolean;
}

export interface InHouseSocialLoginState extends BaseState {
  loginType: InHouseSocialLoginType;
  displayName: string;
  email: string;
  confirmationToken: string;
  mfaToken?: string;
  createNeeded: boolean;
  verifyNeeded: boolean;
}

export type State = BaseState | GoogleState | InHouseSocialLoginState;

export const defaultState: BaseState = {
  error: undefined,
  fieldErrors: {},
  redirectTo: null,
  isLoading: false,
};

const createAccount = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'CONFIRMATION_RESET':
      return defaultState;
    case 'CONFIRMATION_REQUEST':
      return {
        ...state,
        fieldErrors: {},
        error: undefined,
        isLoading: true,
      };
    case 'CONFIRMATION_REQUEST_SUCCESS':
      return {
        ...state,
        fieldErrors: {},
        isLoading: false,
        redirectTo: action.redirectTo,
      };
    case 'CONFIRMATION_RESEND_SUCCESS':
      return {
        ...(state as InHouseSocialLoginState),
        mfaToken: action.mfaToken,
      };
    case 'CONFIRMATION_REQUEST_FAILED':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case 'CONFIRMATION_SHOW_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: action.errors,
        isLoading: false,
      };
    default:
      return state;
  }
};

// @ts-ignore
export default createAccount;
