import { Dispatch as ReduxDispatch } from 'redux';
import { State } from '../reducers';
import { Application } from '../utilities/applications';
import { ScreenSize } from '../utilities/style-utils';
import { ThunkDispatch as ReduxThunkDispatch, ThunkAction as ReduxThunkAction } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { CallHistoryMethodAction } from '../actions/history-actions';
import { ForcedResetPasswordMessage } from '../containers/ExpiredPasswordPage';
import { UnforcedResetPasswordMessage } from '../containers/ResetPasswordPage';
import {
  ChangePasswordMessage,
  SecurityWasMeForcedResetPasswordMessage,
  SecurityWasNotMeForcedResetPasswordMessage,
  ChangeEmailFlowMessage,
} from '../containers/ChangePasswordPage';
import { ConfirmationAction } from '../actions/confirmation-actions';
import { ChangePasswordAction } from '../actions/change-password-actions';
import { MarketingConsentAction } from '../actions/marketing-consent-actions';
import { MarketingConsent } from '../utilities/marketing-consent';

export enum CheckUserNameRedirectType {
  Sso = 'sso',
  EmailSent = 'email_sent',
}
export enum CheckUserNamePasswordlessType {
  Disallowed = 'disallowed',
  Allowed = 'allowed',
  // May have 'Forced' in the future if we know the user has no password
}
export type JWT = string;
export type JWTValidationError =
  | 'invalid_token'
  | 'expired_token'
  | 'future_issued_token'
  | 'additional_claim_mismatch';

export type CsrfToken = string;
export type ContextPath = string;
export type Signature = string;
export type AnonymousId = string;

export interface MicrobrandingParams {
  application?: Application;
  continue?: string;
  tenant?: string;
}

export interface InjectedAnalyticsProps {
  analyticsClient: AnalyticsClient;
}

export type InjectedRouterProps = RouteComponentProps<URLParameters>;

export interface Auth0Config {
  domain: string;
  clientId: string;
  callbackUrl: string;
  tenant: string;
  tokenIssuer: string;
  deferRules: Record<'apple' | 'microsoft' | 'slack', Auth0DeferRulesConfig>;
}

export interface Auth0DeferRulesConfig {
  clientId: string;
  callbackUrl: string;
}

export interface AppConfig {
  contextPath: string;
  auth0Config: Auth0Config;
  bitbucketSignupUrl: string;
  bitbucketSignupUrlOverrideEnabled: boolean;
  googleAuthClientId: string;
  isSamlEnabled: boolean;
  recaptchaEnable: boolean;
  recaptchaEnterpriseCheckboxKeySite: string;
  recaptchaEnterpriseInvisibleKeySite: string;
  segmentIoKey: string;
  sentryUrl: string;
  marketingConsentApiUrl: string;
  castleAppId: string | undefined;
  castlePublishableApiKey: string | undefined;
}

export type ResetPasswordMesage =
  | UnforcedResetPasswordMessage
  | ForcedResetPasswordMessage
  | SecurityWasMeForcedResetPasswordMessage
  | SecurityWasNotMeForcedResetPasswordMessage
  | ChangeEmailFlowMessage;

export interface URLParameters {
  display?: string;
  token?: string;
  continue?: string;
  email?: string;
  displayName?: string;
  application?: Application;
  tenant?: string;
  productHint?: string;
  bitbucketSignupUrl?: string;
  shide?: string;
  errorCode?: string;
  infoCode?: string;
  userFlow?: UserFlow;
  signature?: Signature;
  message?: ResetPasswordMesage | ChangePasswordMessage;
  redirectedFrom?: string;
  enableMicrosoft?: string;
  enableApple?: string;
  state?: string;
  showRequestAccessLink?: string;
  disableEmailInput?: string;
  restrict?: string;
  errorDescription?: string;
  transactionToken?: string;
}

export interface ContinueURLParameters {
  client_id?: string;
  'openid.return_to'?: string;
  'dest-url'?: string;
  hostname?: string;
  pathname?: string;
}

export interface CreateAccountConfirmationURLHashParameters {
  source?: string;
  loginType?: string;
  fullName?: string;
  email?: string;
  createNeeded?: string;
  verifyNeeded?: string;
}

export type LoginErrorCode =
  | 'invalid_user_password'
  | 'captcha'
  | 'unknown'
  | 'ambiguous_user'
  | 'no_email_on_remote_user'
  | 'bitbucket_username_is_not_email'
  | 'username_is_not_email'
  | 'bitbucket_email_is_not_primary'
  | 'email_is_not_primary'
  | 'too_many_logins'
  | 'multiple_google_accounts'
  | 'password_leaked'
  | 'unable_to_verify_google_token'
  | 'hosted_domain_mismatch'
  | 'google_email_change_not_allowed_by_auth_policy'
  | 'google_email_change_not_allowed_by_user_managed_status'
  | 'social_login_missing_email'
  | 'social_login_missing_email_verified'
  | 'social_login_unexpected_login_type'
  | 'social_login_missing_slack_email_date_verified'
  | 'social_login_invalid_social_id'
  | 'slack_login_invalid_team_id'
  | 'social_login_managed_user'
  | 'social_login_requires_password_change'
  | 'social_login_hide_email_change_not_allowed_to_bypass_policy'
  | 'social_login_email_change_not_allowed_to_bypass_policy'
  | 'bitbucket_mfa_enforced'
  | 'bitbucket_mfa_required'
  | 'bitbucket_saml_user'
  | 'login.form.token.expired'
  | 'login.form.problem.encountered'
  | 'login.form.email.does.not.match.loggedin.user'
  | 'switch.session.expired'
  | 'request_error';

export interface LoginState {
  csrfToken: CsrfToken;
  anonymousId?: AnonymousId;
  marketingConsent?: MarketingConsent;
  userFlow?: UserFlow;
}

export interface LoginGlobalError {
  code: LoginErrorCode;
  args?: { [key: string]: string };
}

export type LoginInfoCode = 'migratingExistingUser' | 'existingUserSignupAttempt' | 'invitedUser';

export interface LoginGlobalInfo {
  code: LoginInfoCode;
}

export interface LoginFieldError {
  email?: string;
  password?: string;
}

export type SignupErrorCode = 'unknown' | 'captcha' | 'request_error' | string;

export type WelcomeErrorCode = 'unknown' | 'captcha' | 'request_error' | string;

export type RecoveryTsvErrorCode = 'unknown' | string;

export type MigratingUserInfoCode = 'existingUser' | 'newUser';

export type ConfirmationErrorCode = 'backend' | 'offline' | 'unknown';

/**
 * A parameter used for analytics purposes.
 * Because we reuse a lot of pages in different flows, it's helpful to be able
 *  to know why a user started on a common experience, and where they ended up.
 * This parameter is provided to us since not all consumers of Aa experiences
 *  are using GAS, so we lose visibility going from say, Trello to Aa.
 */
export type UserFlow = 'migratingUser';

export interface SignupFieldError {
  email?: string;
}

export interface WelcomeFieldError {
  displayName?: string;
  emailUsedAsName?: string;
  passwordUsedAsName?: string;
  password?: string;
}

export interface TsvRecoveryFieldError {
  password?: string;
}

export interface ConfirmationFieldError {
  displayName?: string;
  code?: string;
}

export interface ResetPasswordFieldError {
  email?: string;
  captcha?: string;
}

export interface ChangePasswordFieldErrors {
  password?: string;
}

export type ChangePasswordError =
  | { type: 'UNKNOWN_ERROR'; message: undefined; cause?: Error }
  | { type: 'BACKEND_ERROR'; message: string }
  | { type: 'PASSWORD_LEAKED' };

/**
 * Basically a formError, but with a key.
 */
export interface UiError {
  key: string;
  error: string;
}

export type MfaBackend = 'id-authentication' | 'auth0';

export type MultifactorMethod = 'otp' | 'sms';
export interface MultiFactorOTPError {
  errorCode: 'invalid_otp' | 'invalid_otp_format' | 'invalid_token';
  message: string;
}
export interface MultiFactorRecoveryError {
  errorCode: 'invalid_recovery_code' | 'invalid_recovery_code_format' | 'invalid_token';
  message: string;
}
export type OtpCodeFieldError = {
  errorCode:
    | 'invalid_otp_pattern'
    | 'invalid_otp'
    | 'invalid_otp_format'
    | 'invalid_token'
    | 'expired_otp'
    | 'unknown';
};
export type MultiFactorStartSmsEnrollError =
  | 'invalid_phone_number'
  | 'too_many_sms'
  | 'generic_error';
export type MultiFactorError = MultiFactorOTPError | MultiFactorRecoveryError;
export type ResetExpiredPasswordError = 'invalid_token' | 'too_many_requests' | 'generic_error';

export interface MessageDescriptor {
  id: string;
  description?: string;
  defaultMessage?: string;
}
export interface Flag {
  type: 'success' | 'error';
  title: MessageDescriptor;
  description: MessageDescriptor;
  id?: string;
}

export type Action =
  | { type: 'CSRF_TOKEN_UPDATE'; csrfToken: CsrfToken }
  | { type: 'WELCOME_SIGNUP_REQUEST' }
  | { type: 'WELCOME_SIGNUP_REQUEST_SUCCESS'; redirectTo: string }
  | { type: 'WELCOME_SIGNUP_REQUEST_FAILED'; formError: WelcomeErrorCode }
  | { type: 'WELCOME_SIGNUP_RESET' }
  | { type: 'WELCOME_SIGNUP_SHOW_FIELD_ERRORS'; errors: WelcomeFieldError }
  | { type: 'LOGIN_REQUEST_CHECK_USERNAME' }
  | {
      type: 'LOGIN_REQUEST_CHECK_USERNAME_REDIRECT';
      redirectUrl: string;
      redirectType: CheckUserNameRedirectType;
    }
  | { type: 'LOGIN_REQUEST_CHECK_USERNAME_SIGNUP' }
  | {
      type: 'LOGIN_REQUEST_CHECK_USERNAME_NO_ACTION';
      passwordless: CheckUserNamePasswordlessType;
    }
  | { type: 'LOGIN_REQUEST_CHECK_USERNAME_FAILED'; error: string }
  | { type: 'LOGIN_REQUEST_CHECK_USERNAME_ERROR'; error: LoginGlobalError }
  | { type: 'LOGIN_RESET' }
  | { type: 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN' }
  | { type: 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_SUCCESS'; redirectUrl: string }
  | { type: 'LOGIN_REQUEST_USERNAME_PASSWORD_LOGIN_FAILED'; error: LoginGlobalError }
  | { type: 'LOGIN_REQUEST_PASSWORDLESS_LOGIN' }
  | { type: 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_SUCCESS' }
  | { type: 'LOGIN_REQUEST_PASSWORDLESS_LOGIN_FAILED'; error: LoginGlobalError }
  | { type: 'LOGIN_REDIRECT_USER'; redirectUrl: string }
  | { type: 'LOGIN_SHOW_LOGIN_FORM_ERRORS'; errors: LoginFieldError }
  | { type: 'UPDATE_LOGIN_ATTEMPT_DETAILS'; nextAttempt: number }
  | { type: 'LOGIN_UPDATE_CAPTCHA_REQUIREMENT'; isCaptchaRequired: boolean }
  | { type: 'RESET_PASSWORD_RESET' }
  | { type: 'RESET_PASSWORD_REQUEST_LINK' }
  | { type: 'RESET_PASSWORD_REQUEST_LINK_SUCCESS' }
  | { type: 'RESET_PASSWORD_REQUEST_LINK_REDIRECT'; redirectTo: string }
  | {
      type: 'RESET_PASSWORD_REQUEST_LINK_FAILED_WITH_FIELD_ERRORS';
      fieldErrors: ResetPasswordFieldError;
    }
  | { type: 'RESET_PASSWORD_ERROR'; error: MessageDescriptor | string }
  | { type: 'SIGNUP_REQUEST' }
  | { type: 'SIGNUP_REQUEST_SUCCESS'; redirectTo: string }
  | { type: 'SIGNUP_REQUEST_FAILED'; error: SignupErrorCode }
  | { type: 'SIGNUP_RESET' }
  | { type: 'SIGNUP_SHOW_FIELD_ERRORS'; errors: SignupFieldError }
  | { type: 'VERIFICATION_EMAIL_RESENT' }
  | { type: 'VERIFICATION_EMAIL_ERROR' }
  | { type: 'GUARDIAN_ENROLLMENT_DETAILS_REQUEST' }
  | {
      type: 'GUARDIAN_ENROLLMENT_DETAILS_RESPONSE';
      method: MultifactorMethod;
      phoneNumber?: string;
    }
  | {
      type: 'GUARDIAN_RESEND_SMS';
      params: string;
      analyticsClient: AnalyticsClient;
    }
  | {
      type: 'GUARDIAN_VERIFY';
      params: string;
      otpCode: string;
      analyticsClient: AnalyticsClient;
    }
  | {
      type: 'GUARDIAN_RECOVER';
      params: string;
      recoveryCode: string;
      analyticsClient: AnalyticsClient;
    }
  | {
      type: 'GUARDIAN_CONFIRMED_RECOVER';
      params: string;
      analyticsClient: AnalyticsClient;
      signature?: string;
    }
  | { type: 'GUARDIAN_VERIFY_RESPONSE'; signature: string }
  | { type: 'GUARDIAN_RECOVER_RESPONSE'; signature: string; recoveryCode: string }
  | ({ type: 'GUARDIAN_VERIFY_ERROR' } & MultiFactorOTPError)
  | ({ type: 'GUARDIAN_RECOVER_ERROR' } & MultiFactorRecoveryError)
  | { type: 'RECOVERY_EMAIL_START' }
  | { type: 'RECOVERY_EMAIL_SENT' }
  | { type: 'RECOVERY_EMAIL_ERROR' }
  | { type: 'TSV_RECOVERY_RESET' }
  | { type: 'TSV_RECOVERY_LOGIN_REQUEST_LINK_REDIRECT'; redirectTo: string }
  | { type: 'TSV_RECOVERY_REQUEST_FAILED'; error: string }
  | { type: 'TSV_RECOVERY_SHOW_FIELD_ERRORS'; error: TsvRecoveryFieldError }
  | { type: 'USER_MANAGED_STATUS'; isManaged: boolean }
  | { type: 'START_ENROLL'; token: string | null }
  | {
      type: 'START_ENROLL_RESPONSE';
      transactionToken: string;
      emailAddress: string;
      totpSecret: string;
      recoveryCode: string;
      totpUri: string;
    }
  | { type: 'CONFIRM_ENROLL'; otpCode: string; continueUrl: string | null | undefined }
  | { type: 'CONFIRM_ENROLL_RESPONSE'; redirectUrl: string | null | undefined }
  | { type: 'CONFIRM_ENROLL_ERROR' }
  | { type: 'START_SMS_ENROLL'; phoneNumber: string; token: string | null }
  | {
      type: 'START_SMS_ENROLL_RESPONSE';
      transactionToken: string;
      recoveryCode: string;
      phoneNumber: string;
    }
  | { type: 'START_SMS_ENROLL_ERROR'; smsEnrollError: MultiFactorStartSmsEnrollError }
  | { type: 'SCREEN_SIZE_UPDATED'; screenSize: ScreenSize }
  | { type: 'FLAG_ADD'; flag: Flag }
  | { type: 'FLAG_DISMISS' }
  | { type: 'EXPIRED_PASSWORD_RESET_REQUEST_INITIATE' }
  | { type: 'EXPIRED_PASSWORD_RESET_REQUEST_SUCCESS' }
  | { type: 'EXPIRED_PASSWORD_RESET_REQUEST_FAILED'; error: ResetExpiredPasswordError }
  | { type: 'MANAGE_SESSION_RESPONSE'; redirectUrl: string }
  | { type: 'GOOGLE_ONE_TAP_DISMISS' }
  | { type: 'SESSION_REQUEST' }
  | { type: 'STEP_UP_RESEND_CODE_SUCCESS'; mfaToken: string }
  | CallHistoryMethodAction // XXX: This pattern should be continued.
  | ChangePasswordAction // -action.ts files should declare and export their action union
  | ConfirmationAction
  | MarketingConsentAction;

// eslint-disable-next-line no-use-before-define
export type ThunkDispatch = ReduxThunkDispatch<State, undefined, Action>;
export type ThunkAction<R = Promise<any>> = ReduxThunkAction<R, State, undefined, Action>;
export type Dispatch = ReduxDispatch<Action> & ThunkDispatch;

export type MultiFactorMode =
  | 'OTP_CODE'
  | 'RECOVERY_CODE'
  | 'RECOVERY_EMAIL'
  | 'RECOVERY_EMAIL_SENT';

export enum OidcDisplay {
  Login = 'login',
  Signup = 'signup',
  Continue = 'continue',
  LoginWithRedirect = 'login_with_redirect',
  Reverify = 'reverify',
  ManageSessions = 'manage_sessions',
}

export interface OidcContext {
  display?: OidcDisplay;
  // productHint and product are both deprecated in favour of the injected cobranding object
  productHint?: string;
  product?: string;
}

export enum VerificationType {
  Verify = 'verify',
  VerifyWithSoftSession = 'verifyWithSoftSession',
  Reverify = 'reverify',
}

export enum VerifyOrReverifyEmailError {
  ExpiredToken = 'expired_token_error',
  InvalidToken = 'invalid_token_error',
  RateLimitExceeded = 'ratelimit_exceeded_error',
}

export enum EmailConfirmationError {
  InvalidPassword = 'invalid_password',
  InvalidToken = 'invalid_token',
}

export enum WelcomeError {
  RateLimitExceeded = 'rate_limit_exceeded',
}

export enum WelcomeSentError {
  ExpiredToken = 'expired_token',
  InvalidToken = 'invalid_token',
}

export enum RedirectType {
  ResumeMigration = 'resume_migration',
  Migration = 'migration',
  Signup = 'signup',
  ResumeSignup = 'resume_signup',
  Invite = 'invite',
}

export interface ServerError {
  type: 'bad_request' | 'not_found' | 'internal_server_error' | 'csrf_token_mismatch';
}
