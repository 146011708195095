import React, { useCallback, useEffect, useState } from 'react';
import { wrapComponent } from '../utilities/wrap-component';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import { Dispatch, Flag, InjectedRouterProps, OidcContext, OtpCodeFieldError } from '../types';
import { PrivacyPolicyLink, UserNoticeLink } from '../components/links';
import { isMobileOidc } from '../utilities/oidc/oidc';
import { State as MainState } from '../reducers/index';
import Helmet from 'react-helmet';
import messages from './StepUpStartPage.messages';
import EmailSent from '../components/EmailSent';
import OtpCodeField from '../components/OtpCodeField';
import ButtonContainer from '../components/ButtonContainer';
import Button from '@atlaskit/button';
import { addFlag } from '../actions/flag-actions';
import { Link } from 'react-router-dom';
import {
  resendCode,
  StepUpResendResult,
  StepUpVerificationResult,
  verifyOtpCode,
} from '../actions/step-up-actions';
import Bull from '../components/BulletSeparator';
import styled from '@emotion/styled';
import {
  useAnalyticsClient,
  usePageViewedEvent,
} from '../utilities/analytics/analytics-web-client';

const StyledButton = styled(Button)`
  button& {
    font-weight: 400;
  }
`;

interface PropsFromState {
  email: string;
  mfaToken: string;
  oidcContext: OidcContext | null;
}

interface FooterProps {
  oidcContext: OidcContext | null;
}

interface PropsFromDispatch {
  addFlag: (Flag) => void;
  verifyOtpCode: (otpCode: string) => Promise<StepUpVerificationResult>;
  resendCode: () => Promise<StepUpResendResult>;
}

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps;

export type Props = OwnProps & PropsFromState & PropsFromDispatch & InjectedProps;

export const pageId = 'stepUpOtpChallengeScreen';

const TRANSITION_DURATION = 400;

const OTP_CODE_LENGTH = 8;

export const StepUpStartPage: React.ComponentType<Props> = props => {
  const analyticsClient = useAnalyticsClient();
  const { intl, location, verifyOtpCode, addFlag } = props;
  const [otpCode, setOtpCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [verificationError, setVerificationError] = useState<OtpCodeFieldError>();

  usePageViewedEvent(pageId);

  const handleSubmit = useCallback(async () => {
    analyticsClient.uiEvent({
      page: pageId,
      subject: 'form',
      subjectId: 'otpForm',
      action: 'submitted',
    });

    setLoading(true);
    try {
      const result = await verifyOtpCode(otpCode);
      if ('redirectUri' in result) {
        analyticsClient.trackingEvent({
          page: pageId,
          action: 'succeeded',
          subject: 'stepUpChallenge',
        });
        window.location.assign(result.redirectUri);
      } else {
        analyticsClient.trackingEvent({
          page: pageId,
          action: 'failed',
          subject: 'stepUpChallenge',
          attributes: {
            errorCode: result.errorCode,
          },
        });
        setVerificationError(result);
      }
    } catch {
      const errorCode = 'unknown';

      analyticsClient.trackingEvent({
        page: pageId,
        action: 'failed',
        subject: 'stepUpChallenge',
        attributes: {
          errorCode,
        },
      });
      setVerificationError({ errorCode });
    } finally {
      setLoading(false);
    }
  }, [otpCode, verifyOtpCode, analyticsClient]);

  useEffect(() => {
    if (otpCode.length === OTP_CODE_LENGTH) {
      handleSubmit();
    }
  }, [handleSubmit, otpCode]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationError(undefined);
    setOtpCode(e.target.value);
  };

  const handleResendCode = async () => {
    const { resendCode } = props;
    analyticsClient.linkClickedEvent(pageId, 'resendOtp');

    const result = await resendCode();
    if (result.success) {
      addFlag({
        type: 'success',
        title: messages.stepUpStartFlagSuccessTitle,
        description: messages.stepUpStartFlagSuccessDesc,
      });
    } else {
      addFlag({
        type: 'error',
        title: messages.stepUpStartFlagErrorTitle,
        description: messages.stepUpStartFlagErrorDesc,
      });
    }
  };

  const handleVerify = () => {
    analyticsClient.buttonClickedEvent(pageId, 'verifyOtp');
  };

  const handleReturnToLogin = () => {
    analyticsClient.linkClickedEvent(pageId, 'switchAccount');
  };

  const returnLoginLink = `/login${location.search}`;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.stepUpStartTitle)}</title>
      </Helmet>
      <WhiteboxPage
        pageId={pageId}
        header={<FormattedMessage {...messages.stepUpStartTitle} tagName="h5" />}
        footer={<StepUpFooter oidcContext={props.oidcContext} />}
        whiteboxFooter={
          <>
            <ul>
              <li>
                <StyledButton
                  id="resend-code"
                  appearance="link"
                  spacing="none"
                  onClick={handleResendCode}>
                  <FormattedMessage {...messages.stepUpStartResendCode} />
                </StyledButton>
              </li>
            </ul>
            <ul>
              <li>
                <Link
                  id="return-to-login"
                  data-testid="return-to-login"
                  to={returnLoginLink}
                  onClick={handleReturnToLogin}>
                  <FormattedMessage {...messages.stepUpStartReturnToLogin} />
                </Link>
              </li>
            </ul>
          </>
        }>
        <EmailSent
          email={props.email}
          message={intl.formatMessage(messages.stepUpStartEmailSentHeader)}
        />
        <form autoComplete="off" onSubmit={handleSubmit}>
          <OtpCodeField
            id="otp-code-input"
            intl={props.intl}
            label={intl.formatMessage(messages.stepUpStartEnterCodePrompt)}
            visible={true}
            disabled={isLoading}
            fieldError={verificationError}
            onChange={handleInputChange}
            transitionDuration={TRANSITION_DURATION}
            codeLength={OTP_CODE_LENGTH}
          />
          <ButtonContainer id="verify-submit">
            <TallButton
              appearance="primary"
              type="submit"
              isFullWidth
              isLoading={isLoading}
              onClick={handleVerify}>
              <FormattedMessage {...messages.stepUpStartVerify} />
            </TallButton>
          </ButtonContainer>
        </form>
      </WhiteboxPage>
    </>
  );
};

const mapStateToProps = (state: MainState): PropsFromState => {
  const {
    stepUp: { email, mfaToken },
    microbranding: { oidcContext },
  } = state;
  return {
    email,
    mfaToken,
    oidcContext,
  };
};

const StepUpFooter: React.ComponentType<FooterProps> = ({ oidcContext }) => {
  return (
    <div>
      <ul className="fine-print">
        <li>
          <PrivacyPolicyLink isMobileLink={isMobileOidc(oidcContext)} />
        </li>
        <Bull />
        <li>
          <UserNoticeLink isMobileLink={isMobileOidc(oidcContext)} />
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  addFlag(flag: Flag) {
    dispatch(addFlag(flag.type, flag.title, flag.description));
  },
  resendCode(): Promise<StepUpResendResult> {
    return dispatch(resendCode());
  },
  verifyOtpCode(otpCode: string): Promise<StepUpVerificationResult> {
    return dispatch(verifyOtpCode(otpCode));
  },
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withIntl: true,
    withRouter: true,
  },
  StepUpStartPage
);
