import React from 'react';
import Loadable, { LoadableComponent } from 'react-loadable';
import LoadingPage from '../components/LoadingPage';
import { OwnProps as UnauthorizedPageProps } from './UnauthorizedPage';
import { OwnProps as MfaEnrollmentConfigureAppProps } from '../components/MfaEnrollmentConfigureApp';
import { OwnProps as MultiFactorPageProps } from './MultiFactorPage';
import { OwnProps as CreateAccountConfirmationPageProps } from './CreateAccountConfirmationPage';

export enum LoginPages {
  UnauthorizedPage = 'UnauthorizedPage',
  LoginPage = 'LoginPage',
  ResetPasswordPage = 'ResetPasswordPage',
  ContinueAsPage = 'ContinueAsPage',
  SelectAccountPage = 'SelectAccountPage',
  RemoveAccountPage = 'RemoveAccountPage',
  SignupPage = 'SignupPage',
  CreateAccountConfirmationPage = 'CreateAccountConfirmationPage',
  WelcomePage = 'WelcomePage',
  MigrateAccountConfirmationPage = 'MigrateAccountConfirmationPage',
  RecoveryPage = 'RecoveryPage',
}

export enum MfaPages {
  GetAppPage = 'GetAppPage',
  ConfigureAppPage = 'ConfigureAppPage',
  ConfigurePhonePage = 'ConfigurePhonePage',
  SaveRecoveryKeyPage = 'SaveRecoveryKeyPage',
}

interface LoadableComponentProps<T> {
  component: T;
}

const loadableComponent = <T extends LoadableComponentProps<any>>(
  loader: Function,
  loadableSettings?: any
): LoadableComponent & React.ComponentType<T> => {
  return Loadable({
    loader,
    loading: props => <LoadingPage {...props} />,
    render(loaded, props) {
      const { component } = props;
      if (!component) {
        return <LoadingPage error="No component prop" isFatalError={true} {...props} />;
      } else if (!loaded[component]) {
        return (
          <LoadingPage error={`No component for key ${component}`} isFatalError={true} {...props} />
        );
      }
      const LoadedComponent = loaded[component];
      return <LoadedComponent {...props} />;
    },
    timeout: 10000,
    ...loadableSettings,
  });
};

const loadablePage = <T extends any>(
  loader: Function,
  loadableSettings?: any
): LoadableComponent & React.ComponentType<T> => {
  return Loadable({
    loader,
    loading: () => null,
    ...loadableSettings,
  });
};

type MfaEnrollmentComponentsProps = (MfaEnrollmentConfigureAppProps | object) &
  LoadableComponentProps<MfaPages>;
type LoginComponentsProps = (UnauthorizedPageProps | CreateAccountConfirmationPageProps | object) &
  LoadableComponentProps<LoginPages>;

export const MfaEnrollment: LoadableComponent &
  React.ComponentType<MfaEnrollmentComponentsProps> = loadableComponent<
  MfaEnrollmentComponentsProps
>(() => import('./MfaEnrollmentComponents' /* webpackChunkName: "mfa" */), { delay: 100 });
export const LoginComponents: LoadableComponent &
  React.ComponentType<LoginComponentsProps> = loadableComponent<LoginComponentsProps>(() =>
  import('./LoginComponents' /* webpackChunkName: "login" */)
);
export const MfaAppsPage = loadablePage(() =>
  import('../components/MfaAppsPage' /* webpackChunkName: "mfa.apps" */)
);
export const VerifyEmailSentPage = loadablePage(() =>
  import('./VerifyEmailSentPage' /* webpackChunkName: "verify.email" */)
);
export const ReverifyEmailSentPage = loadablePage(() =>
  import('./ReverifyEmailSentPage' /* webpackChunkName: "reverify.email" */)
);
export const ExpiredPasswordPage = loadablePage(() =>
  import('./ExpiredPasswordPage' /* webpackChunkName: "expired.password" */)
);
export const MfaEnrollmentPage = loadablePage(() =>
  import('./MfaEnrollmentPage' /* webpackChunkName: "mfa.enrollment" */)
);
export const MultiFactorPage: LoadableComponent &
  React.ComponentType<MultiFactorPageProps> = loadablePage<MultiFactorPageProps>(() =>
  import('./MultiFactorPage' /* webpackChunkName: "multi.factor" */)
);
export const CancelAccountDeletionPage = loadablePage(() =>
  import('./CancelAccountDeletionPage' /* webpackChunkName: "cancel.account.delete" */)
);
export const DeletionRequestConfirmationPage = loadablePage(() =>
  import('./DeletionRequestConfirmationPage' /* webpackChunkName: "delete.request.confirmation" */)
);
export const InactiveAccountPage = loadablePage(() =>
  import('./InactiveAccountPage' /* webpackChunkName: "inactive.account" */)
);
export const PendingDeletionPage = loadablePage(() =>
  import('./PendingDeletionPage' /* webpackChunkName: "pending.deletion" */)
);
