import React, { Component, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import FormTextField from './FormTextField';

import ButtonContainer from './ButtonContainer';
import TallButton from './TallButton';
import messages from './OtpCodeField.messages';

import { InjectedIntl } from 'react-intl';
import { OtpCodeFieldError } from '../types';

interface Props {
  id: string;
  label?: string;
  disabled: boolean;
  fieldError?: OtpCodeFieldError;
  intl: InjectedIntl;
  onChange: (object: { target: HTMLInputElement | null | undefined }) => void;
  visible: boolean;
  transitionDuration: number;
  codeLength: 6 | 8;
}

interface State {
  value: string;
  fieldError: OtpCodeFieldError | null | undefined;
}

export default class OtpCodeField extends Component<Props, State> {
  fieldText: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = { value: '', fieldError: props.fieldError || null };
  }

  handlePaste = (e: ClipboardEvent) => {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const selectionStart = target.selectionStart || 0;
    const selectionEnd = target.selectionEnd || target.value.length;
    const start = target.value.substr(0, selectionStart);
    const middle = (e.clipboardData || window.clipboardData)
      .getData('text/plain')
      .replace(/\D/g, '')
      .substr(0, target.maxLength - target.value.length - selectionStart + selectionEnd);
    const end = target.value.substr(selectionEnd);
    //timeout to allow input update UI
    setTimeout(() => this.setState({ value: start + middle + end }));
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value.replace(/\D/g, ''), fieldError: null });
  };

  handleInvalid = (e: Event) => {
    e.preventDefault();
    this.setState({
      fieldError: {
        errorCode: 'invalid_otp_pattern',
      },
    });
  };

  componentDidMount() {
    const input = this.fieldText;
    if (input) {
      input.addEventListener('invalid', this.handleInvalid);
      input.addEventListener('paste', this.handlePaste);
    }
  }

  componentWillUnmount() {
    const input = this.fieldText;
    if (input) {
      input.removeEventListener('invalid', this.handleInvalid);
      input.removeEventListener('paste', this.handlePaste);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.fieldError && this.props.fieldError) {
      this.setState({ fieldError: this.props.fieldError });
      this.fieldText && this.fieldText.select();
    }
    setTimeout(() => {
      if (this.props.visible) {
        this.fieldText && this.fieldText.focus();
      }
    }, this.props.transitionDuration);
    if (this.state.value !== prevState.value) {
      this.props.onChange &&
        this.props.onChange({
          target: this.fieldText,
        });
    }
  }

  errorContent(error: OtpCodeFieldError) {
    const { formatMessage } = this.props.intl;

    switch (error && error.errorCode) {
      case 'invalid_otp':
        return formatMessage(messages.errorsInvalidOtp);
      case 'invalid_otp_format':
        return formatMessage(messages.errorsInvalidOtpFormat);
      case 'invalid_otp_pattern':
        return formatMessage(messages.errorsInvalidOtpPattern);
      case 'expired_otp':
        return formatMessage(messages.errorsExpiredOtp);
      case 'unknown':
        return formatMessage(messages.errorsUnknown);
      case 'invalid_token':
        return (
          <form onSubmit={() => window.location.reload()}>
            <FormattedMessage
              id="mf.mfform.errors.invalid_token"
              defaultMessage="Your session has expired. Please refresh the page and try again."
            />
            <ButtonContainer>
              <TallButton type="submit" isFullWidth>
                <FormattedMessage id="common.refresh" defaultMessage="Refresh" />
              </TallButton>
            </ButtonContainer>
          </form>
        );
      default:
        return undefined;
    }
  }

  render() {
    return (
      <FormTextField
        id={this.props.id}
        name="otpCode"
        type="tel"
        placeholder={this.props.intl.formatMessage(messages.mfFormPlaceholderNDigits, {
          codeLength: this.props.codeLength,
        })}
        label={this.props.label}
        autoFocus
        isLabelHidden={true}
        onChange={this.handleChange}
        isInvalid={!!this.state.fieldError}
        invalidMessage={
          !this.props.disabled && this.state.fieldError && this.errorContent(this.state.fieldError)
        }
        value={this.state.value}
        isDisabled={this.props.disabled}
        pattern={`\d{${this.props.codeLength}}`}
        maxLength={this.props.codeLength}
        required
        ref={input => (this.fieldText = input)}
      />
    );
  }
}
