import styled from '@emotion/styled';
import securityWall from '../images/security-wall.svg';
import { media } from '../utilities/style-utils';
import { gridSize } from '@atlaskit/theme';
import React from 'react';

const TRANSITION_DURATION = 400;

type SecurityWallStyle = 'default' | 'password-reset';

const DefaultSecurityWallContainer = styled.div<{ visible?: boolean }>`
  background: url(${securityWall}) no-repeat center 0 / ${gridSize() * 14.5}px;
  height: ${({ visible }) => (visible === false ? 0 : 'auto')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: visibility ${TRANSITION_DURATION}ms;
  min-width: 100%;
  padding-top: ${gridSize() * 18}px;
  box-sizing: border-box;

  ${media.handheld`
    background: 0;
    padding: 0;
  `}
`;

const SuccessfulResetSecurityWallContainer = styled.div<{ visible?: boolean }>`
  background: url(${securityWall}) no-repeat center;
  height: ${({ visible }) => (visible === false ? 0 : gridSize() * 14.5)}px;
  transition: visibility ${TRANSITION_DURATION}ms;
  margin-bottom: ${({ visible }) => (visible === false ? 0 : gridSize() * 1.5)}px;
`;

interface Props {
  children?: React.ReactNode;
  id?: string;
  visible?: boolean;
  securityWallStyle?: SecurityWallStyle;
}

export class SecurityWall extends React.Component<Props> {
  static defaultProps = {
    visible: true,
  };

  chooseWall = (securityWallStyle?: SecurityWallStyle) => {
    switch (securityWallStyle) {
      case 'password-reset':
        return SuccessfulResetSecurityWallContainer;
      default:
        return DefaultSecurityWallContainer;
    }
  };

  render() {
    const { children, id, visible, securityWallStyle } = this.props;
    const WallComponent = this.chooseWall(securityWallStyle);

    return (
      <WallComponent id={id} visible={visible}>
        {children}
      </WallComponent>
    );
  }
}
