import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';

const iconSize = '24px';
export default styled.span`
  color: ${colors.N500};
  height: ${iconSize};
  width: ${iconSize};

  + div input {
    margin-right: ${iconSize};
  }
`;
