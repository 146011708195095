import { defineMessages } from 'react-intl';

export default defineMessages({
  // Flags
  reverifyEmailFlagSuccessTitle: {
    id: 'reverify.email.sent.flag.success.title',
    defaultMessage: "We've sent you an email!",
  },
  reverifyEmailFlagSuccessDesc: {
    id: 'reverify.email.sent.flag.success.description',
    defaultMessage: 'The verification email is on its way!',
  },
  reverifyEmailFlagErrorTitle: {
    id: 'reverify.email.sent.flag.error.title',
    defaultMessage: 'Something went wrong.',
  },
  reverifyEmailFlagErrorDesc: {
    id: 'reverify.email.sent.flag.error.description',
    defaultMessage: 'Please try again later.',
  },
  headTitle: {
    id: 'reverify.email.sent.head.title',
    defaultMessage: 'Verify your email',
  },
});
