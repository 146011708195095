export type Env = 'local' | 'ddev' | 'adev' | 'stg' | 'prod' | 'unknown';

function inferEnvFromHostname(hostname: string): Env {
  if (hostname === 'localhost') {
    return 'local';
  }

  if (hostname.endsWith('.stg.internal.atlassian.com') || hostname.endsWith('.stg.atlassian.com')) {
    return 'stg';
  }

  if (
    hostname.endsWith('-domain.dev.internal.atlassian.com') ||
    hostname.endsWith('-domain.dev.atlassian.com')
  ) {
    return 'ddev';
  }

  if (hostname.endsWith('.dev.internal.atlassian.com') || hostname.endsWith('.dev.atlassian.com')) {
    return 'adev';
  }

  if (hostname.endsWith('.atlassian.com')) {
    return 'prod';
  }

  return 'unknown';
}

export const currentEnv: Env = inferEnvFromHostname(window.location.hostname);
export const isLocal: boolean = 'local' === currentEnv;
export const isDev: boolean = ['ddev', 'adev'].includes(currentEnv);
export const isStaging: boolean = 'stg' === currentEnv;
export const isProd: boolean = 'prod' === currentEnv;
export const isUnknown: boolean = 'unknown' === currentEnv;

const idacOrigins: Record<Env, string> = {
  local: 'http://localhost:8080', // idproxy local
  ddev: 'https://id-domain.dev.internal.atlassian.com',
  adev: 'https://id.dev.internal.atlassian.com',
  stg: 'https://id.stg.internal.atlassian.com',
  prod: 'https://id.atlassian.com',
  unknown: 'https://id.atlassian.com',
};
export const idacOrigin = idacOrigins[currentEnv];
