import { Action, OtpCodeFieldError, ThunkAction } from '../types';

export type StepUpVerificationSuccess = { redirectUri: string };
export type StepUpVerificationResult = StepUpVerificationSuccess | OtpCodeFieldError;
export type StepUpResendResult = { success: true } | { success: false; errorCode: string };

export const resendCodeSuccess = (mfaToken: string): Action => ({
  type: 'STEP_UP_RESEND_CODE_SUCCESS',
  mfaToken,
});

export const resendCode = (): ThunkAction<Promise<StepUpResendResult>> => async (
  dispatch,
  getState
) => {
  const {
    stepUp: { mfaToken },
    csrfToken,
  } = getState();
  try {
    const response = await fetch(`/rest/mfa/resend?transactionToken=${mfaToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      dispatch(resendCodeSuccess(responseBody.transactionToken));
      return { success: true };
    } else {
      return { success: false, errorCode: 'resend_failed' };
    }
  } catch (error) {
    return { success: false, errorCode: 'resend_failed' };
  }
};

export const verifyOtpCode = (otpCode: string): ThunkAction => async (dispatch, getState) => {
  const { stepUp, csrfToken, router } = getState();
  const continueUrl = new URLSearchParams(router.location.search).get('continue');
  try {
    const response = await fetch(`/rest/mfa/verify?transactionToken=${stepUp.mfaToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({
        otpCode,
      }),
    });

    if (response.ok) {
      const responseBody = await response.json();
      const loginAuthorizeResp = await fetch(
        `/rest/mfa/authorize?transactionToken=${responseBody.transactionToken}`
      );

      if (loginAuthorizeResp.ok) {
        const loginAuthorizeUrl = new URL((await loginAuthorizeResp.json()).redirectUri);
        if (continueUrl !== null) {
          loginAuthorizeUrl.searchParams.append('continue', continueUrl);
        }
        return { redirectUri: loginAuthorizeUrl.toString() };
      } else {
        return { errorCode: 'invalid_otp' };
      }
    } else {
      if (response.status === 403) {
        return { errorCode: 'invalid_otp' };
      } else {
        return { errorCode: 'unknown' };
      }
    }
  } catch (error) {
    return { errorCode: 'unknown' };
  }
};
