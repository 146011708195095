import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';
import { State as MainState } from '../reducers';
import { InjectedAnalyticsProps, InjectedRouterProps } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import WhiteboxPage from '../components/WhiteboxPage';
import { SecurityWall } from '../components/SecurityWall';

interface PropsFromState {}

interface PropsFromDispatch {}

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export class SecurityExpiredLinkPage extends Component<Props> {
  static pageId = 'securityExpiredLinkScreen';

  componentDidMount() {
    this.props.analyticsClient.pageViewedEvent(SecurityExpiredLinkPage.pageId);
  }

  buildHeader = () => (
    <b>
      <FormattedMessage
        id="security-expiredlink-title"
        defaultMessage="The link we sent you has expired"
      />
    </b>
  );

  render() {
    return (
      // @ts-ignore no id
      <WhiteboxPage pageId={SecurityExpiredLinkPage.pageId} header={this.buildHeader()}>
        <SecurityWall id="security-expired-link-wall" securityWallStyle={'password-reset'} />
        <p>
          <FormattedMessage
            id="security-expiredlink-message"
            defaultMessage="It looks like the link we sent you is no longer valid."
          />
        </p>

        <p>
          <FormattedHTMLMessage
            id="security-expiredlink-call-to-action"
            defaultMessage="For security reasons, if your account has been compromised we’ll need you to update your password by clicking the <strong>Can’t log in?</strong> link from the Atlassian login screen."
          />
        </p>
      </WhiteboxPage>
    );
  }
}

const mapStateToProps = (state: MainState): PropsFromState => ({});

export default wrapComponent<PropsFromState, PropsFromState, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  SecurityExpiredLinkPage
);
