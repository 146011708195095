import styled from '@emotion/styled';
import { colors, gridSize } from '@atlaskit/theme';

const HEADER_HEIGHT = 56;
const SECONDARY_NAV_WIDTH = 240;

export const Background = styled.div`
  background-color: ${colors.N0};
  @media (max-width: ${gridSize() * 100}px) {
    display: none;
    background: ${colors.N0};
    color: ${colors.N800};
  }
`;

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  @media (min-width: 1200px) {
    path.small {
      visibility: hidden;
    }
    path.medium {
      visibility: hidden;
    }
    path.large {
      visibility: visible;
    }
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    path.small {
      visibility: hidden;
    }
    path.large {
      visibility: hidden;
    }
    path.medium {
      visibility: visible;
    }
  }
  @media (max-width: 1000px) {
    path.small {
      visibility: visible;
    }
    path.medium {
      visibility: hidden;
    }
    path.large {
      visibility: hidden;
    }
  }
`;

export const Search = styled.img`
  position: fixed;
  top: 0;
  right: 0;
`;

export const HeaderLine = styled.div`
  display: inline-block;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  height: 2px;
  width: 100%;
  background: ${colors.N40};
  z-index: 1;
`;

export const SecondaryNavBackground = styled.div`
  display: inline-block;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  height: 100%;
  width: ${SECONDARY_NAV_WIDTH}px;
  background: ${colors.N20};
  z-index: -1;
`;

export const SecondaryNav = styled.img`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: ${SECONDARY_NAV_WIDTH}px;
`;

export const IssueBreadcrumbs = styled.img`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: ${SECONDARY_NAV_WIDTH}px;
  z-index: -1;
`;

export const IssueActions = styled.img`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  right: 0;
`;

export const IssueContentWrapper = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT + 88}px;
  left: ${SECONDARY_NAV_WIDTH + 24}px;
  right: 400px;
  height: 80%;
`;

export const IssueContent = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 60%;
  min-height: 464px;
`;

export const DetailsPanel = styled.img`
  position: fixed;
  top: ${HEADER_HEIGHT + 88}px;
  right: 0;
`;
