import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import errorImage from '../images/Error.svg';
import WhiteboxPage from '../components/WhiteboxPage';
import styled from '@emotion/styled';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

export const pageId = 'welcomeSentExpiredTokenErrorPage';

export const WelcomeSentExpiredTokenErrorPage: React.FC = () => {
  usePageViewedEvent(pageId);

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <MessageContainer>
            <FormattedMessage
              id="welcome.sent.expire.title"
              defaultMessage="The link has expired"
              description="Telling the user that there is an error with the welcome link. The error is caused by submitting the resend link again after the token has expired."
              tagName="h5"
            />
          </MessageContainer>
          <img src={errorImage} width="99" alt="" />
        </div>
      }>
      <p>
        <FormattedHTMLMessage
          id="welcome.sent.expire.body"
          defaultMessage="Check your inbox for the verification email."
          description="Telling the user that the welcome resend link is expired and they're not verified. The error is caused by submitting the resend link again after the token has expired. The user should try again by clicking the link in their email."
        />
      </p>
    </WhiteboxPage>
  );
};

export default WelcomeSentExpiredTokenErrorPage;
