import React from 'react';
import WhiteboxPage from '../components/WhiteboxPage';
import { InjectedIntlProps, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { wrapComponent } from '../utilities/wrap-component';
import errorImage from '../images/Error.svg';
import { InjectedAnalyticsProps, InjectedRouterProps, VerificationType } from '../types';

import TallButton from '../components/TallButton';
import ButtonContainer from '../components/ButtonContainer';
import styled from '@emotion/styled';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

interface OwnProps {
  recoverUrl: string;
  verificationType: VerificationType;
}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = OwnProps & InjectedProps;

export const VerifyOrReverifyEmailExpiredTokenErrorPage: React.FC<Props> = ({
  analyticsClient,
  recoverUrl,
  verificationType,
}) => {
  const pageId = 'verifyEmailExpiredTokenErrorPage';

  usePageViewedEvent(pageId, { flow: verificationType });

  const recover = () => {
    analyticsClient.buttonClickedEvent(pageId, 'resendVerificationEmail', {
      flow: verificationType,
    });
    window.location.assign(recoverUrl);
  };

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <MessageContainer>
            <FormattedMessage
              id="reverify.email.expire.title"
              defaultMessage="Resend verification email"
              tagName="h5"
            />
          </MessageContainer>
          <img src={errorImage} width="99" alt="" />
        </div>
      }>
      <p>
        <FormattedHTMLMessage
          id="reverify.email.expire.body"
          defaultMessage="The link to verify your account has expired. Please try again"
        />
      </p>
      <ButtonContainer>
        <TallButton
          appearance="primary"
          id="reverify.email.expire.button"
          isFullWidth
          onClick={recover}>
          <FormattedMessage
            id="reverify.email.expire.button"
            defaultMessage="Resend verification email"
          />
        </TallButton>
      </ButtonContainer>
    </WhiteboxPage>
  );
};

export default wrapComponent<{}, {}, OwnProps, InjectedProps>(
  {
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  VerifyOrReverifyEmailExpiredTokenErrorPage
);
