import React from 'react';
import { wrapComponent } from '../utilities/wrap-component';
import ButtonContainer from '../components/ButtonContainer';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import errorImage from '../images/Error.svg';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import styled from '@emotion/styled';
import { InjectedAnalyticsProps, VerificationType } from '../types';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

interface OwnProps {
  recoverUrl: string;
  verificationType: VerificationType;
}

type InjectedProps = InjectedAnalyticsProps;

type Props = OwnProps & InjectedProps;

export const VerifyOrReverifyEmailInvalidTokenErrorPage: React.FC<Props> = ({
  analyticsClient,
  recoverUrl,
  verificationType,
}) => {
  const pageId = 'verifyEmailInvalidTokenErrorPage';

  usePageViewedEvent(pageId, { flow: verificationType });

  const recover = () => {
    analyticsClient.buttonClickedEvent(pageId, 'startAgain', {
      flow: verificationType,
    });

    window.location.assign(recoverUrl);
  };

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <MessageContainer>
            <FormattedMessage
              id="reverify.email.invalid-token.error.title"
              defaultMessage="We couldn't verify your email"
              tagName="h5"
            />
          </MessageContainer>
          <img src={errorImage} width="99" alt="" margin-top="24px" />
        </div>
      }>
      <p>
        <FormattedHTMLMessage
          id="reverify.email.invalid-token.error.main-action"
          defaultMessage="Something went wrong while trying to validate your email."
        />
      </p>
      <ButtonContainer>
        <TallButton appearance="primary" id="go-back-button" isFullWidth onClick={recover}>
          <FormattedMessage
            id="reverify.email.invalid-token.error.button-text"
            defaultMessage="Go back to verify your account"
          />
        </TallButton>
      </ButtonContainer>
    </WhiteboxPage>
  );
};

export default wrapComponent<{}, {}, OwnProps, InjectedProps>(
  {
    withAnalyticsClient: true,
  },
  VerifyOrReverifyEmailInvalidTokenErrorPage
);
