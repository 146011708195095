import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'email-confirmation.title',
    defaultMessage: 'Confirm the update of your email',
  },
  incorrectPassword: {
    id: 'email-confirmation.form.incorrect-password',
    defaultMessage: 'Your password was incorrect',
  },
  invalidTokenTitle: {
    id: 'email-confirmation.invalid-token.title',
    defaultMessage: 'Unable to verify your email address',
  },
  invalidTokenDescription: {
    id: 'email-confirmation.invalid-token.description',
    defaultMessage:
      'Something went wrong while trying to validate your email. The link you followed may have expired or be broken. Please log in to try again.',
  },
  invalidTokenReturnToLoginButton: {
    id: 'email-confirmation.invalid-token.return-login-button',
    defaultMessage: 'Return to log in',
  },
});
