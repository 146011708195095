import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { AtlassianLogo } from '@atlaskit/logo';
import { colors } from '@atlaskit/theme';
import errorImage from '../images/Error.svg';
import { getAnonymousId } from '../utilities/analytics/segment-io';

interface Props {
  title: ReactNode;
  children?: ReactNode;
}

export default function ErrorPage({ title, children }: Props) {
  const anonymousId = getAnonymousId();
  return (
    <ErrorContainer>
      <AtlassianLogo
        size="large"
        label="Atlassian"
        textColor={colors.B400}
        iconColor={colors.B200}
        iconGradientStart={colors.B400}
        iconGradientStop={colors.B200}
      />
      <div>
        <img src={errorImage} height="150" alt="" className="error-image" />
      </div>
      <h2 className="error-title">{title}</h2>
      {!!children && <div className="error-content">{children}</div>}

      <small className="error-diagnostic-details">
        <code>{anonymousId || '—'}</code>
      </small>
    </ErrorContainer>
  );
}

const ErrorContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
  padding: 100px 10px 0px 10px;
  text-align: center;

  .error-image {
    padding: 24px 0px;
  }

  .error-title {
    padding-top: 0px;
  }

  .error-content {
    padding-top: 16px;
  }

  .error-action {
    padding-top: 24px;
  }

  .error-diagnostic-details {
    display: block;
    padding-top: 24px;
  }
`;
