import React from 'react';
import { EmailConfirmationError } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers/index';
import EmailConfirmationInvalidTokenErrorPage from './EmailConfirmationInvalidTokenErrorPage';
import EmailConfirmationFormPage from './EmailConfirmationFormPage';

interface PropsFromState {
  error: EmailConfirmationError | null;
}

type Props = PropsFromState;

export const EmailConfirmationPage: React.FC<Props> = ({ error }) => {
  if (error === EmailConfirmationError.InvalidToken) {
    return <EmailConfirmationInvalidTokenErrorPage />;
  } else {
    return <EmailConfirmationFormPage />;
  }
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  error: state.changeEmail.error,
});

export default wrapComponent<PropsFromState>(
  {
    mapStateToProps,
  },
  EmailConfirmationPage
);
