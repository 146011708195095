import React, { Component } from 'react';
import {
  AnalyticsClientImpl,
  AnalyticsClientContext,
} from '../utilities/analytics/analytics-web-client';
import { Microbranding } from '../reducers/microbranding-reducer';
import { State as Cobranding } from '../reducers/cobranding-reducer';

interface Props {
  locale: string;
  microbranding: Microbranding;
  cobranding: Cobranding;
  ffsId: string;
  children?: React.ReactNode;
  tenantCloudId?: string;
  userId?: string;
}

export default class AnalyticsClientProvider extends Component<Props> {
  render() {
    const {
      locale,
      microbranding,
      cobranding,
      children,
      tenantCloudId,
      userId,
      ffsId,
    } = this.props;
    return (
      <AnalyticsClientContext.Provider
        value={
          new AnalyticsClientImpl({
            microbranding,
            cobranding,
            locale,
            tenantCloudId,
            userId,
            ffsId,
          })
        }>
        {children}
      </AnalyticsClientContext.Provider>
    );
  }
}
