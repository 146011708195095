import {
  Action,
  WelcomeError,
  JWT,
  RedirectType,
  WelcomeErrorCode,
  WelcomeFieldError,
} from '../types';
import { detectClient } from '../utilities/client';

export interface State {
  formError: WelcomeErrorCode | null;
  error: WelcomeError | null;
  fieldErrors: WelcomeFieldError;
  isLoading: boolean;
  isMobile: boolean;
  redirectTo: string | null;
  recoveryToken?: JWT;
  redirectType: RedirectType;
  email: string;
  displayName?: string;
}

export const defaultState: State = {
  error: null,
  formError: null,
  fieldErrors: {},
  isLoading: false,
  isMobile: detectClient().atlassianMobileApp,
  redirectTo: null,
  redirectType: RedirectType.Migration,
  email: '',
};

const welcome = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'WELCOME_SIGNUP_RESET':
      return defaultState;
    case 'WELCOME_SIGNUP_REQUEST':
      return {
        ...state,
        error: null,
        formError: null,
        fieldErrors: {},
        isLoading: true,
      };
    case 'WELCOME_SIGNUP_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {},
        redirectTo: action.redirectTo,
      };
    case 'WELCOME_SIGNUP_REQUEST_FAILED':
      return {
        ...state,
        error: null,
        formError: action.formError,
        isLoading: false,
      };
    case 'WELCOME_SIGNUP_SHOW_FIELD_ERRORS':
      return {
        ...state,
        fieldErrors: action.errors,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default welcome;
