import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { applyMiddleware, createStore, CombinedState } from 'redux';
import thunk from 'redux-thunk';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';
import Spinner from '@atlaskit/spinner';
import { startMetalClient } from './utilities/metal-client';

import { getUserManagedStatus } from './actions/multi-factor-actions';
import ErrorBoundary from './components/ErrorBoundary';
import ServerErrorInterceptor from './containers/ServerErrorInterceptor';
import UnSupportedMobileBrowserInterceptor from './containers/UnSupportedMobileBrowserInterceptor';
import { initClient as initErrorReportingClient } from './utilities/analytics/error-reporting';

import reducers, { State as MainState } from './reducers';
import LocaleSwitcher from './components/LocaleSwitcher';
import { Action } from './types';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import {
  buildInitialState,
  createAnalyticsClient,
  startupAnalyticEvent,
  pushGlobals,
  preloadChunks,
  initializeLocationState,
  createGuardianMiddleware,
  initCastle,
} from './utilities/init-app-state';

import App from './containers/App';
import { createIdAuthMfaMiddleware } from './middleware/idauth-mfa';

// Render a spinner to remove the "failed to load scripts" error while loading the app
const container = document.getElementById('root');
ReactDOM.render(
  <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner size="large" />
  </div>,
  container
);

buildInitialState().then(initialState => {
  const { initialStoreState, featureFlags, loginHint } = initialState;

  initErrorReportingClient(initialStoreState.microbranding, featureFlags || {});

  const analyticsClient = createAnalyticsClient(initialState);

  startupAnalyticEvent(analyticsClient);

  pushGlobals(initialStoreState.appConfig, featureFlags);

  preloadChunks();

  const history = createBrowserHistory();

  // initialize location state prior to feeding redux
  initializeLocationState(loginHint, history);

  const guardian = createGuardianMiddleware();
  const idauthMfaMiddleware = createIdAuthMfaMiddleware();

  const mfaMiddleware = initialStoreState.multiFactor?.useIdAuthMfaMiddleware
    ? idauthMfaMiddleware
    : guardian;

  const middleware = [...(mfaMiddleware ? [mfaMiddleware] : []), thunk, routerMiddleware(history)];

  const store = createStore<CombinedState<MainState>, Action, {}, {}>(
    reducers(history),
    initialStoreState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  if (guardian) {
    // @ts-ignore
    store.dispatch(getUserManagedStatus());
  }

  viewportUnitsBuggyfill.init();

  if (initialStoreState.appConfig) {
    initCastle(initialStoreState.appConfig);
  }
  startMetalClient();

  ReactDOM.render(
    <ErrorBoundary analyticsClient={analyticsClient}>
      <Provider store={store}>
        <LocaleSwitcher>
          <ServerErrorInterceptor analyticsClient={analyticsClient}>
            <UnSupportedMobileBrowserInterceptor>
              <ConnectedRouter history={history}>
                <Route path="/" component={App} />
              </ConnectedRouter>
            </UnSupportedMobileBrowserInterceptor>
          </ServerErrorInterceptor>
        </LocaleSwitcher>
      </Provider>
    </ErrorBoundary>,
    container
  );
});
