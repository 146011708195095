import React, { ComponentClass } from 'react';
import { State as CobrandingState } from '../../reducers/cobranding-reducer';

import { lookupApplication } from '../applications';
import { getComponentDisplayName } from '../hoc-utils';

export enum Cobranding {
  NO_COBRANDING = 'no-cobranding',
  BITBUCKET = 'bitbucket',
  COMPASS = 'compass',
  CONFLUENCE = 'confluence',
  JIRA = 'jira',
  OPSGENIE = 'opsgenie',
  STATUSPAGE = 'statuspage',
  TRELLO = 'trello',
  WAC = 'wac',
  ATLAS = 'atlas',
}

export function getCurrentCobranding(cobranding: CobrandingState): Cobranding {
  const applicationData = lookupApplication(cobranding);
  if (applicationData) {
    switch (applicationData.application) {
      case 'atlas':
        return Cobranding.ATLAS;
      case 'bitbucket':
        return Cobranding.BITBUCKET;
      case 'compass':
        return Cobranding.COMPASS;
      case 'confluence':
        return Cobranding.CONFLUENCE;
      case 'jira':
        return Cobranding.JIRA;
      case 'opsgenie':
        return Cobranding.OPSGENIE;
      case 'statuspage':
        return Cobranding.STATUSPAGE;
      case 'trello':
        return Cobranding.TRELLO;
      case 'wac':
        return Cobranding.WAC;
    }
  }

  return Cobranding.NO_COBRANDING;
}

export interface CobrandingProps {
  cobranding: Cobranding;
  isMobileApp: boolean;
  isAnyMobile: boolean;
}

const CobrandingContext = React.createContext<CobrandingProps>({
  cobranding: Cobranding.NO_COBRANDING,
  isMobileApp: false,
  isAnyMobile: false,
});

export const CobrandingProvider = CobrandingContext.Provider;
export const CobrandingConsumer = CobrandingContext.Consumer;

export const withCobranding = <P extends {}>(
  WrappedComponent: React.ComponentType<P & CobrandingProps>
): React.ComponentClass<P> => {
  class WithCobranding extends React.Component<P> {
    render() {
      const originalProps = this.props;
      return (
        <CobrandingConsumer>
          {({ cobranding, isMobileApp, isAnyMobile }) => (
            <WrappedComponent
              {...originalProps}
              cobranding={cobranding}
              isMobileApp={isMobileApp}
              isAnyMobile={isAnyMobile}
            />
          )}
        </CobrandingConsumer>
      );
    }
  }

  (WithCobranding as ComponentClass).displayName = `WithCobranding(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return WithCobranding;
};
