import React from 'react';
import defaultImageLeft from '../../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../../images/cobranding/default/default_right.svg';
import { colors } from '@atlaskit/theme';
import AtlasLogo from './AtlasLogo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

export const AtlasBackground = backgroundWithSideImages(defaultImageLeft, defaultImageRight);

const AtlasCobranding: CobrandingComponents = {
  background: AtlasBackground,
  logo: {
    renderLogo: () => <AtlasLogo size={'large'} />,
  },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Trello, Jira, Confluence',
};

export default AtlasCobranding;
