import React, { Component } from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import FormTextField from './FormTextField';
import styled from '@emotion/styled';

import FormError from './FormError';
import FieldGroup from './FieldGroup';
import PasswordField from './PasswordField';
import ButtonContainer from './ButtonContainer';
import TallButton from './TallButton';

import { RecoveryTsvErrorCode, TsvRecoveryFieldError } from '../types';
import messages from './LoginForm.messages';
import { Field } from '@atlaskit/form';
import { colors } from '@atlaskit/theme';

const PasswordFieldGroup = styled(FieldGroup)`
  height: auto;
`;

interface Props {
  email?: string;
  error?: RecoveryTsvErrorCode | null;
  fieldErrors: TsvRecoveryFieldError;
  intl: InjectedIntl;
  isEmbedded?: boolean;
  isLoading?: boolean;
  showEmailAsInput: boolean;
  onFormClick?: () => void;
  onValidationError: (tsvFieldError: TsvRecoveryFieldError) => void;
  title?: React.ReactNode;
  submitButtonText: React.ReactNode;
  onSubmitClick?: () => void;
  onSubmit?: (email: string, password: string) => void;
}

interface State {
  email: string;
  password: string;
}

const Form = styled.form`
  margin: 0;
`;

const DisplayText = styled.div`
  font-weight: bold;
  color: ${colors.N300};
  word-wrap: break-word;
`;

class AccountVerificationForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const email = props.email || '';
    this.state = {
      email,
      password: '',
    };
  }

  emailInput: HTMLInputElement | null = null;
  passwordInput: PasswordField | null = null;

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { isLoading, onValidationError, intl } = this.props;
    const { email, password } = this.state;
    const { formatMessage } = intl;
    const validationErrors: TsvRecoveryFieldError = {};
    e.preventDefault();

    if (isLoading) {
      return;
    }

    if (password.trim() === '') {
      validationErrors.password = formatMessage(messages.passwordEmpty);
    }

    if (Object.keys(validationErrors).length) {
      onValidationError(validationErrors);
      return;
    }

    this.props.onSubmit && this.props.onSubmit(email, password);
  };

  handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onValidationError({}); // clear validation errors
    this.setState({ password: e.currentTarget.value });
  };

  errorContent() {
    const error: RecoveryTsvErrorCode | null | undefined = this.props.error;
    switch (error) {
      case 'unknown':
        return (
          <FormattedMessage
            id="recovery.error.unknown"
            defaultMessage="Something went wrong while attempting to login. Please try again."
          />
        );
      default:
        return error;
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevFieldErrors = prevProps.fieldErrors;
    const fieldErrors = this.props.fieldErrors;

    const passwordError = fieldErrors.password && fieldErrors.password !== prevFieldErrors.password;

    if (passwordError) {
      this.passwordInput && this.passwordInput.focus();
    }
  }

  render() {
    const {
      intl: { formatMessage },
      title,
      email,
      showEmailAsInput,
    } = this.props;
    return (
      <div>
        <FormError className="form-error" isHidden={!this.props.error}>
          {this.errorContent()}
        </FormError>
        {title && <div>{title}</div>}
        <Form
          onSubmit={this.handleSubmit}
          id="form-accountverification-up"
          onClick={this.props.onFormClick}>
          {showEmailAsInput ? (
            <FieldGroup>
              <FormTextField
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                placeholder={formatMessage(messages.emailEmpty)}
                label={formatMessage(messages.emailEmpty)}
                autoFocus={!this.props.email}
                isLabelHidden={true}
                value={this.props.email}
                ref={input => (this.emailInput = input)}
                isDisabled={true}
              />
            </FieldGroup>
          ) : (
            <Field name="email" label="New Email address">
              {() => <DisplayText>{email}</DisplayText>}
            </Field>
          )}
          <PasswordFieldGroup>
            <PasswordField
              name="password"
              autoComplete="new-password"
              id="password"
              placeholder={formatMessage(messages.passwordEmpty)}
              label={formatMessage(messages.passwordEmpty)}
              autoFocus={!!this.props.email}
              isLabelHidden={true}
              isInvalid={!!this.props.fieldErrors.password}
              invalidMessage={this.props.fieldErrors.password}
              onChange={this.handlePasswordChange}
              value={this.state.password}
              ref={input => (this.passwordInput = input)}
            />
          </PasswordFieldGroup>
          <ButtonContainer>
            <TallButton
              appearance="primary"
              type="submit"
              isFullWidth
              id="tsvrecovery-submit"
              onClick={() => this.props.onSubmitClick && this.props.onSubmitClick()}
              isLoading={this.props.isLoading}>
              {this.props.submitButtonText}
            </TallButton>
          </ButtonContainer>
        </Form>
      </div>
    );
  }
}
export default injectIntl(AccountVerificationForm);
