import jiraImageLeft from '../../../images/cobranding/jira/jira-left.svg';
import jiraImageRight from '../../../images/cobranding/jira/jira-right.svg';
import { colors } from '@atlaskit/theme';
import { JiraLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  blueLogo,
  CobrandingComponents,
  CobrandingLogoStyles,
} from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

const logoStyles: CobrandingLogoStyles = { ...blueLogo, textColor: colors.N800 };
export const JiraBackground = backgroundWithSideImages(jiraImageLeft, jiraImageRight);

const JiraCobranding: CobrandingComponents = {
  background: JiraBackground,
  logo: { component: JiraLogo, desktopStyles: logoStyles, mobileStyles: logoStyles },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Jira, Confluence, Trello',
};

export default JiraCobranding;
