import { Action, ChangePasswordFieldErrors, ChangePasswordError } from '../types';

export interface State {
  email: string;
  fieldErrors: ChangePasswordFieldErrors;
  isLoading: boolean;
  error?: ChangePasswordError;
}

export const defaultState: State = {
  email: '',
  fieldErrors: {},
  isLoading: false,
};

const changePassword = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'CHANGE_PASSWORD_REQUEST_INITIATE':
      return {
        ...state,
        fieldErrors: {},
        isLoading: true,
        error: undefined,
      };
    case 'CHANGE_PASSWORD_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case 'CHANGE_PASSWORD_REQUEST_FAILED':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'CHANGE_PASSWORD_REQUEST_FIELD_ERRORS':
      return {
        ...state,
        isLoading: false,
        fieldErrors: action.fieldErrors,
      };
    default:
      return state;
  }
};

export default changePassword;
