import bitbucketImageLeft from '../../../images/cobranding/bitbucket/bitbucket_left.svg';
import bitbucketImageRight from '../../../images/cobranding/bitbucket/bitbucket_right.svg';
import { colors } from '@atlaskit/theme';
import { BitbucketLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  blueLogo,
  CobrandingComponents,
  CobrandingLogoStyles,
} from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

const logoStyles: CobrandingLogoStyles = { ...blueLogo, textColor: colors.N800 };

export const BitbucketBackground = backgroundWithSideImages(
  bitbucketImageLeft,
  bitbucketImageRight
);

const BitbucketCobranding: CobrandingComponents = {
  background: BitbucketBackground,
  logo: { component: BitbucketLogo, desktopStyles: logoStyles, mobileStyles: logoStyles },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Bitbucket, Jira, Confluence',
};

export default BitbucketCobranding;
