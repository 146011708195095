import React, { useMemo } from 'react';
import { wrapComponent } from '../utilities/wrap-component';
import ButtonContainer from '../components/ButtonContainer';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import errorImage from '../images/Error.svg';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import styled from '@emotion/styled';
import { InjectedAnalyticsProps, VerificationType } from '../types';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

interface OwnProps {
  recoverUrl: string;
  verificationType: VerificationType;
}

type InjectedProps = InjectedAnalyticsProps & InjectedIntlProps;

type Props = OwnProps & InjectedProps;

export const VerifyOrReverifyEmailRateLimitExceededErrorPage: React.FC<Props> = ({
  analyticsClient,
  recoverUrl,
  verificationType,
}) => {
  const pageId = 'reverifyEmailRateLimitExceededErrorPage';
  const displayRecoverUrl = useMemo(() => new URL(recoverUrl).hostname, [recoverUrl]);

  usePageViewedEvent(pageId, { flow: verificationType });

  const recover = () => {
    analyticsClient.buttonClickedEvent(pageId, 'backToStartButton', { flow: verificationType });

    window.location.assign(recoverUrl);
  };

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <MessageContainer>
            <FormattedMessage
              id="reverify.email.rate-limit-exceeded.error.title"
              defaultMessage="We couldn't verify your email"
              tagName="h5"
            />
          </MessageContainer>
          <img src={errorImage} width="99" alt="" margin-top="24px" />
        </div>
      }>
      <FormattedMessage
        id="reverify.email.rate-limit-exceeded.error.main-action"
        defaultMessage="There have been too many attempts to reverify your account in the past hour. Please try again later."
        description="Telling the user that they have exceeded welcome email limit"
        tagName="p"
      />
      <ButtonContainer>
        <TallButton appearance="primary" id="go-back-button" isFullWidth onClick={recover}>
          <FormattedMessage
            id="reverify.email.rate-limit-exceeded.error.button-text"
            defaultMessage="Go back to {startUrl}"
            values={{ startUrl: displayRecoverUrl }}
          />
        </TallButton>
      </ButtonContainer>
    </WhiteboxPage>
  );
};

export default wrapComponent<{}, {}, OwnProps, InjectedProps>(
  {
    withAnalyticsClient: true,
  },
  VerifyOrReverifyEmailRateLimitExceededErrorPage
);
