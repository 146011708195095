import React, { Component } from 'react';
import WhiteboxPage from '../components/WhiteboxPage';
import queryString from 'query-string';
import { applications, ApplicationParams } from '../utilities/applications';
import { InjectedIntlProps, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { wrapComponent } from '../utilities/wrap-component';
import errorImage from '../images/Error.svg';
import { State as MainState } from '../reducers/index';
import {
  InjectedAnalyticsProps,
  InjectedRouterProps,
  URLParameters,
  JWTValidationError,
} from '../types';

import messages from './SocialLoginRecoveryPage.messages';
import TallButton from '../components/TallButton';
import ButtonContainer from '../components/ButtonContainer';
import { isMobileOidc } from '../utilities/oidc/oidc';

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedAnalyticsProps & InjectedRouterProps;

export type SocialLoginRecoveryError =
  | JWTValidationError
  | 'invalid_source_flow'
  | 'no_recovery_method'
  | 'unknown_error';

interface PropsFromState {
  expectedEmail?: string;
  foundEmail?: string;
  recoveryUrl?: URL;
  error?: SocialLoginRecoveryError;
  product?: ApplicationParams;
  isMobileApp: Boolean;
}

type Props = PropsFromState & OwnProps & InjectedProps;

export class SocialLoginRecoveryPage extends Component<Props> {
  static pageId = 'atlassianAccountSocialLoginRecoveryScreen';

  buildHeader = () => {
    return (
      <div>
        <img src={errorImage} width="99" alt="" />
      </div>
    );
  };

  componentDidMount() {
    const pageViewedAttributes = this.props.error && { errorCode: this.props.error };

    this.props.analyticsClient.pageViewedEvent(
      SocialLoginRecoveryPage.pageId,
      pageViewedAttributes
    );
  }

  recover = (recoveryUrl: URL) => {
    this.props.analyticsClient.buttonClickedEvent(SocialLoginRecoveryPage.pageId, 'tryAgainButton');
    window.location.assign(recoveryUrl.toString());
  };

  whiteboxFooter = () => {
    const { isMobileApp } = this.props;
    return isMobileApp ? null : (
      <a
        id="social-login-recovery-documentation-link"
        target="_blank"
        rel="noreferrer noopener"
        onClick={() =>
          this.props.analyticsClient.linkClickedEvent(
            SocialLoginRecoveryPage.pageId,
            'documentationLink'
          )
        }
        href="https://support.atlassian.com/atlassian-account/docs/login-issues-related-to-an-incorrect-email-address-error/">
        <FormattedMessage {...messages.documentationLinkMessage} />
      </a>
    );
  };

  renderRecoveryPage = (recoveryUrl: URL, expectedEmail: string, foundEmail?: string) => {
    const {
      intl: { formatMessage, formatHTMLMessage },
      product,
    } = this.props;

    const destinationProduct = product ? product.name : formatMessage(messages.productFallback);

    return (
      <WhiteboxPage
        pageId={SocialLoginRecoveryPage.pageId}
        header={this.buildHeader()}
        whiteboxFooter={this.whiteboxFooter()}>
        <p>
          {foundEmail ? (
            <FormattedHTMLMessage
              {...messages.bodyMessageUnableToLoginWithFoundEmail}
              values={{
                product: destinationProduct,
                foundEmail: foundEmail,
              }}
            />
          ) : (
            <FormattedHTMLMessage
              {...messages.bodyMessageUnableToLoginWithThisEmailAddress}
              values={{
                product: destinationProduct,
              }}
            />
          )}
        </p>

        <p>
          <FormattedHTMLMessage
            {...messages.bodyMessageUseExpectedEmail}
            values={{
              expectedEmail: expectedEmail,
            }}
          />
        </p>

        <ButtonContainer>
          <TallButton
            appearance="primary"
            id="recovery-button"
            isFullWidth
            onClick={() => this.recover(recoveryUrl)}>
            {formatHTMLMessage(messages.buttonText)}
          </TallButton>
        </ButtonContainer>
      </WhiteboxPage>
    );
  };

  renderRecoveryError = (error: SocialLoginRecoveryError) => {
    const errorMessage = () => {
      switch (error) {
        case 'expired_token':
          return messages.expiredTokenErrorMessage;
        case 'future_issued_token':
        case 'invalid_token':
          return messages.tokenErrorMessage;
        default:
          return messages.unknownErrorMessage;
      }
    };

    return (
      <WhiteboxPage pageId={SocialLoginRecoveryPage.pageId} header={this.buildHeader()}>
        <p>
          <FormattedHTMLMessage {...errorMessage()} />
        </p>
      </WhiteboxPage>
    );
  };

  render() {
    const { expectedEmail, foundEmail, recoveryUrl, error } = this.props;

    if (expectedEmail && recoveryUrl) {
      return this.renderRecoveryPage(recoveryUrl, expectedEmail, foundEmail);
    } else if (error) {
      return this.renderRecoveryError(error);
    } else {
      return this.renderRecoveryError('unknown_error');
    }
  }
}

const mapStateToProps = (state: MainState): PropsFromState => {
  const queryParams = queryString.parse(state.router.location.search) as URLParameters;
  return {
    ...state.socialLoginRecovery,
    product: queryParams.application && applications[queryParams.application],
    isMobileApp: isMobileOidc(state.microbranding.oidcContext) || state.microbranding.isEmbedded,
  };
};

export default wrapComponent<PropsFromState, {}, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  SocialLoginRecoveryPage
);
