import { defineMessages } from 'react-intl';

export default defineMessages({
  genericErrorTitle: {
    id: 'error.title',
    defaultMessage: 'Something went wrong.',
  },
  internalServerErrorTitle: {
    id: 'error.internalservererror.title',
    defaultMessage: 'Something went wrong while executing your request.',
  },
  internalServerErrorDescription: {
    id: 'error.internalservererror.desc',
    defaultMessage: "We're working on it, please try again shortly.",
  },
  notFoundTitle: {
    id: 'error.notfound.title',
    defaultMessage: 'Page Not Found',
  },
  badRequestTitle: {
    id: 'error.badrequest.title',
    defaultMessage: "Oops, you've made a malformed request.",
  },
  badRequestDescription: {
    id: 'error.badrequest.desc',
    defaultMessage:
      'Often, clearing your browsers cache and restarting your browser will solve this problem.<br/>If that doesn\'t work, please <a href="https://support.atlassian.com/" target="_blank">contact support</a>.',
  },
});
