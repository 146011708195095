import { createSelector, Selector } from 'reselect';
import { ParameterizedLocation, State } from '../reducers/index';

export enum LoginHintType {
  EmailAddress = 'email-address',
  NotEmailAddress = 'not-email-address',
  MaybeUsername = 'maybe-username',
}

export interface LoginHint {
  type: LoginHintType;
  value: string;
}

export const getUsername: (LoginHint) => string | null = loginHint => {
  switch (loginHint.type) {
    case LoginHintType.EmailAddress:
    case LoginHintType.MaybeUsername:
      return loginHint.value;
    default:
      return null;
  }
};

export const combiner = (location: ParameterizedLocation): LoginHint | null => {
  return location.state && location.state.loginHint ? location.state.loginHint : null;
};

const selector: Selector<State, LoginHint | null> = createSelector(
  (state: State): ParameterizedLocation => state.router.location,
  combiner
);

export default selector;
