import React from 'react';
import defaultImageLeft from '../../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../../images/cobranding/default/default_right.svg';
import { colors } from '@atlaskit/theme';
import { CompassLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

export const CompassBackground = backgroundWithSideImages(defaultImageLeft, defaultImageRight);

const CompassCobranding: CobrandingComponents = {
  background: CompassBackground,
  logo: {
    renderLogo: () => (
      <CompassLogo
        textColor={colors.N700}
        iconColor={colors.B200}
        iconGradientStart={colors.B400}
        iconGradientStop={colors.B200}
        size="large"
      />
    ),
  },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Trello, Jira, Confluence',
};

export default CompassCobranding;
