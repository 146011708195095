import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import emailSentImage from '../images/check-your-email-open-letter.svg';
import { gridSize, typography } from '@atlaskit/theme';

const imgHeight = 88;

const EmailSentImageContainer = styled.div`
  background: url(${emailSentImage}) no-repeat center bottom;
  height: ${imgHeight}px;
  margin-bottom: ${gridSize() * 3}px;
`;

const EmailSentDescriptionContainer = styled.div`
  text-align: left;
`;

const EmailWarningContainer = styled.div`
  font-weight: bold;
  text-align: left;
  margin-top: ${gridSize() * 3}px;
`;

const Email = styled.p`
  ${typography.h500()}
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  & {
    margin-top: ${gridSize() * 0.5}px;
  }
`;

export default function EmailSent(props: {
  children?: React.ReactNode;
  email?: string;
  message?: React.ReactNode;
  warningMessage?: React.ReactNode;
  onRender?: () => void;
}) {
  const { children, email, message, warningMessage, onRender } = props;

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  return (
    <div id="email-sent-page">
      <EmailSentImageContainer />
      <EmailSentDescriptionContainer>{message}</EmailSentDescriptionContainer>
      {email && <Email>{email}</Email>}
      {warningMessage && <EmailWarningContainer>{warningMessage}</EmailWarningContainer>}
      {children}
    </div>
  );
}
