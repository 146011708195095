import React, { Component, Fragment } from 'react';
import UnauthenticatedPage, { MainContent } from './UnauthenticatedPage';
import Spinner from '@atlaskit/spinner';
import { gridSize } from '@atlaskit/theme';
import { wrapComponent } from '../utilities/wrap-component';
import { captureErrorMessage } from '../utilities/analytics/error-reporting';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { InjectedRouterProps } from '../types';
import styled from '@emotion/styled';
import { screenSize } from '../utilities/style-utils';

interface OwnProps {
  error: string;
  pastDelay?: boolean;
  timedOut?: boolean;
  isFatalError?: boolean;
}

type InjectedProps = InjectedIntlProps & InjectedRouterProps;

type Props = OwnProps & InjectedProps;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${gridSize() * 3.5}px !important;
`;

class UnauthenticatedLoadingPage extends Component<Props> {
  renderContent() {
    const { error, pastDelay, timedOut, history, isFatalError } = this.props;
    if (error || timedOut) {
      captureErrorMessage(error, history.location);
      return (
        <Fragment>
          <Heading>
            <FormattedMessage
              id="loading.component.error"
              defaultMessage="Oops, something went wrong."
            />
          </Heading>
          {(timedOut || !isFatalError) && (
            <Heading>
              <FormattedMessage
                id="loading.componenet.error.hint"
                defaultMessage="Refresh the page to try again."
              />
            </Heading>
          )}
        </Fragment>
      );
    } else if (pastDelay) {
      const spinnerSize = screenSize() === 'mobile' ? 'large' : 'xlarge';
      return (
        <LoaderContainer>
          <Spinner size={spinnerSize} />
        </LoaderContainer>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <UnauthenticatedPage>
        <MainContent noShadow noPadding>
          {this.renderContent()}
        </MainContent>
      </UnauthenticatedPage>
    );
  }
}

export default wrapComponent<{}, {}, OwnProps, InjectedProps>(
  {
    withRouter: true,
    withIntl: true,
  },
  UnauthenticatedLoadingPage
);
