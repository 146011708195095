import { ThunkAction, ThunkDispatch } from '../types';
import queryString, { ParsedQuery } from 'query-string';
import { State } from '../reducers';
import { Hash, LocationDescriptorObject, LocationKey, Pathname } from 'history';
import { LoginHint, LoginHintType } from '../selectors/login-hint';
import {
  CallHistoryMethodAction as RouterCallHistoryMethodAction,
  push,
} from 'connected-react-router';

export type LocationState = { loginHint?: LoginHint };
interface ParsedLocation {
  pathname?: Pathname;
  search?: ParsedQuery;
  state?: LocationState;
  hash?: Hash;
  key?: LocationKey;
}
type ModifyParsedLocation = (location: ParsedLocation) => ParsedLocation;
type LocationDescriptor = LocationDescriptorObject<LocationState>;
export type CallHistoryMethodAction = RouterCallHistoryMethodAction<
  [LocationDescriptorObject<LocationState>]
>;

const modifyParsedLocation = (
  modifyParsedLocation: ModifyParsedLocation,
  callHistoryAction: (location: LocationDescriptor) => CallHistoryMethodAction
): ThunkAction<CallHistoryMethodAction> => (dispatch: ThunkDispatch, getState: () => State) => {
  const {
    router: { location },
    appConfig: { contextPath },
  } = getState();
  const modifiedParsedLocation = modifyParsedLocation({
    pathname: location.pathname,
    search: queryString.parse(location.search),
    state: location.state,
    hash: location.hash,
  });
  return dispatch(
    callHistoryAction({
      pathname:
        location.pathname === modifiedParsedLocation.pathname
          ? location.pathname
          : `${contextPath}${modifiedParsedLocation.pathname}`,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        ...modifiedParsedLocation.search,
      }),
      state: {
        ...location.state,
        ...modifiedParsedLocation.state,
      },
      hash: modifiedParsedLocation.hash || location.hash,
    })
  );
};

const composeModifyParsedLocation = (
  ...modifyParsedLocations: ModifyParsedLocation[]
): ModifyParsedLocation =>
  modifyParsedLocations.reduce<ModifyParsedLocation>(
    (composedModifyParsedLocation, modifyParsedLocation) => location => {
      const mergedLocation = composedModifyParsedLocation(location);
      const modifiedLocation = modifyParsedLocation(location);
      return {
        pathname: modifiedLocation.pathname || mergedLocation.pathname,
        search: {
          ...mergedLocation.search,
          ...modifiedLocation.search,
        },
        state: {
          ...mergedLocation.state,
          ...modifiedLocation.state,
        },
        hash: modifiedLocation.hash || mergedLocation.hash,
      };
    },
    location => location
  );

const removeInvertedLoginHint: ModifyParsedLocation = location =>
  location.state &&
  location.state.loginHint &&
  location.state.loginHint.type === LoginHintType.NotEmailAddress
    ? {
        search: {
          login_hint: undefined,
        },
        state: {
          loginHint: undefined,
        },
      }
    : {};

const setLoginPrompt: ModifyParsedLocation = () => ({
  search: {
    prompt: 'login',
  },
  pathname: '/login',
});

export const navigateToLoginPrompt: ThunkAction<CallHistoryMethodAction> = modifyParsedLocation(
  composeModifyParsedLocation(removeInvertedLoginHint, setLoginPrompt),
  push
);
export const navigateToRemoveAccount: ThunkAction<CallHistoryMethodAction> = modifyParsedLocation(
  () => ({ pathname: '/login/remove-account' }),
  push
);
export const navigateToSelectAccount: ThunkAction<CallHistoryMethodAction> = modifyParsedLocation(
  () => ({ pathname: '/login/select-account' }),
  push
);

export const setMfaTransactionToken: (
  string
) => ThunkAction<CallHistoryMethodAction> = transactionToken =>
  modifyParsedLocation(
    location => ({ pathname: location.pathname, search: { transactionToken } }),
    push
  );
