import {
  guardianRecoverError,
  guardianRecoverResponse,
  guardianVerifyError,
} from '../actions/multi-factor-actions';

import { Dispatch, ThunkAction, URLParameters } from '../types';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { captureException } from '../utilities/analytics/error-reporting';
import queryString from 'query-string';
import { addFlag } from '../actions/flag-actions';
import messages from '../containers/MultiFactorPage.messages';
import { setMfaTransactionToken } from '../actions/history-actions';

/* *********************
 * id-authentication MFA
 * *********************/

export function createIdAuthMfaMiddleware() {
  return store => next => action => {
    switch (action.type) {
      case 'GUARDIAN_ENROLLMENT_DETAILS_REQUEST':
        // This action isn't needed in IdAuth, so just pass on the action
        return next(action);
      case 'GUARDIAN_VERIFY':
        next(action);
        // The action that calls fetch (/verify) and redirects
        return next(verifyOtpCodeAction(action));
      case 'GUARDIAN_RESEND_SMS':
        next(action);
        // The action that calls fetch to resend SMS
        return next(resendSmsAction(action));
      case 'GUARDIAN_RECOVER':
        next(action);
        // The action that verifies recovery code
        return next(verifyRecoveryCodeAction(action));
      case 'GUARDIAN_CONFIRMED_RECOVER':
        next(action);
        // The action that calls /mfa/authorize and redirects
        return next(confirmedRecoveryCodeAction(action));
      default:
        return next(action);
    }
  };
}

type ResendSmsParams = {
  params: string;
  analyticsClient: AnalyticsClient;
};

export const resendSmsAction = ({
  params,
  analyticsClient,
}: ResendSmsParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/resend-sms${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
    });

    const queryParams: URLParameters = queryString.parse(params);
    switch (response.status) {
      case 200:
        const { transactionToken } = await response.json();
        dispatch(setMfaTransactionToken(transactionToken));
        break;
      case 400:
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svChallengeScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 429:
        analyticsClient.errorShownEvent('2svChallengeScreen', '2svTooManySms', {
          mfaBackend: 'id-authentication',
        });
        handleError(dispatch);
        break;
      default:
        const error = await response.text();
        throw new Error(error || response.statusText);
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svChallengeScreen', '2svUnknownError', {
      mfaBackend: 'id-authentication',
    });
    handleError(dispatch);
    captureException(error);
  }
};

type MfaVerificationParams = {
  params: string;
  otpCode: string;
  analyticsClient: AnalyticsClient;
};

export const verifyOtpCodeAction = ({
  params,
  otpCode,
  analyticsClient,
}: MfaVerificationParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/verify${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
      body: JSON.stringify({ otpCode }),
    });

    switch (response.status) {
      case 200:
        const { transactionToken, redirectUri } = await response.json();
        if (redirectUri) {
          // fast5
          window.location.assign(redirectUri);
        } else {
          const queryParams: URLParameters = queryString.parse(params);
          queryParams.transactionToken = transactionToken;
          const authorizeResponse = await fetch(
            `/rest/mfa/authorize?${queryString.stringify(queryParams)}`
          );

          if (!authorizeResponse.ok) {
            const error = await authorizeResponse.text();
            throw new Error(error || authorizeResponse.statusText);
          }

          const { redirectUri } = await authorizeResponse.json();
          window.location.assign(redirectUri);
        }
        break;
      case 400:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svChallengeScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 403:
      case 429:
        analyticsClient.errorShownEvent('2svChallengeScreen', '2svIncorrectCode', {
          mfaBackend: 'id-authentication',
        });
        dispatch(guardianVerifyError({ errorCode: 'invalid_otp', message: 'invalid otp code' }));
        break;
      default:
        const error = await response.text();
        throw new Error(error || response.statusText);
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svChallengeScreen', '2svUnknownError', {
      mfaBackend: 'id-authentication',
    });
    handleError(dispatch);
    captureException(error);
  }
};

type MfaRecoveryCodeVerificationParams = {
  params: string;
  recoveryCode: string;
  analyticsClient: AnalyticsClient;
};

export const verifyRecoveryCodeAction = ({
  params,
  recoveryCode,
  analyticsClient,
}: MfaRecoveryCodeVerificationParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/verify/recoverycode${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
      body: JSON.stringify({ recoveryCode }),
    });

    switch (response.status) {
      case 200:
        const { recoveryCode, transactionToken } = await response.json();
        dispatch(setMfaTransactionToken(transactionToken));
        dispatch(guardianRecoverResponse({ signature: '', recoveryCode }));
        break;
      case 400:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svRecoveryKeyScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 403:
      case 429:
        analyticsClient.errorShownEvent('2svRecoveryKeyScreen', '2svIncorrectCode', {
          mfaBackend: 'id-authentication',
        });
        dispatch(
          guardianRecoverError({
            errorCode: 'invalid_recovery_code',
            message: 'invalid recovery code',
          })
        );
        break;
      default:
        const error = await response.text();
        throw new Error(
          `Unexpected response from POST /rest/mfa/verify/recoverycode: ${
            error || response.statusText
          }`
        );
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svRecoveryKeyScreen', '2svUnknownError', {
      mfaBackend: 'id-authentication',
    });
    handleError(dispatch);
    captureException(error);
  }
};

type ConfirmedRecoveryCodeParams = {
  params: string;
  analyticsClient: AnalyticsClient;
};

export const confirmedRecoveryCodeAction = ({
  params,
  analyticsClient,
}: ConfirmedRecoveryCodeParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/authorize${params}`);

    switch (response.status) {
      case 200:
        const { redirectUri } = await response.json();
        window.location.assign(redirectUri);
        break;
      case 400:
      case 403:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svSavedNewRecoveryKey',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      default:
        const error = await response.text();
        throw new Error(
          `Unexpected response from GET /rest/mfa/authorize: ${error || response.statusText}`
        );
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svSavedNewRecoveryKey', '2svUnknownError', {
      mfaBackend: 'id-authentication',
    });
    handleError(dispatch);
    captureException(error);
  }
};

function handleError(dispatch: Dispatch) {
  return dispatch(addFlag('error', messages.mfVerifyErrorTitle, messages.mfVerifyErrorDesc));
}

function redirectToLoginErrorOn400IncorrectTokenResponse({
  analyticsClient,
  page,
  subjectId,
  currentQueryParams,
}: {
  analyticsClient: AnalyticsClient;
  page: string;
  subjectId: string;
  currentQueryParams: URLParameters;
}) {
  analyticsClient.errorShownEvent(page, subjectId, {
    mfaBackend: 'id-authentication',
  });
  delete currentQueryParams.transactionToken;
  delete currentQueryParams.errorCode;
  const currentQueryString = queryString.stringify(currentQueryParams);
  const extraParamsString = currentQueryString === '' ? '' : `&${currentQueryString}`;

  window.location.assign(
    `/login?prompt=true&errorCode=login.form.token.expired${extraParamsString}`
  );
}
