import { AtlassianLogo } from '@atlaskit/logo';
import { colors } from '@atlaskit/theme';
import { backgroundWithSideImages, blueLogo, CobrandingComponents } from './shared-cobranding';
import { darkFooter } from './FooterComponent';
import defaultImageLeft from '../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../images/cobranding/default/default_right.svg';

export const DefaultCobrandingBackground = backgroundWithSideImages(
  defaultImageLeft,
  defaultImageRight
);

const DefaultCobranding: CobrandingComponents = {
  background: DefaultCobrandingBackground,
  logo: { component: AtlassianLogo, desktopStyles: blueLogo },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: {
    ...darkFooter,
    mobileLogo: darkFooter,
  },
  footerProducts: 'Jira, Confluence, Trello',
};

export default DefaultCobranding;
