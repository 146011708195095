import React from 'react';
import styled from '@emotion/styled';

const SeparatorContainer = styled.p`
  margin: 0px 8px;
`;

const Bull = () => <SeparatorContainer>&bull;</SeparatorContainer>;

export default Bull;
