import { colors } from '@atlaskit/theme';
import { AtlassianLogo } from '@atlaskit/logo';
import styled from '@emotion/styled';
import { DefaultBackground, blueLogo, CobrandingComponents } from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

export const WacBackground = styled(DefaultBackground)`
  background-color: #fff;
`;

const WacCobranding: CobrandingComponents = {
  background: WacBackground,
  logo: { component: AtlassianLogo, desktopStyles: blueLogo, mobileStyles: blueLogo },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Jira, Confluence, Trello',
};

export default WacCobranding;
