import { Action, MultifactorMethod, MultiFactorOTPError, MultiFactorRecoveryError } from '../types';

export interface State {
  email: string;
  fieldErrors: { otpCode?: MultiFactorOTPError; recoveryCode?: MultiFactorRecoveryError };
  isLoading: boolean;
  isManaged: boolean;
  isRecoveryEmailSent: boolean;
  recoveryEmailError: boolean;
  method?: MultifactorMethod;
  phoneNumber?: string;
  signature?: string;
  recoveryCode?: string;
  useIdAuthMfaMiddleware?: boolean;
}

export const defaultState: State = {
  email: window.userData ? window.userData.email : '',
  fieldErrors: {},
  isLoading: false,
  isManaged: true,
  isRecoveryEmailSent: false,
  recoveryEmailError: false,
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'GUARDIAN_ENROLLMENT_DETAILS_RESPONSE':
      return {
        ...state,
        method: action.method,
        phoneNumber: action.phoneNumber,
      };
    case 'GUARDIAN_VERIFY':
      return {
        ...state,
        fieldErrors: {},
        isLoading: true,
      };
    case 'GUARDIAN_RECOVER':
      return {
        ...state,
        fieldErrors: {},
        isLoading: true,
      };
    case 'GUARDIAN_VERIFY_RESPONSE':
      return {
        ...state,
        signature: action.signature,
      };
    case 'GUARDIAN_RECOVER_RESPONSE':
      return {
        ...state,
        signature: action.signature,
        recoveryCode: action.recoveryCode,
      };
    case 'GUARDIAN_VERIFY_ERROR':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {
          otpCode: { errorCode: action.errorCode, message: action.message },
        },
      };
    case 'GUARDIAN_RECOVER_ERROR':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {
          recoveryCode: { errorCode: action.errorCode, message: action.message },
        },
      };
    case 'RECOVERY_EMAIL_SENT':
      return {
        ...state,
        isRecoveryEmailSent: true,
      };
    case 'RECOVERY_EMAIL_START':
      return {
        ...state,
        recoveryEmailError: false,
      };
    case 'RECOVERY_EMAIL_ERROR':
      return {
        ...state,
        recoveryEmailError: true,
      };
    case 'USER_MANAGED_STATUS':
      return {
        ...state,
        isManaged: action.isManaged,
      };

    default:
      return state;
  }
};
