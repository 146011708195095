import React from 'react';
import trelloImageLeft from '../../../images/cobranding/trello/trello-left.svg';
import trelloImageRight from '../../../images/cobranding/trello/trello-right.svg';
import { colors } from '@atlaskit/theme';
import TrelloLogo from './TrelloLogo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

export const TrelloBackground = backgroundWithSideImages(trelloImageLeft, trelloImageRight);

const TrelloCobranding: CobrandingComponents = {
  background: TrelloBackground,
  logo: {
    renderLogo: () => <TrelloLogo size={'large'} />,
  },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Trello, Jira, Confluence',
};

export default TrelloCobranding;
