import { sizes } from '@atlaskit/logo/constants';
import logoSrc from '../../../images/cobranding/atlas/atlasbetalogo.svg';
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { LogoSize } from '../shared-cobranding';

const LogoContainer = styled.div<{ size: LogoSize }>`
  display: inline-block;
  height: ${p => (p.size ? sizes[p.size] : sizes['large'])}px;
  position: relative;
  user-select: none;

  > img {
    width: 100%;
    height: 48px;
  }
`;

export interface AtlasLogoProps {
  size: LogoSize;
}

export default class AtlasLogo extends Component<AtlasLogoProps> {
  render() {
    return (
      <LogoContainer size={this.props.size}>
        <img src={logoSrc} alt={'Atlas Logo'} />
      </LogoContainer>
    );
  }
}
