import { Action, JWTValidationError } from '../types';

export interface State {
  expectedEmail?: string;
  foundEmail?: string;
  recoveryUrl?: URL;
  error?: JWTValidationError | 'unknown_error';
}

export const defaultState: State = {
  error: 'unknown_error',
};

const socialLoginRecovery = (state: State = defaultState, action: Action): State => state;

export default socialLoginRecovery;
