/* eslint-disable max-params */
import queryString from 'query-string';
import { Base64 } from 'js-base64';
import { CsrfToken, URLParameters, Auth0Config, UserFlow, JWT } from '../types';
import { isBooleanFeatureFlagSet, BooleanFeatureFlags } from './feature-flags';
import H from 'history';
import { getAnonymousId } from './analytics/segment-io';
import { FeatureExposedAttributes } from './analytics/analytics-web-client';

export enum SocialLoginFlowType {
  Auth0 = 'auth0',
  Google = 'google',
  InHouse = 'in-house',
}

export enum LoginType {
  Apple = 'appleLogin',
  Slack = 'slackLogin',
  Microsoft = 'microsoftLogin',
  SSO = 'ssoLogin',
  UnknownAuth0 = 'unknownAuth0Login',
  GoogleButton = 'googleButton',
  GoogleOneTap = 'googleYolo',
}

export type GoogleLoginType = LoginType.GoogleButton | LoginType.GoogleOneTap;

export type InHouseSocialLoginType = LoginType.Apple | LoginType.Slack | LoginType.Microsoft;

export type Auth0LoginType =
  | LoginType.Apple
  | LoginType.Slack
  | LoginType.Microsoft
  | LoginType.SSO
  | LoginType.UnknownAuth0;

export const isAuth0LoginType = (type: string): type is Auth0LoginType => {
  switch (type as LoginType) {
    case LoginType.Apple:
    case LoginType.Slack:
    case LoginType.Microsoft:
    case LoginType.SSO:
      return true;
    default:
      return false;
  }
};

/**
 * Used when we want to ensure that the user picks a specific email address from the social login
 * provider. Using the OIDC standard login_hint parameter still gives the user the option to pick
 * other accounts either on purpose or accidentally.
 */
export interface SocialLoginRecoveryOptions {
  expectedEmail: string;
  recoveryToken: JWT;
}

// common

/**
 * Builds up an OIDC `state` parameter. The `state` parameter is supposed to be used _as_ a CSRF token, and so is guaranteed to
 * be provided as a parameter once the OIDC provider redirects the user's browser back to Atlassian account. This means we can
 * smuggle information through the OIDC flow by packing and unpacking this parameter at the appropriate time.
 */
export const buildState = ({
  location,
  csrfToken,
  source,
  loginType,
  anonymousId,
  userFlow,
  recoveryOptions,
}: {
  location: Location;
  csrfToken: CsrfToken;
  source: string;
  loginType: LoginType;
  anonymousId?: string;
  userFlow?: UserFlow;
  recoveryOptions?: SocialLoginRecoveryOptions;
}) =>
  Base64.encode(
    JSON.stringify({
      csrfToken,
      query: location.search || undefined,
      source,
      loginType,
      anonymousId,
      userFlow,
      expects: recoveryOptions?.expectedEmail,
      recovery: recoveryOptions?.recoveryToken,
    })
  );

// Google

export const redirectToGoogleLogin = ({
  location,
  source,
  prefillEmail,
  recoveryOptions,
}: {
  location: Location;
  hashedCsrfToken: CsrfToken;
  source: string;
  prefillEmail?: { email: string; restrictDomain: boolean };
  recoveryOptions?: SocialLoginRecoveryOptions;
}) => {
  const email = prefillEmail && { login_hint: prefillEmail.email };

  const urlParams: URLParameters = queryString.parse(location.search);
  const { userFlow } = urlParams;

  const params = {
    ...queryString.parse(location.search),
    prompt: 'select_account',
    source,
    loginType: LoginType.GoogleButton,
    userFlow,
    ...email,
    recovery: recoveryOptions?.recoveryToken,
  };

  location.assign(
    `${location.protocol}//${location.host}/login/initiate/google?` + queryString.stringify(params)
  );
};

// Auth0

export interface SocialLoginRedirectParams {
  location: Location;
  auth0Config: Auth0Config;
  csrfToken: CsrfToken;
  source: string;
  email?: string;
  recoveryOptions?: SocialLoginRecoveryOptions;
}

const redirectToAuth0SocialLogin = ({
  location,
  auth0Config,
  csrfToken,
  connection,
  loginType,
  source,
  email,
  recoveryOptions,
}: SocialLoginRedirectParams & {
  connection: 'apple' | 'microsoft' | 'slack';
  loginType: Auth0LoginType;
}) => {
  const urlParams: URLParameters = queryString.parse(location.search);
  const { userFlow } = urlParams;

  const params = {
    response_type: 'code',
    client_id: auth0Config.clientId,
    redirect_uri: auth0Config.callbackUrl + location.search,
    scope: 'openid profile email',
    state: buildState({
      location,
      csrfToken,
      source,
      loginType,
      anonymousId: getAnonymousId(),
      userFlow,
      recoveryOptions,
    }),
    prompt: 'select_account',
    login_hint: email,
    connection,
  };

  location.assign(`https://${auth0Config.domain}/authorize?` + queryString.stringify(params));
};

const isAuthEnabled = ({
  location,
  localStorageKey,
  forceEnableParamName,
  featureFlagName,
}: {
  location: H.Location;
  localStorageKey: string;
  forceEnableParamName: string;
  featureFlagName: keyof BooleanFeatureFlags;
}) => {
  const params: URLParameters = queryString.parse(location.search);
  const forceEnabled = forceEnableParamName in params;
  const featureFlagValue = isBooleanFeatureFlagSet(featureFlagName);

  if (forceEnabled) {
    try {
      localStorage.setItem(localStorageKey, '1');
    } catch (error) {
      console.error(error);
    }
  }

  if (forceEnabled || featureFlagValue) {
    return true;
  } else {
    try {
      return Boolean(localStorage.getItem(localStorageKey));
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};

// Microsoft

export const redirectToMicrosoftLogin = (params: SocialLoginRedirectParams) =>
  redirectToAuth0SocialLogin({
    ...params,
    auth0Config: {
      ...params.auth0Config,
      ...(isBooleanFeatureFlagSet('aid-signup.defer-auth0-rules-for-microsoft.enabled') &&
        params.auth0Config.deferRules.microsoft),
    },
    connection: 'microsoft',
    loginType: LoginType.Microsoft,
  });

export const isMicrosoftAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_microsoft',
    forceEnableParamName: 'enableMicrosoft',
    featureFlagName: 'aid_signup.microsoft.auth.enabled',
  });

export const microsoftAuthFeatureExposedAttributes = (
  value: boolean
): FeatureExposedAttributes => ({
  ruleId: '0E2C138D-053A-450C-914A-5E5C9D1539B9', // Generated, hardcoded for now.
  flagKey: 'aid_signup.microsoft.auth.enabled',
  value,
});

// Apple

export const redirectToAppleLogin = (params: SocialLoginRedirectParams) =>
  redirectToAuth0SocialLogin({
    ...params,
    auth0Config: {
      ...params.auth0Config,
      ...(isBooleanFeatureFlagSet('aid-signup.defer-auth0-rules-for-apple.enabled') &&
        params.auth0Config.deferRules.apple),
    },
    connection: 'apple',
    loginType: LoginType.Apple,
  });

export const isAppleAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_apple',
    forceEnableParamName: 'enableApple',
    featureFlagName: 'aid_signup.apple.auth.enabled',
  });

export const appleAuthFeatureExposedAttributes = (value: boolean): FeatureExposedAttributes => ({
  ruleId: '93E21B0C-9CB7-468E-B341-C378CCE0B285', // Generated, hardcoded for now.
  flagKey: 'aid_signup.apple.auth.enabled',
  value,
});

export const isAppleHiddenEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }

  // Hidden email as defined by <unique-alphanumeric-string>@privaterelay.appleid.com
  const hiddenEmailDomainIdentifier = 'privaterelay.appleid.com';
  const [domain] = email.split('@').slice(-1);
  return domain === hiddenEmailDomainIdentifier;
};

// Slack

export const redirectToSlackLogin = (params: SocialLoginRedirectParams) =>
  redirectToAuth0SocialLogin({
    ...params,
    // Slack's OIDC implementation expects login_hint to be a JWT and blows up if it's not. If
    // Slack ever supports using an email as the login_hint (like other OIDC providers) then we
    // should remove this line.
    email: undefined,
    auth0Config: {
      ...params.auth0Config,
      ...(isBooleanFeatureFlagSet('aid-signup.defer-auth0-rules-for-slack.enabled') &&
        params.auth0Config.deferRules.slack),
    },
    connection: 'slack',
    loginType: LoginType.Slack,
  });

export const isSlackAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_slack',
    forceEnableParamName: 'enableSlack',
    featureFlagName: 'sign-in-with-slack.enabled',
  });

export const slackAuthFeatureExposedAttributes = (value: boolean): FeatureExposedAttributes => ({
  ruleId: '65BE87D7-EE80-41EC-BBBB-68677B700347', // Generated, hardcoded for now.
  flagKey: 'sign-in-with-slack.enabled',
  value,
});

export const isGoogleLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('aid-frontend.google.login.outage.advisory.enabled');

export const isMicrosoftLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('aid-frontend.microsoft.login.outage.advisory.enabled');

export const isAppleLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('aid-frontend.apple.login.outage.advisory.enabled');

export const isSlackLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('aid-frontend.slack.login.outage.advisory.enabled');
