import {
  Action,
  ThunkAction,
  CsrfToken,
  MicrobrandingParams,
  ChangePasswordFieldErrors,
  ChangePasswordError,
  UiError,
} from '../types';
import queryString from 'query-string';
import { updateCsrfToken } from './csrf-token-actions';
import messages from '../containers/ChangePasswordPage.messages';
import { InjectedIntl } from 'react-intl';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';

export type ChangePasswordAction =
  | { type: 'CHANGE_PASSWORD_REQUEST_INITIATE' }
  | { type: 'CHANGE_PASSWORD_REQUEST_SUCCESS' }
  | { type: 'CHANGE_PASSWORD_REQUEST_FAILED'; error: ChangePasswordError }
  | { type: 'CHANGE_PASSWORD_REQUEST_FIELD_ERRORS'; fieldErrors: ChangePasswordFieldErrors };

export type ChangePasswordType = 'security-password-reset' | 'password-reset';

export const changePasswordInitiate = (): Action => ({
  type: 'CHANGE_PASSWORD_REQUEST_INITIATE',
});

export const changePasswordSuccess = (): Action => ({
  type: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
});

export const changePasswordFailure = (error: ChangePasswordError): Action => ({
  type: 'CHANGE_PASSWORD_REQUEST_FAILED',
  error,
});

export const changePasswordFieldErrors = (fieldErrors: ChangePasswordFieldErrors): Action => ({
  type: 'CHANGE_PASSWORD_REQUEST_FIELD_ERRORS',
  fieldErrors,
});

export interface ChangePasswordRequest {
  signature: string;
  password: string;
  resetType: ChangePasswordType;
}

// redirectTo is our discriminant
export type ChangePasswordResponse = ChangePasswordSuccess | ChangePasswordFailure;

export interface ChangePasswordSuccess {
  redirectTo: string;
}

export interface ChangePasswordFailure {
  redirectTo: undefined;
  uiErrors: UiError[];
  formErrors: string[];
  fieldErrors: ChangePasswordFieldErrors;
  csrfToken?: CsrfToken;
}

export type PasswordChangeRequestParams = {
  signature: string;
  password: string;
  resetType: ChangePasswordType;
  csrfToken: CsrfToken;
  microbranding?: MicrobrandingParams;
  intl: InjectedIntl;
  analyticsClient: AnalyticsClient;
};

export const requestChangePassword = (
  requestParams: PasswordChangeRequestParams
): ThunkAction => dispatch => {
  const changePasswordErrors = {
    passwordDoesNotMeetRequirements: 'passwordResetDoesntMeetPolicy',
    passwordUsedBefore: 'passwordResetPreviouslyUsed',
    passwordLeaked: 'passwordLeaked',
    unexpectedError: 'passwordResetUnexpectedError',
  };
  const {
    signature,
    password,
    resetType,
    csrfToken,
    microbranding,
    intl,
    analyticsClient,
  } = requestParams;

  const params = {
    ...microbranding,
    signature,
  };

  const qs = params && Object.keys(params).length ? `?${queryString.stringify(params)}` : '';

  dispatch(changePasswordInitiate());

  if (!password) {
    const message = intl.formatMessage(messages.emptyPasswordErrorMessage);
    dispatch(
      changePasswordFieldErrors({
        password: message,
      })
    );
    return Promise.resolve();
  }

  const request: ChangePasswordRequest = {
    password,
    resetType,
    signature,
  };

  return fetch(`/rest/changepassword${qs}`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    body: JSON.stringify(request),
  })
    .then(response => response.json())
    .then((response: ChangePasswordResponse) => {
      if (response.redirectTo !== undefined) {
        dispatch(changePasswordSuccess());
        window.location.assign(response.redirectTo);
      } else {
        const { csrfToken, uiErrors, formErrors, fieldErrors } = response;

        // SIS doesn't set a new CSRF token on every request (like aid-signup does), and so it
        // doesn't return one. We need to handle both cases though.
        if (csrfToken) {
          dispatch(updateCsrfToken(csrfToken));
        }

        if (uiErrors && uiErrors.length > 0) {
          const subjectId = changePasswordErrors[uiErrors[0].key] || 'passwordResetUnknownUiError';
          analyticsClient.errorShownEvent('accountRecoveryScreen', subjectId);
        }

        if (formErrors && formErrors.length > 0) {
          if (uiErrors && uiErrors.length > 0 && uiErrors[0].key == 'passwordLeaked') {
            dispatch(changePasswordFailure({ type: 'PASSWORD_LEAKED' }));
          } else {
            dispatch(changePasswordFailure({ type: 'BACKEND_ERROR', message: formErrors[0] }));
          }
        }

        if (fieldErrors) {
          dispatch(changePasswordFieldErrors(fieldErrors));
        }
      }
    })
    .catch(error =>
      dispatch(changePasswordFailure({ type: 'UNKNOWN_ERROR', message: undefined, cause: error }))
    );
};
