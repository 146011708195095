import { defineMessages } from 'react-intl';

export default defineMessages({
  csrfMismatchTitle: {
    id: 'error.csrfmismatch.title',
    defaultMessage: "We couldn't log you in",
  },
  csrfMismatchDescriptionFirstLine: {
    id: 'error.csrfmismatch.description.firstline',
    defaultMessage: 'Before trying again:',
  },
  csrfMismatchDescriptionSecondLine: {
    id: 'error.csrfmismatch.description.secondline',
    defaultMessage: 'Close any <b>{siteName}</b> windows in your browser',
  },
  csrfMismatchDescriptionThirdLine: {
    id: 'error.csrfmismatch.description.thirdline',
    defaultMessage:
      'Check your browser settings and disable any extensions that may interfere with cookies.',
  },
  csrfMismatchButtonText: {
    id: 'error.csrfmismatch.button',
    defaultMessage: 'Try logging in again',
  },
});
