import React from 'react';

import {
  Header,
  search,
  secondaryNav,
  issueBreadcrumbs,
  issueActions,
  issueContent,
  detailsPanel,
} from '../../../images/cobranding/jira-experiment';

import {
  Background,
  HeaderContainer,
  Search,
  HeaderLine,
  SecondaryNavBackground,
  SecondaryNav,
  IssueBreadcrumbs,
  IssueActions,
  IssueContentWrapper,
  IssueContent,
  DetailsPanel,
} from './JiraWireframeStyles';

export const JiraWireframe = () => {
  return (
    <Background>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Search src={search} />
      <HeaderLine />
      <SecondaryNavBackground />
      <SecondaryNav src={secondaryNav} />
      <IssueBreadcrumbs src={issueBreadcrumbs} />
      <IssueActions src={issueActions} />
      <IssueContentWrapper>
        <IssueContent src={issueContent} />
      </IssueContentWrapper>
      <DetailsPanel src={detailsPanel} />
    </Background>
  );
};
