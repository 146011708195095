import React from 'react';
import { WelcomeSentError } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers/index';
import WelcomeSentInvalidTokenErrorPage from './WelcomeSentInvalidTokenErrorPage';
import WelcomeSentExpiredTokenErrorPage from './WelcomeSentExpiredTokenErrorPage';
import WelcomeSentSuccessPage from './WelcomeSentSuccessPage';

interface PropsFromState {
  error: WelcomeSentError | null;
}

type Props = PropsFromState;

export const WelcomeSentPage: React.FC<Props> = ({ error }) => {
  if (error === WelcomeSentError.InvalidToken) {
    return <WelcomeSentInvalidTokenErrorPage />;
  } else if (error === WelcomeSentError.ExpiredToken) {
    return <WelcomeSentExpiredTokenErrorPage />;
  } else {
    return <WelcomeSentSuccessPage />;
  }
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  error: state.welcomeSent.error,
});

export default wrapComponent<PropsFromState>(
  {
    mapStateToProps,
  },
  WelcomeSentPage
);
