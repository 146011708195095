import { combineReducers } from 'redux';

import appConfig, { State as AppConfigState } from './app-config-reducer';
import csrfToken, { State as CsrfTokenState, hashedCsrfToken } from './csrf-token-reducer';
import welcome, { State as WelcomeState } from './welcome-reducer';
import login, { State as LoginState } from './login-reducer';
import logout, { State as LogoutState } from './logout-reducer';
import mfaEnrollment, { State as MfaEnrollmentState } from './mfa-enrollment-reducer';
import microbranding, { State as MicrobrandingState } from './microbranding-reducer';
import multiFactor, { State as MultiFactorState } from './multi-factor-reducer';
import recovery, { State as RecoveryState } from './recovery-reducer';
import resetPassword, { State as ResetPasswordState } from './reset-password-reducer';
import signup, { State as SignupState } from './signup-reducer';
import user, { State as UserState } from './user-reducer';
import flags, { State as FlagState } from './flag-reducer';
import continueAs, { State as ContinueAsState } from './continue-as-reducer';
import selectAccount, { State as SelectAccountState } from './select-account-reducer';
import cancelDeletion, { State as CancelDeletionState } from './cancel-deletion-reducer';
import expiredPassword, { State as ExpiredPasswordState } from './expired-password-reducer';
import googleOneTap, { State as GoogleOneTapState } from './google-one-tap-reducer';
import changeEmail, { State as ChangeEmailState } from './change-email-reducer';
import changePassword, { State as ChangePasswordState } from './change-password-reducer';
import verifyEmail, { State as VerifyEmailState } from './verify-email-reducer';
import welcomeSent, { State as WelcomeSentState } from './welcome-sent-reducer';
import verifyEmailSent, { State as VerifyEmailSentState } from './verify-email-sent-reducer';
import stepUp, { State as StepUpState } from './step-up-reducer';
import { connectRouter } from 'connected-react-router';
import { History, Location } from 'history';
import { LocationState } from '../actions/history-actions';
import createAccount, { State as CreateAccountState } from './create-account-reducer';
import context, { State as ContextState } from './context-reducer';
import migrateConfirmation, {
  State as migrateConfirmationState,
} from './migrate-confirmation-reducer';
import marketingConsent, { State as MarketingConsentState } from './marketing-consent-reducer';
import socialLoginRecovery, {
  State as SocialLoginRecoveryState,
} from './social-login-recovery-reducer';
import serverError, { State as ServerErrorState } from './server-error-reducer';
import cobranding, { State as CobrandingState } from './cobranding-reducer';
import ffsId, { State as FfsIdState } from './ffs-id-reducer';

export type ParameterizedLocation = Location<LocationState | undefined>;

export interface State {
  appConfig: AppConfigState;
  csrfToken: CsrfTokenState;
  hashedCsrfToken: CsrfTokenState;
  login: LoginState;
  logout: LogoutState;
  welcome: WelcomeState;
  marketingConsent: MarketingConsentState;
  mfaEnrollment: MfaEnrollmentState;
  recovery: RecoveryState;
  microbranding: MicrobrandingState;
  multiFactor: MultiFactorState;
  resetPassword: ResetPasswordState;
  signup: SignupState;
  user: UserState;
  flags: FlagState;
  continueAs: ContinueAsState;
  selectAccount: SelectAccountState;
  cancelDeletion: CancelDeletionState;
  expiredPassword: ExpiredPasswordState;
  changePassword: ChangePasswordState;
  changeEmail: ChangeEmailState;
  router: {
    // RouterState is not parameterizable
    location: ParameterizedLocation;
  };
  googleOneTap: GoogleOneTapState;
  createAccount: CreateAccountState;
  context: ContextState;
  migrateConfirmation: migrateConfirmationState;
  socialLoginRecovery: SocialLoginRecoveryState;
  verifyEmail: VerifyEmailState;
  verifyEmailSent: VerifyEmailSentState;
  welcomeSent: WelcomeSentState;
  serverError: ServerErrorState;
  cobranding: CobrandingState;
  stepUp: StepUpState;
  ffsId: FfsIdState;
}

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    appConfig,
    csrfToken,
    hashedCsrfToken,
    login,
    logout,
    marketingConsent,
    mfaEnrollment,
    microbranding,
    multiFactor,
    recovery,
    resetPassword,
    signup,
    user,
    flags,
    continueAs,
    selectAccount,
    cancelDeletion,
    expiredPassword,
    googleOneTap,
    changePassword,
    changeEmail,
    createAccount,
    welcome,
    context,
    migrateConfirmation,
    socialLoginRecovery,
    verifyEmail,
    verifyEmailSent,
    welcomeSent,
    serverError,
    cobranding,
    stepUp,
    ffsId,
  });
