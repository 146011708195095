import React, { Component } from 'react';
import { IntlProvider, addLocaleData, LocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import cs from 'react-intl/locale-data/cs';
import nl from 'react-intl/locale-data/nl';
import et from 'react-intl/locale-data/et';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import hu from 'react-intl/locale-data/hu';
import is from 'react-intl/locale-data/is';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import nb from 'react-intl/locale-data/nb';
import pl from 'react-intl/locale-data/pl';
import ro from 'react-intl/locale-data/ro';
import ru from 'react-intl/locale-data/ru';
import sk from 'react-intl/locale-data/sk';
import es from 'react-intl/locale-data/es';
import sv from 'react-intl/locale-data/sv';
import th from 'react-intl/locale-data/th';
import tr from 'react-intl/locale-data/tr';
import uk from 'react-intl/locale-data/uk';
import vi from 'react-intl/locale-data/vi';
import pt from 'react-intl/locale-data/pt';
import da from 'react-intl/locale-data/da';
import { captureErrorMessage } from '../utilities/analytics/error-reporting';

interface Props {
  children?: React.ReactNode;
}

interface State {
  locale: string;
  messages: { [key: string]: string };
  loading: boolean;
  localeId: string | null;
}

export default class LocaleSwitcher extends Component<Props, State> {
  static localeRepresentation: { [key: string]: LocaleData } = {
    en: en,
    zh: zh,
    cs: cs,
    nl: nl,
    et: et,
    fi: fi,
    fr: fr,
    de: de,
    hu: hu,
    is: is,
    it: it,
    ja: ja,
    ko: ko,
    nb: nb,
    pl: pl,
    ro: ro,
    ru: ru,
    sk: sk,
    es: es,
    sv: sv,
    th: th,
    tr: tr,
    uk: uk,
    vi: vi,
    pt: pt,
    da: da,
  };
  // For these language codes we need more information about the extra tags in order to specify a translation file
  static languageTagLocales: string[] = ['zh', 'en', 'pt'];
  static defaultLanguageLocale: string = 'en';
  static languagesWithTranslationsAvaiable: string[] = [
    LocaleSwitcher.defaultLanguageLocale,
    'zh',
    'cs',
    'nl',
    'et',
    'fi',
    'fr',
    'de',
    'hu',
    'it',
    'ja',
    'ko',
    'nb',
    'pl',
    'ru',
    'sk',
    'es',
    'sv',
    'th',
    'tr',
    'uk',
    'vi',
    'pt',
    'da',
  ];

  constructor(props: Props) {
    super(props);

    const browserLanguage = LocaleSwitcher.sanitisedBrowserLanguage(navigator.language);
    const localeId = LocaleSwitcher.getLocaleId(browserLanguage);

    this.state = {
      locale: browserLanguage,
      messages: {},
      loading: !!localeId,
      localeId: localeId || null,
    };
  }

  componentDidMount() {
    if (this.state.localeId) {
      this.fetchLanguage(this.state.localeId);
    }
  }

  fetchLanguage(localeId: string) {
    const translationModule = LocaleSwitcher.languageTagLocales.includes(localeId)
      ? this.state.locale
      : localeId;

    if (!LocaleSwitcher.languagesWithTranslationsAvaiable.includes(localeId)) {
      const lwta = LocaleSwitcher.languagesWithTranslationsAvaiable;
      console.debug(
        `localId ${localeId} has no available translation (available translations are ${lwta} - no attempt to load ${translationModule} will be made`
      );
      this.setState({ loading: false });
      return;
    }

    import('../i18n/' + translationModule)
      .then(i18n => {
        addLocaleData(LocaleSwitcher.localeRepresentation[localeId]);
        this.setState({
          locale: i18n.locale,
          messages: i18n.messages,
          loading: false,
        });
      })
      .catch(error => {
        captureErrorMessage('i18n_fetch_language_error', Object.assign({ localeId }, error));
        this.setState({ loading: false });
      });
  }

  static getLocaleId(browserLanguage: string): string | undefined {
    // React intl doesn't store locale settings for languages with the same country as language, e.g. de-DE so will
    // fail to return a localeID in our lookup
    const [language, country] = browserLanguage.split('-');
    const sanitizedBrowserLanguage =
      country && language === country.toLowerCase() ? language : browserLanguage;

    // Portuguese (Brazil) does not have built in locale data (which contains things like grammar and plural settings)
    // in React-intl, we will treat it the same as Portuguese
    if (sanitizedBrowserLanguage === 'pt-BR') {
      return 'pt';
    }

    // React-intl uses HANS/HANT for Chinese country codes which doesn't align with that which Chrome or Firefox sends
    if (sanitizedBrowserLanguage === 'zh-CN' || sanitizedBrowserLanguage === 'zh-TW') {
      return 'zh';
    }

    // Safari sends ja-jp locale id for japanese
    if (sanitizedBrowserLanguage === 'ja-JP') {
      return 'ja';
    }

    let localeId: string | undefined = undefined;
    if (sanitizedBrowserLanguage !== LocaleSwitcher.defaultLanguageLocale) {
      const containsBrowserLanguage = LocaleSwitcher.langInLocale(sanitizedBrowserLanguage);
      localeId = Object.keys(LocaleSwitcher.localeRepresentation).find(repr =>
        containsBrowserLanguage(LocaleSwitcher.localeRepresentation[repr])
      );
    }

    return localeId;
  }

  static sanitisedBrowserLanguage(browserLanguage: string): string {
    const [language, country] = browserLanguage.split('-');
    switch (language.toLowerCase()) {
      case 'pt': {
        if (!country) {
          return 'pt-PT';
        } else {
          return LocaleSwitcher.correctLangugageCases(language, country);
        }
      }
      case 'zh': {
        if (!country) {
          return 'zh-CN';
        }
        break;
      }
      default:
        break;
    }
    return LocaleSwitcher.correctLangugageCases(language, country);
  }

  // Safari returns the country code lowercase.
  // Enforce a standard which aligns with translation files.
  static correctLangugageCases(language: string, country: string): string {
    if (!country) {
      return language.toLowerCase();
    } else {
      return language.toLowerCase() + '-' + country.toUpperCase();
    }
  }

  static langInLocale(language: string) {
    return (locale: LocaleData) => locale.map(l => l.locale).includes(language);
  }

  render() {
    return (
      <IntlProvider
        key={this.state.locale}
        locale={this.state.locale}
        messages={this.state.messages}>
        {this.state.loading ? <></> : this.props.children}
      </IntlProvider>
    );
  }
}
