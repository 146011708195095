import React, { Ref } from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import TextField, { TextFieldProps } from '@atlaskit/textfield';
import { Field, ErrorMessage } from '@atlaskit/form';

export interface FormTextFieldProps extends Omit<TextFieldProps, 'label'> {
  name: string;
  label?: React.ReactNode;
  isLabelHidden?: boolean;
  invalidMessage?: React.ReactNode;
}

// https://hello.atlassian.net/browse/KIRBY-1876
const WebkitHack = styled.div`
  & input[disabled] {
    -webkit-text-fill-color: ${colors.N70};
  }
`;

const FormTextField = (
  { name, label, isLabelHidden, invalidMessage, ...props }: FormTextFieldProps,
  ref: Ref<HTMLInputElement>
) => {
  return (
    <WebkitHack>
      <Field name={name} label={!isLabelHidden && label}>
        {({ fieldProps }) => (
          <>
            <TextField {...fieldProps} {...props} ref={ref} />
            {invalidMessage && <ErrorMessage>{invalidMessage}</ErrorMessage>}
          </>
        )}
      </Field>
    </WebkitHack>
  );
};

export default React.forwardRef<HTMLInputElement, FormTextFieldProps>(FormTextField);
