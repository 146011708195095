import queryString from 'query-string';
import { URLParameters, ContinueURLParameters } from '../types/';
import { captureException } from './analytics/error-reporting';

export type DestinationProduct = 'Jira' | 'Confluence' | 'BitBucket' | 'Join Site from WAC';

export type DestinationError = 'missing continue param' | 'unknown hostname' | 'unknown product';

export type DestinationDetails =
  | { error: DestinationError }
  | {
      product: Extract<'Jira' | 'Confluence', DestinationProduct>;
      content?: string;
      hostName: string;
      contentType?: string;
    }
  | { product: Exclude<DestinationProduct, 'Jira' | 'Confluence'>; content?: string };

enum ContentType {
  Blog = 'blog',
  Board = 'board',
  Issue = 'issue',
  Page = 'page',
}

type ConfluenceMetadata = {
  title: string;
  type: ContentType;
};

const extractQueryFromUrl = (input: string): string => {
  const url = new URL(input, window.location.origin);
  return url.search;
};

const preventBreakOnLastWord = str => str.replace(/ (?=\S+$)/, '\xA0');
const decodeUrlParam = param => decodeURIComponent(param.replace(/\+/g, '%20'));

function pageMetadataFromConfluenceDest(confluenceDestUrl): ConfluenceMetadata | undefined {
  const pageMatch = confluenceDestUrl.match(new RegExp('^/wiki/spaces/[^/]+/pages/[^/]+/([^/]+)$'));
  const blogMatch = confluenceDestUrl.match(
    new RegExp('^/wiki/spaces/[^/]+/blog/[^/]+/[^/]+/[^/]+/[^/]+/([^/]+)$')
  );

  if (pageMatch) {
    return {
      title: preventBreakOnLastWord(decodeUrlParam(pageMatch[1])),
      type: ContentType.Page,
    };
  } else if (blogMatch) {
    return {
      title: preventBreakOnLastWord(decodeUrlParam(blogMatch[1])),
      type: ContentType.Blog,
    };
  } else {
    return;
  }
}

function confluenceDestinationContent(hostName: string, destUrl: string): DestinationDetails {
  const destinationDetails: DestinationDetails = { product: 'Confluence', hostName };
  const metadata = pageMetadataFromConfluenceDest(destUrl);
  if (!metadata) {
    return destinationDetails;
  }
  const pageTitleLooksLikeId = /[0-9]+/.test(metadata.title);
  if (pageTitleLooksLikeId) {
    return destinationDetails;
  }

  return {
    ...destinationDetails,
    content: metadata.title,
    contentType: metadata.type,
  };
}

export const detectDestinationContent = (): DestinationDetails => {
  try {
    const params: URLParameters = queryString.parse(window.location.search);
    const continueUrl = params.continue;

    if (!continueUrl) {
      return {
        error: 'missing continue param',
      };
    }

    const continueURL = new URL(continueUrl);
    const continueParams: ContinueURLParameters = queryString.parse(continueURL.search);

    if (continueParams['openid.return_to']) {
      const targetUrl = continueParams['openid.return_to'];

      if (/^https:\/\/bitbucket\.org/.test(targetUrl)) {
        return {
          product: 'BitBucket',
        };
      }
    }

    if (continueURL.hostname + continueURL.pathname === 'id.atlassian.com/start') {
      return {
        product: 'Join Site from WAC',
      };
    }

    if (!/(\.atlassian\.net|\.jira-dev\.com)$/.test(continueURL.hostname)) {
      return {
        error: 'unknown hostname',
      };
    }

    const destParam = continueParams['dest-url'] ? continueParams['dest-url'] : continueUrl;
    const destUrlObject = new URL(destParam, continueURL.origin); // continueParams['dest-url'] requires an origin
    const hostName = destUrlObject.hostname;
    const destUrl = destUrlObject.pathname;
    if (!destUrl) {
      return {
        error: 'unknown product',
      };
    }

    if (/^\/wiki($|\/)/.test(destUrl)) {
      return confluenceDestinationContent(hostName, destUrl);
    }

    const issueMatch = destUrl.match(new RegExp('^/browse(?:/([^/]+)/?)?'));
    if (issueMatch) {
      const issueKey = issueMatch[1];
      return {
        product: 'Jira',
        content: issueKey,
        hostName,
        contentType: ContentType.Issue,
      };
    }

    const boardMatch = destUrl.match(new RegExp('^/secure/RapidBoard.jspa'));
    if (boardMatch) {
      const { projectKey } = queryString.parse(extractQueryFromUrl(destUrl));
      if (projectKey) {
        return {
          product: 'Jira',
          content: `${projectKey} board`,
          hostName,
          contentType: ContentType.Board,
        };
      }
    }
  } catch (e) {
    captureException(e);
  }

  return {
    error: 'unknown product',
  };
};
