import { Action } from '../types';

export interface State {
  userData?: UserData;
  formError?: string;
}

export interface UserData {
  email: string;
  avatarUrl: string;
  displayName?: string;
}

export const defaultState = {};

const logout = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default logout;
