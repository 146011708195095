import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import EmailSent from '../components/EmailSent';
import Button from '@atlaskit/button';

import { Flag, InjectedAnalyticsProps, InjectedRouterProps, OidcContext } from '../types';
import { addFlag } from '../actions/flag-actions';
import { wrapComponent } from '../utilities/wrap-component';
import messages from './VerifyEmailSentPage.messages';
import { Dispatch } from 'redux';
import { State as MainState } from '../reducers/index';
import WhiteboxPage from '../components/WhiteboxPage';
import queryString from 'query-string';
import { isTrelloDesktop } from '../utilities/applications';

interface PropsFromState {
  email: string;
  isEmbedded: boolean;
  oidcContext: OidcContext | null;
  showBrowserWarning: boolean;
}

interface PropsFromDispatch {
  addFlag: (flag: Flag) => void;
}

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export class VerifyEmailSentPageComponent extends Component<Props> {
  static pageId = 'verifyEmailScreen';

  constructor(props: Props) {
    super(props);
    this.props.analyticsClient.pageViewedEvent(VerifyEmailSentPageComponent.pageId, {
      browserWarningMessageShown: this.props.showBrowserWarning,
    });
  }

  handleResendVerificationEmail = () => {
    const { location, analyticsClient } = this.props;
    analyticsClient.buttonClickedEvent(
      VerifyEmailSentPageComponent.pageId,
      'resendVerificationEmail'
    );
    return fetch(`/rest/resend-email${location.search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 204) {
          this.addSuccessFlag();
        } else {
          this.addErrorFlag();
        }
      })
      .catch(() => {
        this.addErrorFlag();
      });
  };

  handleReturnToLogin = () => {
    const { analyticsClient } = this.props;
    analyticsClient.buttonClickedEvent(
      VerifyEmailSentPageComponent.pageId,
      'verifyEmailReturnLoginLink'
    );
  };

  addSuccessFlag() {
    this.props.addFlag({
      type: 'success',
      title: messages.verifyEmailFlagSuccessTitle,
      description: messages.verifyEmailFlagSuccessDesc,
    });
  }

  addErrorFlag() {
    this.props.addFlag({
      type: 'error',
      title: messages.verifyEmailFlagErrorTitle,
      description: messages.verifyEmailFlagErrorDesc,
    });
  }

  header = () => (
    <FormattedMessage
      id="verify.email.title"
      tagName="h5"
      defaultMessage="Check your inbox to finish signup"
    />
  );

  renderReturnToLoginLink = () => {
    const { location, email } = this.props;

    const returnLoginLink = `/login?${queryString.stringify({
      ...queryString.parse(location.search),
      email,
    })}`;

    if (isTrelloDesktop()) {
      return (
        <Link id="return-login-button" to={returnLoginLink} onClick={this.handleReturnToLogin}>
          <FormattedMessage id="return.to.log.in" defaultMessage="Return to log in" />
        </Link>
      );
    }
    return null;
  };

  whiteboxFooter = () => {
    const resendButton = (
      <Button
        id="send-validation-submit"
        appearance="link"
        spacing="none"
        className="link-color-override"
        onClick={this.handleResendVerificationEmail}>
        <FormattedMessage
          id="verify.email.resend.signup.link"
          defaultMessage="Resend signup link"
        />
      </Button>
    );

    return (
      <>
        <ul>
          <li>{resendButton}</li>
        </ul>
        <ul>
          <li>{this.renderReturnToLoginLink()}</li>
        </ul>
      </>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      showBrowserWarning,
    } = this.props;
    return (
      <WhiteboxPage
        // @ts-ignore no id
        id="email-sent-page"
        pageId={VerifyEmailSentPageComponent.pageId}
        header={this.header()}
        whiteboxFooter={this.whiteboxFooter()}>
        <Helmet title={formatMessage(messages.headTitle)} />
        <EmailSent
          email={this.props.email}
          message={
            <FormattedMessage
              id="verify.email.sent.signup.link"
              tagName="p"
              defaultMessage="We sent a signup link to you at"
              description="Telling user that a verification link has been sent. This message is followed by the user's email address on the next line."
            />
          }
          warningMessage={
            showBrowserWarning && (
              <FormattedMessage
                id="verify.email.sent.browser.warning"
                defaultMessage="Make sure to verify your email on this device and browser."
                description="Telling the user to open the verification email sent by this page on the same device and browser"
              />
            )
          }
        />
      </WhiteboxPage>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  addFlag(flag: Flag) {
    dispatch(addFlag(flag.type, flag.title, flag.description));
  },
});

const mapStateToProps = (state: MainState): PropsFromState => ({
  email: state.user.email,
  isEmbedded: state.microbranding.isEmbedded,
  oidcContext: state.microbranding.oidcContext,
  showBrowserWarning: state.verifyEmailSent.showBrowserWarning,
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  VerifyEmailSentPageComponent
);
