import { RedirectType } from '../types';

export enum ExperimentCohort {
  NotEnrolled = 'not-enrolled',
  Variation = 'variation',
  Control = 'control',
}

/* Uncomment this block to test that these properties still hold when editing the types here
// should typecheck
isBooleanFeatureFlagSet('aid_signup.apple.auth.enabled');
getFeatureFlag('aid_signup.apple.auth.enabled', true);

// should not
isBooleanFeatureFlagSet('not-a-real-feature-flag');
getFeatureFlag('aid_signup.apple.auth.enabled', 'false');
*/

export function isBooleanFeatureFlagSet(name: keyof BooleanFeatureFlags): boolean {
  return getFeatureFlag<keyof BooleanFeatureFlags>(name, false) === true;
}

export function getFeatureFlag<K extends keyof FeatureFlags>(
  name: K,
  defaultValue: Required<FeatureFlags>[K]
): Required<FeatureFlags>[K] {
  if (window.featureFlags && window.featureFlags.hasOwnProperty(name)) {
    // @ts-ignore
    return window.featureFlags[name];
  } else {
    return defaultValue;
  }
}

export type BooleanFeatureFlags = Partial<
  Record<
    | 'aid_signup.microsoft.auth.enabled'
    | 'aid_signup.apple.auth.enabled'
    | 'sign-in-with-slack.enabled'
    | 'aid_signup.disallow.bitbucket.username.login'
    | 'aid-frontend.google.login.outage.advisory.enabled'
    | 'aid-frontend.microsoft.login.outage.advisory.enabled'
    | 'aid-frontend.apple.login.outage.advisory.enabled'
    | 'aid-frontend.slack.login.outage.advisory.enabled'
    | 'aid-frontend.google.one.tap.enabled'
    | 'aid-signup.defer-auth0-rules-for-apple.enabled'
    | 'aid-signup.defer-auth0-rules-for-microsoft.enabled'
    | 'aid-signup.defer-auth0-rules-for-slack.enabled',
    boolean
  >
>;

/**
 * Login page optimisation experiment v1
 */
export enum LoginPageExperimentCohort {
  NO_ANONYMOUS_ID = 'no-anonymous-id',
  CONTROL = 'control',
  CONTROL_NOT_ENROLLED = 'control-not-enrolled',
  BACKGROUND = 'background-variation',
  BACKGROUND_NOT_ENROLLED = 'background-not-enrolled',
  WHITEBOX = 'whitebox-variation',
  WHITEBOX_NOT_ENROLLED = 'whitebox-not-enrolled',
  BOTH = 'both-variation',
  BOTH_NOT_ENROLLED = 'both-not-enrolled',
}

export type LoginPageExperimentFeatureFlags = Partial<
  Record<'aid-frontend.growth.login-page-optimisation', LoginPageExperimentCohort>
>;

export function getLoginExperimentFeatureFlag(
  name: keyof LoginPageExperimentFeatureFlags
): LoginPageExperimentCohort {
  return getFeatureFlag<keyof LoginPageExperimentFeatureFlags>(
    name,
    LoginPageExperimentCohort.CONTROL_NOT_ENROLLED
  );
}

export const isEligibleForLoginExperiment = (
  acceptableCohorts: LoginPageExperimentCohort[],
  isMobileScreenSize: boolean,
  product: string | undefined,
  redirectType?: RedirectType
) =>
  resolveLoginPageExperiment(acceptableCohorts) &&
  isEligibleForLoginExperimentWithoutCohortCheck(isMobileScreenSize, product, redirectType);

const isEligibleForLoginExperimentWithoutCohortCheck = (
  isMobileScreenSize: boolean,
  product: string | undefined,
  redirectType?: RedirectType
) =>
  !isMobileScreenSize &&
  !!product &&
  (product === 'jira' || product === 'confluence') &&
  (redirectType
    ? redirectType === RedirectType.Signup || redirectType === RedirectType.ResumeSignup
    : true);

// Convenience function to get the LD flag value or 'not-enrolled'. Used for experiment post analysis only.
export const getResolvedCohortLoginExperiment = (
  isMobileScreenSize: boolean,
  product: string | undefined,
  redirectType?: RedirectType
): string => {
  if (
    isEligibleForLoginExperiment(
      loginPageExperimentGroups.enrolled,
      isMobileScreenSize,
      product,
      redirectType
    )
  ) {
    return getLoginExperimentFeatureFlag('aid-frontend.growth.login-page-optimisation');
  }
  return 'not-enrolled';
};

export type ExperimentCohortFeatureFlags = Partial<Record<never, ExperimentCohort>>;

export type FeatureFlags = BooleanFeatureFlags &
  ExperimentCohortFeatureFlags &
  LoginPageExperimentFeatureFlags &
  LoginPageExperimentFeatureFlagV2;

export function resolveLoginPageExperiment(cohorts: LoginPageExperimentCohort[]): boolean {
  const loginExperimentFeatureFlag = getLoginExperimentFeatureFlag(
    'aid-frontend.growth.login-page-optimisation'
  );
  return cohorts.includes(loginExperimentFeatureFlag);
}

export const loginPageExperimentGroups = {
  enrolled: [
    LoginPageExperimentCohort.CONTROL,
    LoginPageExperimentCohort.BACKGROUND,
    LoginPageExperimentCohort.WHITEBOX,
    LoginPageExperimentCohort.BOTH,
  ],
  variation: [
    LoginPageExperimentCohort.BACKGROUND,
    LoginPageExperimentCohort.WHITEBOX,
    LoginPageExperimentCohort.BOTH,
  ],
  background: [LoginPageExperimentCohort.BACKGROUND, LoginPageExperimentCohort.BOTH],
  whitebox: [LoginPageExperimentCohort.WHITEBOX, LoginPageExperimentCohort.BOTH],
};

/**
 * Login screen optimisation experiment v2
 */
const LOGIN_PAGE_EXPERIMENT_V2_LAUCH_DARKLY_KEY = 'aid-frontend.growth.login-page-optimisation-v2';

export enum LoginPageExperimentCohortV2 {
  CONTROL = 'control',
  VARIATION = 'variation',
  NOT_ENROLLED = 'not-enrolled',
}

export type LoginPageExperimentFeatureFlagV2 = Partial<
  Record<typeof LOGIN_PAGE_EXPERIMENT_V2_LAUCH_DARKLY_KEY, LoginPageExperimentCohortV2>
>;

export type LoginPageExperimentEligibility = {
  cohortV1: LoginPageExperimentCohort;
  cohortV2: LoginPageExperimentCohortV2;
  isEligibleForV2: boolean;
  resolvedCohortV1: string;
  resolvedCohortV2: string;
  isEnrolledV1: boolean;
  isEnrolledV2: boolean;
  showExperimental: boolean;
  showExperimentalWhiteBoxStyles: boolean;
  showExperimentalBackgroundStyles: boolean;
};

/**
 * Determine eligibility across both v1 and v2 for login page optimisation experiment.
 *
 * @param isMobileScreenSize true if the user's screen size is considered a "mobile" screen size.
 * @param product a string representing the product the user will be forwarded to after login/signup.
 * @param redirectType optional string which represents the reason for signup.
 *    Optional because redirectType is only valid on Welcome and WelcomeSent pages.
 *    Only show experimental changes when redirectType is signup or resume_signup.
 * @returns an object containing:
 * cohortV1 - the user's v1 cohort as a string.
 * cohortV2 - the user's v2 cohort as a string.
 * isEligibleForV2 - the user is not enrolled in v1 and is eligible for v2
 * resolvedCohortV1 - the user's v1 cohort (if in a variation) or 'not-enrolled' as a string.
 * resolvedCohortV2 - the user's v1 cohort, or, the user's v2 cohort if they're enrolled in no-anonymous-id cohort from v1.
 * isEnrolledV1 - true if the user is not in a not-enrolled cohort for the login experiment.
 * isEnrolledV2 - true if the user is not in a not-enrolled cohort for the login experiment.
 * showExperimental - true if the user is eligible for the login experiment.
 *    This represents general experimental changes that should be shown to all variatons across both v1 and v2 login experiment feature flags.
 * showExperimentalWhiteBoxStyles - true if the user should see experimental whitebox changes.
 *    This is based off the cohort the user is enrolled in for both v1 and v2 flags.
 * showExperimentalBackgroundStyles - true if the user should see experimental background changes.
 *    This is based off the cohort the user is enrolled in for both v1 and v2 flags.
 */
export const getLoginExperimentEligibility = (
  isMobileScreenSize: boolean,
  product: string | undefined,
  redirectType?: RedirectType
): LoginPageExperimentEligibility => {
  const ffv1 = getLoginExperimentFeatureFlag('aid-frontend.growth.login-page-optimisation');
  const ffv2 = getFeatureFlag<keyof LoginPageExperimentFeatureFlagV2>(
    LOGIN_PAGE_EXPERIMENT_V2_LAUCH_DARKLY_KEY,
    LoginPageExperimentCohortV2.NOT_ENROLLED
  );

  const isEligibleForEnrollment = isEligibleForLoginExperimentWithoutCohortCheck(
    isMobileScreenSize,
    product,
    redirectType
  );

  const resolvedCohortV1 =
    isEligibleForEnrollment && loginPageExperimentGroups.enrolled.includes(ffv1)
      ? ffv1
      : 'not-enrolled';
  const resolvedCohortV2 =
    isEligibleForEnrollment && ffv1 === LoginPageExperimentCohort.NO_ANONYMOUS_ID
      ? ffv2
      : resolvedCohortV1;

  const isEnrolledV1 = loginPageExperimentGroups.enrolled.includes(ffv1);

  const isEnrolledV2 =
    ffv1 === LoginPageExperimentCohort.NO_ANONYMOUS_ID &&
    (ffv2 === LoginPageExperimentCohortV2.VARIATION ||
      ffv2 === LoginPageExperimentCohortV2.CONTROL);

  // for all showExperimental* variables, be safe and feature flag v2 logic changes.
  // check that the user is in `no-anonymous-id` cohort as the feature guard.
  const isInNoAnonIdCohort = ffv1 === LoginPageExperimentCohort.NO_ANONYMOUS_ID;
  const isEligibleForLoginExperimentV2 =
    isEligibleForEnrollment && resolvedCohortV2 === LoginPageExperimentCohortV2.VARIATION;
  const showExperimental = isInNoAnonIdCohort
    ? isEligibleForLoginExperimentV2
    : isEligibleForLoginExperiment(
        loginPageExperimentGroups.variation,
        isMobileScreenSize,
        product,
        redirectType
      );

  const showExperimentalWhiteBoxStyles = isInNoAnonIdCohort
    ? false
    : isEligibleForLoginExperiment(
        loginPageExperimentGroups.whitebox,
        isMobileScreenSize,
        product,
        redirectType
      );

  const showExperimentalBackgroundStyles = isInNoAnonIdCohort
    ? isEligibleForLoginExperimentV2
    : isEligibleForLoginExperiment(
        loginPageExperimentGroups.background,
        isMobileScreenSize,
        product,
        redirectType
      );

  const isEligibleForV2 =
    isEligibleForEnrollment && ffv1 === LoginPageExperimentCohort.NO_ANONYMOUS_ID;
  return {
    cohortV1: ffv1,
    cohortV2: ffv2,
    isEligibleForV2,
    resolvedCohortV1,
    resolvedCohortV2,
    isEnrolledV1: isEligibleForEnrollment && isEnrolledV1,
    isEnrolledV2: isEligibleForEnrollment && isEnrolledV2,
    showExperimental,
    showExperimentalWhiteBoxStyles,
    showExperimentalBackgroundStyles,
  };
};
