import React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import Helmet from 'react-helmet';

import EmailSent from '../components/EmailSent';
import Button from '@atlaskit/button/standard-button';

import { Flag, InjectedAnalyticsProps, InjectedRouterProps } from '../types';
import { addFlag } from '../actions/flag-actions';
import { wrapComponent } from '../utilities/wrap-component';
import messages from './ReverifyEmailSentPage.messages';
import { Dispatch } from 'redux';
import { State as MainState } from '../reducers/index';
import WhiteboxPage from '../components/WhiteboxPage';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

interface PropsFromState {
  email: string;
}

interface PropsFromDispatch {
  addFlag: (flag: Flag) => void;
}

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps & InjectedAnalyticsProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export const ReverifyEmailSentPageComponent: React.FC<Props> = ({
  addFlag,
  analyticsClient,
  email,
  intl: { formatMessage },
  location,
}) => {
  const pageId = 'reverifyEmailSentScreen';

  usePageViewedEvent(pageId);

  const addSuccessFlag = () => {
    addFlag({
      type: 'success',
      title: messages.reverifyEmailFlagSuccessTitle,
      description: messages.reverifyEmailFlagSuccessDesc,
    });
  };

  const addErrorFlag = () => {
    addFlag({
      type: 'error',
      title: messages.reverifyEmailFlagErrorTitle,
      description: messages.reverifyEmailFlagErrorDesc,
    });
  };

  const handleResendVerificationEmail = () => {
    analyticsClient.buttonClickedEvent(pageId, 'resendReverificationEmail');
    return fetch(`/rest/resend-email${location.search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 204) {
          addSuccessFlag();
        } else {
          addErrorFlag();
        }
      })
      .catch(() => {
        addErrorFlag();
      });
  };

  const header = () => (
    <FormattedMessage
      id="reverify.email.title"
      tagName="h5"
      defaultMessage="Check your inbox to verify your email"
    />
  );

  const whiteboxFooter = () => {
    return (
      <Button
        id="send-validation-submit"
        appearance="link"
        spacing="none"
        className="link-color-override"
        onClick={handleResendVerificationEmail}>
        <FormattedMessage
          id="verify.email.resend.reverification.link"
          defaultMessage="Resend verification link"
        />
      </Button>
    );
  };

  return (
    <WhiteboxPage
      // @ts-ignore no id
      id="reverify-email-sent-page"
      pageId={pageId}
      header={header()}
      whiteboxFooter={whiteboxFooter()}>
      <Helmet title={formatMessage(messages.headTitle)} />
      <EmailSent
        email={email}
        message={
          <FormattedMessage
            id="reverify.email.sent.verification.link"
            tagName="p"
            defaultMessage="We sent a verification link to you at"
            description="Telling user that a verification link has been sent. This message is followed by the user's email address on the next line."
          />
        }
      />
    </WhiteboxPage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  addFlag(flag: Flag) {
    dispatch(addFlag(flag.type, flag.title, flag.description));
  },
});

const mapStateToProps = (state: MainState): PropsFromState => ({
  email: state.user.email,
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withRouter: true,
    withIntl: true,
    withAnalyticsClient: true,
  },
  ReverifyEmailSentPageComponent
);
