import React, { useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Blanket from '@atlaskit/blanket';
import Page from '@atlaskit/page';
import { borderRadius, colors, gridSize } from '@atlaskit/theme';

import { colorPalette } from '../utilities/color-palette';
import { Cobranding, CobrandingProps, withCobranding } from '../utilities/cobranding/cobranding';
import { getCobrandedComponents } from './whitebox-cobranding';
import { ConfluenceWireframe } from './whitebox-cobranding/confluence-experiment/ConfluenceWireframe';
import { JiraWireframe } from './whitebox-cobranding/jira-experiment/JiraWireframe';

import {
  linkStyles,
  listStyles,
  renderProductLogo,
  whiteboxWidthDesktop,
  whiteboxWidthHandheld,
} from './whitebox-cobranding/shared-cobranding';
import Spinner from '@atlaskit/spinner';
import { detectDestinationContent } from '../utilities/destination-utils';
import FooterComponent from './whitebox-cobranding/FooterComponent';

export interface Props {
  pageId: string;
  children?: React.ReactNode;
  header: React.ReactNode;
  whiteboxFooter?: React.ReactNode;
  footer?: React.ReactNode;
  noFooterMarginTop?: boolean;
  isLoading?: boolean;
  showLoginExperimentBackgroundStyles?: boolean;
  showLoginExperimentWhiteboxStyles?: boolean;
}

const maxMobileScreen = `( max-width: ${gridSize() * 100}px )`;

/*
 * Exported for tests only
 */
export const WhiteboxFooter = styled.div`
  color: ${colors.N200};
  margin-top: ${gridSize() * 3}px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;

  ${listStyles}
`;

export const WhiteboxFootnote = styled.div<{ showLoginExperimentWhiteboxStyles?: boolean }>`
  ${props =>
    css`
      color: ${colors.N200};
      padding-top: ${gridSize() * 2}px;
      margin: ${gridSize()}px ${gridSize()}px 0px;
      ${!props.showLoginExperimentWhiteboxStyles &&
      css`
        border-top: 1px solid #d8dce1;
        margin-top: ${gridSize() * 3}px;
      `}
      font-size: 11px;
      text-align: center;
      line-height: 14px;

      ${listStyles}
    `}
`;

/*
 * Exported for tests only
 */
export const WhiteboxHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: ${gridSize() * 2}px;
  color: ${colors.N800};
  line-height: 28px;
  font-size: 24px;
`;

const Whitebox = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${gridSize() * 3}px;
  width: ${whiteboxWidthDesktop};
  padding: ${gridSize() * 4}px ${gridSize() * 5}px;

  background: ${colorPalette.primaryWhite};
  border-radius: ${borderRadius()}px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${colors.N300};
  ${linkStyles(colors.B400)}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.N300};
  }

  @media ${maxMobileScreen} {
    padding: 0 ${gridSize()}px;
    background: transparent;
    box-shadow: none;
    width: ${whiteboxWidthHandheld};
  }
`;

const PageHeader = styled.header`
  text-align: center;
  padding: ${gridSize() * 5}px 0 ${gridSize() * 5}px;
  color: ${colorPalette.primaryWhite};

  @media ${maxMobileScreen} {
    padding: ${gridSize() * 3}px 0 !important;
    color: ${colors.N200};
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${gridSize() * 3}px;
`;

export const ExperimentCobrandedWhiteboxPage = ({
  header,
  children,
  footer,
  whiteboxFooter,
  cobranding,
  isLoading,
  isMobileApp,
  pageId,
  showLoginExperimentBackgroundStyles,
  showLoginExperimentWhiteboxStyles,
}: Props & CobrandingProps) => {
  let { background: Background, logo, footerStyles, footerProducts } = getCobrandedComponents(
    cobranding
  );
  if (showLoginExperimentBackgroundStyles) {
    if (cobranding === Cobranding.CONFLUENCE) {
      Background = ConfluenceWireframe;
    } else if (cobranding === Cobranding.JIRA) {
      Background = JiraWireframe;
    }
  }

  const details = detectDestinationContent();
  const [isSmallScreen, setIsSmallScreen] = useState(
    () => window.matchMedia(maxMobileScreen).matches
  );

  const renderPageHeader = () => {
    if (!('error' in details)) {
      const { content } = details;
      if (isSmallScreen && !!content) {
        return <PageHeader>{renderProductLogo(logo)}</PageHeader>;
      }
    }
    if (!showLoginExperimentBackgroundStyles) {
      return <PageHeader>{renderProductLogo(logo)}</PageHeader>;
    }
    return <PageHeader />;
  };

  useLayoutEffect(() => {
    const smallScreen = window.matchMedia(maxMobileScreen);
    const handleScreenSizeChange = (e: MediaQueryListEvent) => {
      setIsSmallScreen(e.matches);
    };

    if (smallScreen.addEventListener) {
      smallScreen.addEventListener('change', handleScreenSizeChange);
      return () => smallScreen.removeEventListener('change', handleScreenSizeChange);
    } else if (smallScreen.addListener) {
      smallScreen.addListener(handleScreenSizeChange);
      return () => smallScreen.removeListener(handleScreenSizeChange);
    }
  }, []);

  const maybeWrapWithBlanket = (children: React.ReactNode) =>
    !isSmallScreen && showLoginExperimentBackgroundStyles ? (
      <Blanket isTinted>{children}</Blanket>
    ) : (
      <>{children}</>
    );

  return (
    <Page>
      <Background />
      {maybeWrapWithBlanket(
        <Container>
          <div>
            {renderPageHeader()}
            <Whitebox role="main">
              {isLoading ? (
                <SpinnerContainer>
                  <Spinner size="large" />
                </SpinnerContainer>
              ) : (
                <>
                  <WhiteboxHeader>{header}</WhiteboxHeader>
                  {children}
                  {whiteboxFooter && <WhiteboxFooter>{whiteboxFooter}</WhiteboxFooter>}
                  {footer && (
                    <WhiteboxFootnote
                      showLoginExperimentWhiteboxStyles={showLoginExperimentWhiteboxStyles}>
                      {footer}
                    </WhiteboxFootnote>
                  )}
                </>
              )}
            </Whitebox>
          </div>
          {!showLoginExperimentBackgroundStyles && (
            <FooterComponent
              styles={{ ...footerStyles, footerMessageTextColor: colors.N300 }}
              renderText={!isMobileApp}
              pageId={pageId}
              footerProducts={footerProducts}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

export default withCobranding<Props>(ExperimentCobrandedWhiteboxPage);
