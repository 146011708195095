export default function submitFormHack({
  method,
  action = '',
  payload,
}: {
  method: string;
  action?: string;
  payload?: { [key: string]: any };
}) {
  const form = document.createElement('form');

  form.style.display = 'none';
  form.method = method;
  form.action = action;

  if (payload) {
    Object.entries(payload)
      .filter(([, value]) => value != null)
      .forEach(([name, value]) => {
        const input = document.createElement('input');
        input.name = name;
        input.value = String(value);
        form.appendChild(input);
      });
  }

  document.body.appendChild(form);
  form.submit();
}
