import React, { ReactNode } from 'react';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';
import { State as MainState } from '../reducers';
import ErrorPage from '../components/ErrorPage';
import CsrfTokenMismatchErrorPage from './CsrfTokenMismatchErrorPage';
import messages from './ServerErrorInterceptor.messages';

import { ServerError } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';

interface OwnProps {
  children: ReactNode;
  analyticsClient: AnalyticsClient;
}

interface PropsFromState {
  serverError: ServerError | null;
}

type Props = PropsFromState & OwnProps & InjectedIntlProps;

export const ServerErrorInterceptor: React.FC<Props> = ({
  intl,
  serverError,
  analyticsClient,
  children,
}) => {
  if (serverError) {
    if (serverError.type === 'not_found') {
      return <ErrorPage title={intl.formatMessage(messages.notFoundTitle)} />;
    } else if (serverError.type === 'internal_server_error') {
      return (
        <ErrorPage title={intl.formatMessage(messages.internalServerErrorTitle)}>
          <FormattedHTMLMessage {...messages.internalServerErrorDescription} />
        </ErrorPage>
      );
    } else if (serverError.type === 'bad_request') {
      return (
        <ErrorPage title={intl.formatMessage(messages.badRequestTitle)}>
          <FormattedHTMLMessage {...messages.badRequestDescription} />
        </ErrorPage>
      );
    } else if (serverError.type === 'csrf_token_mismatch') {
      return <CsrfTokenMismatchErrorPage analyticsClient={analyticsClient} />;
    } else {
      throw new Error(serverError.type || 'ServerError');
    }
  }

  return <>{children}</>;
};

function mapStateToProps(state: MainState) {
  return { serverError: state.serverError };
}

export default wrapComponent<PropsFromState, {}, OwnProps, InjectedIntlProps>(
  {
    mapStateToProps,
    withIntl: true,
  },
  ServerErrorInterceptor
);
