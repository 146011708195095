import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gridSize } from '@atlaskit/theme';

const FieldGroupStyled = styled.div<{ showLoginExperimentVariationStyles?: boolean }>`
  ${({ showLoginExperimentVariationStyles }) => {
    const marginTop = showLoginExperimentVariationStyles ? gridSize() : gridSize() * 2;
    return css`
      margin-top: ${marginTop}px;
    `;
  }}
  height: 39px;
  transform-origin: 50% 0;
  transition-property: visibility, height, margin-top, opacity, transform;
  transition-duration: 0s, 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out;

  &:first-child {
    margin-top: 0;
  }

  &.hidden {
    height: 0;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
  }
`;

export default function FieldGroup(props: {
  isHidden?: boolean;
  className?: string;
  showLoginExperimentVariationStyles?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <FieldGroupStyled
      {...props}
      className={`${props.className ? props.className : ''} ${props.isHidden ? 'hidden' : ''}`}
    />
  );
}
