import React, { Component } from 'react';
import styled from '@emotion/styled';
import makeAsyncScriptLoader from 'react-async-script';
import { colors } from '@atlaskit/theme';
import messages from './PasswordStrengthMeter.messages';

import { InjectedIntl } from 'react-intl';

const zxcvbnUrl = 'https://common-admin-cdn.atlassian.com/zxcvbn/da46e06/zxcvbn.js';
const splitter = /\W+/;
const barWidths = ['0%', '20%', '40%', '60%', '80%', '100%'];
const barColours = [colors.N40, colors.R500, colors.R300, colors.Y300, colors.G300, colors.G400];

// Extra words to be used as a dict of known words, which make passwords weaker
const extraWords = [
  'atlassian',
  'bitbucket',
  'confluence',
  'jira',
  'opsgenie',
  'statuspage',
  'trello',
];

interface PasswordStrengthMeterProps extends BarProps {
  title: string;
}

interface BarProps {
  score: number | null;
}

const Container = (props: PasswordStrengthMeterProps) => (
  <div>
    <Bar score={props.score}>
      <Dividers />
    </Bar>
    <Hint>{props.title}</Hint>
  </div>
);

const Bar = styled.div<BarProps>`
  background: ${colors.N40};
  height: 2px;
  margin-top: 5px;
  position: relative;

  &::before {
    background: ${props => (props.score === null ? colors.N40 : barColours[props.score])};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-out;
    width: ${props => (props.score === null ? 0 : barWidths[props.score])};
  }
`;

const Dividers = styled.span`
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  bottom: 0;
  left: 19%;
  position: absolute;
  right: 19%;
  top: 0;

  &::after {
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    bottom: 0;
    content: '';
    left: 30%;
    position: absolute;
    right: 30%;
    top: 0;
  }
`;

const Hint = styled.p`
  color: ${colors.N90};
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 4px;
  position: relative;
  text-align: center;
`;

type ZxcvbnScoreRange = 0 | 1 | 2 | 3 | 4 | 5;

interface ZxcvbnResult {
  calc_time: number;
  crack_time: number;
  crack_time_display: string;
  entropy: number;
  match_sequence: Object[];
  password: string;
  score: ZxcvbnScoreRange;
}

type Zxcvbn = (password: string, userInputs?: string[]) => ZxcvbnResult;

interface Props {
  i18nLevels?: string[];
  i18nNoValue?: string;
  i18nNoZxcvbn?: string;
  intl: InjectedIntl;
  scoreWords: string[];
  password: string;
  zxcvbn?: Zxcvbn;
}

interface State {
  i18nLevels: string[];
  i18nNoValue: string;
  i18nNoZxcvbn: string;
}

export class PasswordStrengthMeter extends Component<Props, State> {
  static defaultProps = {
    scoreWords: [],
  };

  constructor(props: Props) {
    super(props);

    const { formatMessage } = props.intl;

    this.state = {
      i18nLevels:
        props.i18nLevels ||
        [
          messages.passwordStrengthMinimumRequirement,
          messages.passwordStrengthLevel1,
          messages.passwordStrengthLevel2,
          messages.passwordStrengthLevel3,
          messages.passwordStrengthLevel4,
          messages.passwordStrengthLevel5,
        ].map(message => formatMessage(message)),
      i18nNoValue: props.i18nNoValue || '',
      i18nNoZxcvbn: props.i18nNoZxcvbn || formatMessage(messages.passwordStrengthUnavailable),
    };
  }

  getResult = (props: Props): ZxcvbnResult | null => {
    return props.zxcvbn && props.password
      ? props.zxcvbn(props.password, [...props.scoreWords, ...extraWords])
      : null;
  };

  getScore = (props: Props): ZxcvbnScoreRange | null => {
    if (this.props.password.length < 8) {
      return 0;
    } else {
      const result = this.getResult(props);
      const score = result ? result.score : null;
      // @ts-ignore already checks that score of a number
      return typeof score === 'number' ? score + 1 : null;
    }
  };

  getStrengthText = () => {
    if (!this.props.password) {
      return this.state.i18nNoValue;
    } else {
      const score = this.getScore(this.props);

      return typeof score === 'number' ? this.state.i18nLevels[score] : this.state.i18nNoZxcvbn;
    }
  };

  render() {
    return <Container title={this.getStrengthText()} score={this.getScore(this.props)} />;
  }
}

export const AsyncLoadedPasswordStrengthMeter = makeAsyncScriptLoader(zxcvbnUrl, {
  globalName: 'zxcvbn',
})(PasswordStrengthMeter);

const addUnique = (seen: string[], chunk: string) =>
  seen.includes(chunk) ? seen : [...seen, chunk];

export function scoreWordSplitter(strings: string[]) {
  const deduped = strings.reduce(addUnique, []);
  return deduped.reduce((arr, str) => str.split(splitter).reduce(addUnique, arr), deduped);
}
