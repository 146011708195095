import statuspageImageLeft from '../../../images/cobranding/statuspage/statuspage-left.svg';
import statuspageImageRight from '../../../images/cobranding/statuspage/statuspage-right.svg';
import { colors } from '@atlaskit/theme';
import { StatuspageLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  blueLogo,
  CobrandingComponents,
  CobrandingLogoStyles,
} from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

const logoStyles: CobrandingLogoStyles = { ...blueLogo, textColor: colors.N800 };
export const StatuspageBackground = backgroundWithSideImages(
  statuspageImageLeft,
  statuspageImageRight
);

const StatuspageCobranding: CobrandingComponents = {
  background: StatuspageBackground,
  logo: {
    component: StatuspageLogo,
    desktopStyles: logoStyles,
    mobileStyles: logoStyles,
  },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Statuspage, Jira, Confluence',
};

export default StatuspageCobranding;
