import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { Microbranding } from '../../reducers/microbranding-reducer';
import { currentEnv } from '../env';
import { FeatureFlags } from '../feature-flags';
import { isMobileOidc } from '../oidc/oidc';
import { getAnonymousId } from './segment-io';

Sentry.init({
  dsn: 'https://71e54c28be0d49f0bcd732ab30f35faa@api.atlassian.com/sentry-cloud/275199', // same for all envs
  debug: currentEnv !== 'prod',
  environment: currentEnv,
  sampleRate: 0.1,
  // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
  // https://docs.sentry.io/workflow/releases/?platform=browser#create-deploy
  release: process.env.REACT_APP_VERSION,
});

export function initClient(microbranding: Microbranding, featureFlags: FeatureFlags) {
  const userId = getAnonymousId();

  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });

    // We're not going to send all of microbranding just in case someone
    // puts PII into microbranding later on
    scope.setExtra('isEmbedded', microbranding.isEmbedded);
    scope.setExtra('isMobileOidc', isMobileOidc(microbranding.oidcContext));

    const extra = { ...featureFlags };

    Object.keys(extra).forEach(key => {
      scope.setExtra(key, extra[key]);
    });
  });
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  if (!breadcrumb.level) {
    breadcrumb.level = Severity.Info;
  }
  Sentry.addBreadcrumb(breadcrumb);
}

export function setLocale(locale: string | undefined) {
  if (locale) {
    Sentry.configureScope(scope => {
      scope.setTag('locale', locale);
    });
  }
}

export function captureException(ex: Error, payload?: Object) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.keys(payload).forEach(key => {
        scope.setExtra(key, payload[key]);
      });

      Sentry.captureException(ex);
    });
  } else {
    Sentry.captureException(ex);
  }
}

export function captureErrorMessage(msg: string, payload?: any) {
  captureMessage(msg, payload, Severity.Error);
}

export function captureMessage(msg: string, payload: any, severity: Severity) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.keys(payload).forEach(key => {
        scope.setExtra(key, payload[key]);
      });

      Sentry.captureMessage(msg, severity);
    });
  } else {
    Sentry.captureMessage(msg, severity);
  }
}
