import { defineMessages } from 'react-intl';

export default defineMessages({
  unexpectedErrorHappened: {
    id: 'login.form.sso.check.unknown.error',
    defaultMessage: 'Unexpected error happened.',
    description: '',
  },
  emailPlaceholder: {
    id: 'login.form.email.placeholder',
    defaultMessage: 'Enter your email',
  },
  emailEmpty: {
    id: 'login.form.email.empty',
    defaultMessage: 'Enter an email address',
  },
  passwordEmpty: {
    id: 'login.form.password.empty',
    defaultMessage: 'Enter your password',
  },
  migrateAccountInfo: {
    id: 'login.form.info.migrate.account',
    defaultMessage:
      'It looks like you already have an Atlassian account with other products such as Jira or Confluence.' +
      ' Log in to finish migrating your {application} account.',
    description: 'Login form info container message',
  },
  existingUserSignupAttemptInfo: {
    id: 'login.form.info.existing.user.signup.attempt',
    defaultMessage:
      "It looks like you've already got an account associated with this email. Log in instead or reset your password if you've forgotten it",
    description: 'Login form info container message for existing user signup attempt',
  },
  rateLimitErrorMessage: {
    id: 'login.form.email.rate-limit-exceeded.error',
    defaultMessage:
      'There have been too many attempts to reverify your account in the past hour. Please try again later.',
    description: 'Telling the user that they have exceeded welcome email limit',
  },
});
