import React, { useState } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { State as MainState } from '../reducers/index';

import AccountVerificationForm from '../components/AccountVerificationForm';

import { Dispatch, EmailConfirmationError, InjectedRouterProps } from '../types';
import { wrapComponent } from '../utilities/wrap-component';
import WhiteboxPage from '../components/WhiteboxPage';
import { ApplicationData, lookupApplication } from '../utilities/applications';
import { changeEmailConfirmation } from '../actions/change-email-actions';
import {
  useButtonClickedEvent,
  usePageViewedEvent,
} from '../utilities/analytics/analytics-web-client';
import messages from './EmailConfirmationPage.messages';
import { Link } from 'react-router-dom';

interface OwnProps {}

type InjectedProps = InjectedIntlProps & InjectedRouterProps;

interface PropsFromDispatch {
  confirmChangeEmail: (newEmail: string, password: string) => void;
}

interface PropsFromState {
  isEmbedded: boolean;
  applicationData: ApplicationData | null;
  email: string;
  newEmail: string;
  error: EmailConfirmationError | null;
}

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

const pageId = 'EmailConfirmationPage';

export const EmailConfirmationFormPage: React.ComponentType<Props> = ({
  email,
  newEmail,
  error,
  isEmbedded,
  confirmChangeEmail,
  intl,
}) => {
  const [fieldErrors, setFieldErrors] = useState(
    error === EmailConfirmationError.InvalidPassword
      ? { password: intl.formatMessage(messages.incorrectPassword) }
      : {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleLoginClick = useButtonClickedEvent(pageId, 'EmailConfirmationSubmit');

  usePageViewedEvent(pageId);

  const handleLogIn = (newEmail: string, password: string) => {
    setIsLoading(true);
    confirmChangeEmail(newEmail, password);
  };

  const resetPasswordLink =
    '/login/resetpassword?' +
    new URLSearchParams([
      ['redirectedFrom', 'change-email'],
      ['email', email],
      ['continue', window.location.href],
    ]);

  return (
    <WhiteboxPage
      pageId={pageId}
      header={<FormattedMessage tagName="h5" {...messages.title} />}
      whiteboxFooter={
        <Link to={resetPasswordLink} id="resetPassword">
          <FormattedMessage
            id="having.trouble.logging.in"
            defaultMessage="Having trouble logging in?"
          />
        </Link>
      }>
      <AccountVerificationForm
        email={newEmail}
        isEmbedded={isEmbedded}
        isLoading={isLoading}
        showEmailAsInput={false}
        fieldErrors={fieldErrors}
        onValidationError={setFieldErrors}
        submitButtonText={<FormattedMessage id="common.confirm" defaultMessage="Confirm" />}
        onSubmitClick={handleLoginClick}
        onSubmit={handleLogIn}
      />
    </WhiteboxPage>
  );
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  isEmbedded: Boolean(state.microbranding.isEmbedded),
  applicationData: lookupApplication(state.cobranding),
  email: state.changeEmail.email,
  newEmail: state.changeEmail.newEmail,
  error: state.changeEmail.error,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    confirmChangeEmail(newEmail: string, password: string) {
      dispatch(changeEmailConfirmation(newEmail, password));
    },
  };
};

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withIntl: true,
  },
  EmailConfirmationFormPage
);
