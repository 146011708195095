import {
  Action,
  MultiFactorOTPError,
  MultiFactorRecoveryError,
  ThunkAction,
  MultifactorMethod,
} from '../types';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { idacOrigin } from '../utilities/env';

export const guardianEnrollmentDetailsRequest = (): Action => ({
  type: 'GUARDIAN_ENROLLMENT_DETAILS_REQUEST',
});

export const guardianEnrollmentDetailsResponse = (enrollment: {
  method: MultifactorMethod;
  phoneNumber?: string;
}): Action => ({
  type: 'GUARDIAN_ENROLLMENT_DETAILS_RESPONSE',
  method: enrollment.method,
  phoneNumber: enrollment.phoneNumber,
});

export const confirmedRecoveryCode = (
  params: string,
  analyticsClient: AnalyticsClient,
  signature?: string
): Action => ({
  type: 'GUARDIAN_CONFIRMED_RECOVER',
  params,
  analyticsClient,
  signature,
});

export const verifyOtpCode = (
  params: string,
  otpCode: string,
  analyticsClient: AnalyticsClient
): Action => ({
  type: 'GUARDIAN_VERIFY',
  params,
  otpCode,
  analyticsClient,
});

export const resendSms = (params: string, analyticsClient: AnalyticsClient): Action => ({
  type: 'GUARDIAN_RESEND_SMS',
  params,
  analyticsClient,
});

export const recoverAccount = (
  params: string,
  recoveryCode: string,
  analyticsClient: AnalyticsClient
): Action => ({
  type: 'GUARDIAN_RECOVER',
  params,
  recoveryCode,
  analyticsClient,
});

export const guardianVerifyResponse = ({ signature }: { signature: string }): Action => ({
  type: 'GUARDIAN_VERIFY_RESPONSE',
  signature,
});

export const guardianRecoverResponse = ({
  signature,
  recoveryCode,
}: {
  signature: string;
  recoveryCode: string;
}): Action => ({
  type: 'GUARDIAN_RECOVER_RESPONSE',
  signature,
  recoveryCode,
});

export const guardianVerifyError = ({ errorCode, message }: MultiFactorOTPError): Action => ({
  type: 'GUARDIAN_VERIFY_ERROR',
  errorCode,
  message,
});

export const guardianRecoverError = ({ errorCode, message }: MultiFactorRecoveryError): Action => ({
  type: 'GUARDIAN_RECOVER_ERROR',
  errorCode,
  message,
});

export const recoveryEmailSent = (): Action => ({
  type: 'RECOVERY_EMAIL_SENT',
});

export const recoveryEmailStart = (): Action => ({
  type: 'RECOVERY_EMAIL_START',
});

export const recoveryEmailError = (): Action => ({
  type: 'RECOVERY_EMAIL_ERROR',
});

export const sendRecoveryEmail = (email: string): ThunkAction => dispatch => {
  dispatch(recoveryEmailStart());
  return fetch(`${idacOrigin}/rest/tsv-recovery/trigger`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then(response => {
      if (response.ok) {
        dispatch(recoveryEmailSent());
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      dispatch(recoveryEmailError());
    });
};

export const userManagedStatus = (isManaged: boolean): Action => ({
  type: 'USER_MANAGED_STATUS',
  isManaged,
});

export const getUserManagedStatus = (): ThunkAction => (dispatch, getState) => {
  return fetch(
    `${idacOrigin}/rest/tsv-recovery/userManagedStatus?email=${encodeURIComponent(
      getState().multiFactor.email
    )}`
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error();
    })
    .then(({ isManaged }) => {
      dispatch(userManagedStatus(isManaged));
    })
    .catch(() => {
      // if some error happens during fetching user managed status there is nothing we could really handle on client side,
      // but lets assume that user is managed, because it won't allow managed user to go through tsv recovery flow
      dispatch(userManagedStatus(true));
    });
};
