import { defineMessages } from 'react-intl';

const messages = defineMessages({
  authCallbackTitle: {
    id: 'authcallback.title',
    defaultMessage: "Hmm... We're having trouble logging you in.",
    description:
      'The title for a page that appears when a user experences an error while logging in',
  },
  authCallbackDefaultContent: {
    id: 'authcallback.default.content',
    defaultMessage:
      "We're having trouble logging you in. There seems to be an issue with your identity provider. Wait a few moments, then try again.",
  },
  authCallbackRetryButton: {
    id: 'authCallback.retry-button.content',
    defaultMessage: 'Try logging in again',
  },
  authCallbackUserIsBlocked: {
    id: 'authcallback.user-is-blocked.content',
    defaultMessage: 'This account has been deactivated. Please contact your organization admin.',
  },
  authCallbackVerifySamlMalformedEmail: {
    id: 'authcallback.forbidden.verify-saml-domains.malformed-email-address.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. Try checking your email address with your organization admin.",
  },
  authCallbackVerifySamlInvalidEmail: {
    id: 'authcallback.verify-saml-domains.invalid-email-domain.content',
    defaultMessage:
      "Whoops! The email address you entered can't be used to log in here. Try logging in from id.atlassian.com, or check your email address with your organization admin.",
  },
  authCallbackVerifySamlInvalidIssuer: {
    id: 'authcallback.verify-saml-domains.invalid-issuer.content',
    defaultMessage:
      "You'll need to talk to your organization admin - tell them we sent you, and that there appears to be an issue with the identity provider entity ID used for your SAML single sign-on configuration.",
  },
  authCallbackInvalidAudience: {
    id: 'authcallback.invalid-audience.content',
    defaultMessage:
      "You'll need to talk to your organization admin - tell them we sent you, and that there appears to be an issue with the destination URL used for your SAML single sign-on configuration.",
  },
  authCallbackInvalidThumbprint: {
    id: 'authcallback.invalid-thumbprint.content',
    defaultMessage:
      "You'll need to talk to your organization admin - tell them we sent you, and that there appears to be an issue with the SAML public key used for your SAML single sign-on configuration.",
  },
  authCallbackVerifySamlEmailInvalidSpace: {
    id: 'authcallback.verify-saml-domains.email-domain-space-prefix-or-suffix.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. The email domain should not start or end with spaces. Try checking your email address with your organization admin.",
  },
  authCallbackVerifySamlEmailInvalidEmpty: {
    id: 'authcallback.verify-saml-domains.email-domain-label-length-zero.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. The email domain should not be empty. Try checking your email address with your organization admin.",
  },
  authCallbackVerifySamlEmailInvalidDash: {
    id: 'authcallback.verify-saml-domains.email-domain-label-dash.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. The email domain should not start or end with a hyphen character. Try checking your email address with your organization admin.",
  },
  authCallbackVerifySamlEmailInvalidLong: {
    id: 'authcallback.verify-saml-domains.email-domain-encoded-too-long.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. The maximum length of an email domain name is 255 characters. If your email address is internationalized, the length limit applies to the ASCII form of the address. Try checking your email address with your organization admin.",
  },
  authCallbackVerifySamlEmailInvalidLabel: {
    id: 'authcallback.verify-saml-domains.email-domain-encoded-invalid-label.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. Each email domain label should only contain letters, numbers and hyphens, and have fewer than 64 characters. Validation rules apply to the ASCII form of an internationalized address. Try checking your email address with your organization admin.",
  },
  authCallbackTitleVerifySamlInvalidEmailLabelAmount: {
    id: 'authcallback.verify-saml-domains.email-domain-encoded-label-amount.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. A valid email domain name should have at least two parts separated by a '.' character. Try checking your email address with your organization admin.",
  },
  authCallbackTitleVerifySamlInvalidEmailCharacters: {
    id: 'authcallback.verify-saml-domains.email-local-invalid-characters.content',
    defaultMessage:
      "Whoops! The address you entered doesn't seem to be a valid email address. A valid email address should only contain standard ASCII characters before the @ character. Try checking your email address with your organization admin.",
  },
  authCallbackMicrosoftNotVerified: {
    id: 'authcallback.block-microsoft.microsoft-email-not-verified.content',
    defaultMessage:
      '<p>Sorry, we had trouble logging you in with your Microsoft account. Your specific Azure Active Directory configuration is not currently supported. We are working on it!</p><p>For now, please enter your email in the login form and click continue and we will direct you to the most appropriate login method.</p>',
  },
  authCallbackMicrosoftDisabled: {
    id: 'authcallback.block-microsoft.microsoft-login-disabled.content',
    defaultMessage:
      '<p>Sorry, it is not possible to log in with Microsoft at this time. We are working on it.</p>',
  },
  authCallbackWrongVerificationCode: {
    id: 'authcallback.redirect.wrong-email-verification-code.content',
    defaultMessage:
      "<p>Whoops! The verification code you entered doesn't seem to be right. Please try again.</p>",
  },
  authCallbackAppleDisabled: {
    id: 'authcallback.block-apple.apple-login-disabled.content',
    defaultMessage:
      '<p>Sorry, it is not possible to log in with Apple at this time. We are working on it.</p>',
  },
  authCallbackSlackDisabled: {
    id: 'authcallback.block-slack.slack-login-disabled.content',
    defaultMessage:
      '<p>Sorry, it is not possible to log in with Slack at this time. We are working on it.</p>',
  },
  authCallbackRelayStateInvalid: {
    id: 'authcallback.invalid-relaystate.content',
    defaultMessage:
      "You'll need to talk to your organization admin - tell them we sent you, and that there appears to be an issue with the relay state for your SAML single sign-on configuration.",
  },
  authCallbackSamlRequired: {
    id: 'authcallback.must-use-saml.content',
    defaultMessage:
      'Your organization admin requires you to use single sign-on to login to your Atlassian account.',
  },
  authCallbackGoogleRequired: {
    id: 'authcallback.must-use-google.content',
    defaultMessage:
      'Your organization admin requires you to use Google to login to your Atlassian account.',
  },
  authCallbackMicrosoftRequired: {
    id: 'authcallback.must-use-microsoft.content',
    defaultMessage:
      'Your organization admin requires you to use Microsoft to login to your Atlassian account.',
  },
  authCallbackSamlNotEnabled: {
    id: 'authcallback.cannot-use-saml.content',
    defaultMessage:
      '<p>Single sign-on is no longer available for your account. If you think it should be available, contact your organization admin. <a href="https://confluence.atlassian.com/cloud/troubleshoot-your-account-1026059932.html#Troubleshootyouraccount-Unabletologinwithsinglesign-on">Learn more</a></p><p>In the meantime, use an Atlassian account password to log in. If you don\'t have a password, use the "Can\'t log in?" link to set one when logging in.</p>',
  },
  authCallbackAuthenticationPolicyMismatch: {
    id: 'authcallback.authentication-policy-strategy-mismatch.content',
    defaultMessage: 'Please try again with a different authentication method.',
  },
  authCallbackHideEmailBypassesPolicy: {
    id: 'authcallback.check-auth-policies.hide-email-change-bypasses-policy.content',
    defaultMessage:
      'Sorry, you cannot hide your email once you are part of an organization. Please ensure that you share your real email.',
  },
  authCallbackChangeEmailBypassesPolicy: {
    id: 'authcallback.check-auth-policies.email-change-bypasses-policy.content',
    defaultMessage:
      "Sorry, we detected an email change that is not supported by your organization's authentication policy. Please log in with a email address that matches your account.",
  },
  authCallbackMicrosoftConfiguration: {
    id: 'authcallback.microsoft-domain-configuration.content',
    defaultMessage:
      'Your Microsoft email domain is not correctly configured. Please contact your Microsoft administrator.',
  },
  authCallbackConsentRequired: {
    id: 'authcallback.consent-required.content',
    defaultMessage:
      'Please grant access to your account details if you wish to login to your Atlassian account.',
  },
  authCallbackMissingEmail: {
    id: 'authcallback.missing-email.content',
    defaultMessage:
      '<p>We did not receive a valid email address from your identity provider.</p><p>Please try logging in again by typing your email address. If you don\'t have a password, use the "Can\'t log in?" link to set one when logging in.</p>',
  },
  authCallbackBlockedCookies: {
    id: 'authcallback.blocked-cookies.content',
    defaultMessage:
      '<p class="errorListTitle">Before trying again:</p><ul class="errorList"><li>Check your browser settings to make sure <a href="https://confluence.atlassian.com/cloud/troubleshoot-your-account-1026059932.html#Troubleshootyouraccount-Issuesrelatingtocookies">cookies are enabled</a>.</li><li>Disable any browser extensions that may interfere with cookies.</li><li>Check your computer settings to make sure the time and date are synced with the Internet.</li></ul>',
  },
  authCallbackEmailChangeNotAllowedByUserManagedStatus: {
    id: 'authcallback.email.change.not.allowed.by.user.managed.status',
    defaultMessage:
      'We detected an email change that would remove you from your organization. Contact your organization admin to check your account is configured correctly or try to log in a different way.',
  },
  setCstInvalidOrMissingCsrfToken: {
    id: 'setcst.invalid-or-missing-csrf-token.content',
    defaultMessage:
      'Something went wrong when we tried to log you in. If this issue persists, please contact <a href="https://support.atlassian.com/">Atlassian Support</a>.',
  },
});

export const auth0ErrorToKeyMapping = [
  {
    content: messages.authCallbackUserIsBlocked,
    regex: /^user is blocked/,
  },
  {
    content: messages.authCallbackVerifySamlMalformedEmail,
    regex: /^verify-saml-domains:malformed-email-address/,
  },
  {
    content: messages.authCallbackVerifySamlInvalidEmail,
    regex: /^verify-saml-domains:invalid-email-domain/,
  },
  {
    content: messages.authCallbackVerifySamlInvalidIssuer,
    regex: /^verify-saml-domains:invalid-issuer/,
  },
  {
    content: messages.authCallbackInvalidAudience,
    regex: /^Audience is invalid. Configured:/,
  },
  {
    content: messages.authCallbackInvalidThumbprint,
    regex: /^Invalid thumbprint \(configured:/,
  },
  {
    content: messages.authCallbackVerifySamlEmailInvalidSpace,
    regex: /^verify-saml-domains:email-domain-space-prefix-or-suffix/,
  },
  {
    content: messages.authCallbackVerifySamlEmailInvalidEmpty,
    regex: /^verify-saml-domains:email-domain-label-length-zero/,
  },
  {
    content: messages.authCallbackVerifySamlEmailInvalidDash,
    regex: /^verify-saml-domains:email-domain-label-dash/,
  },
  {
    content: messages.authCallbackVerifySamlEmailInvalidLong,
    regex: /^verify-saml-domains:email-domain-encoded-too-long/,
  },
  {
    content: messages.authCallbackVerifySamlEmailInvalidLabel,
    regex: /^verify-saml-domains:email-domain-encoded-invalid-label/,
  },
  {
    content: messages.authCallbackTitleVerifySamlInvalidEmailLabelAmount,
    regex: /^verify-saml-domains:email-domain-encoded-label-amount/,
  },
  {
    content: messages.authCallbackTitleVerifySamlInvalidEmailCharacters,
    regex: /^verify-saml-domains:email-local-invalid-characters/,
  },
  {
    content: messages.authCallbackMicrosoftNotVerified,
    regex: /^block-microsoft:microsoft-email-not-verified/,
  },
  {
    content: messages.authCallbackRelayStateInvalid,
    regex: /^invalid-relaystate/,
  },
  {
    content: messages.authCallbackSamlRequired,
    regex: /^authentication-policy-strategy-mismatch.*policyConnection=saml-.*/,
  },
  {
    content: messages.authCallbackGoogleRequired,
    regex: /^authentication-policy-strategy-mismatch.*policyConnection=google-oauth2.*/,
  },
  {
    content: messages.authCallbackMicrosoftRequired,
    regex: /^authentication-policy-strategy-mismatch.*policyConnection=microsoft.*/,
  },
  {
    content: messages.authCallbackSamlNotEnabled,
    regex: /^authentication-policy-strategy-mismatch.*currentConnection=saml-.*/,
  },
  {
    content: messages.authCallbackAuthenticationPolicyMismatch,
    regex: /^authentication-policy-strategy-mismatch/,
  },
  {
    content: messages.authCallbackMicrosoftConfiguration,
    regex: /^AADSTS650051.*/,
  },
  {
    content: messages.authCallbackConsentRequired,
    regex: /^The user has denied access to the scope requested by the client.*/,
  },
  {
    content: messages.authCallbackMissingEmail,
    regex: /^check-user-properties:missing-email/,
  },
  {
    content: messages.authCallbackHideEmailBypassesPolicy,
    regex: /^check-auth-policies:hide-email-change-bypasses-policy/,
  },
  {
    content: messages.authCallbackChangeEmailBypassesPolicy,
    regex: /^check-auth-policies:email-change-bypasses-policy/,
  },
  {
    content: messages.authCallbackMicrosoftDisabled,
    regex: /^block-microsoft:microsoft-login-disabled/,
  },
  {
    content: messages.authCallbackAppleDisabled,
    regex: /^block-apple:apple-login-disabled/,
  },
  {
    content: messages.authCallbackSlackDisabled,
    regex: /^block-slack:slack-login-disabled/,
  },
  {
    content: messages.authCallbackWrongVerificationCode,
    regex: /^redirect:wrong-email-verification-code/,
  },
  {
    content: messages.authCallbackBlockedCookies,
    regex: /^Unable to configure verification page./,
  },
  {
    content: messages.authCallbackEmailChangeNotAllowedByUserManagedStatus,
    regex: /^sync-ext-idp-user-profile:email-change-disallowed/,
  },
  {
    content: messages.setCstInvalidOrMissingCsrfToken,
    regex: /^verify-csrf:invalid-or-missing-csrf-token/,
  },
];

export default messages;
