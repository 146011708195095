import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordStrengthMinimumRequirement: {
    id: 'password.strength.level.minimum',
    defaultMessage: 'Password must have at least 8 characters',
  },
  passwordStrengthLevel1: {
    id: 'password.strength.level.1',
    defaultMessage: 'Weak',
  },
  passwordStrengthLevel2: {
    id: 'password.strength.level.2',
    defaultMessage: 'Fair',
  },
  passwordStrengthLevel3: {
    id: 'password.strength.level.3',
    defaultMessage: 'Good',
  },
  passwordStrengthLevel4: {
    id: 'password.strength.level.4',
    defaultMessage: 'Strong',
  },
  passwordStrengthLevel5: {
    id: 'password.strength.level.5',
    defaultMessage: 'Very strong',
  },
  passwordStrengthUnavailable: {
    id: 'password.strength.unavailable',
    defaultMessage: 'Password strength unavailable',
  },
});
