import React from 'react';
import styled from '@emotion/styled';
import Flag, { FlagGroup } from '@atlaskit/flag';
import { colors, gridSize } from '@atlaskit/theme';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { InjectedIntlProps } from 'react-intl';
import { Flag as FlagType, Dispatch } from '../types';
import { dismissFlag } from '../actions/flag-actions';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers';

// We need to wrap the flag in a div to apply responsive styles so
// the flag are always visible on smaller viewports.
const ResponsiveFlagWrapper = styled.div`
  [role='alert'] {
    max-width: calc(100vw - ${gridSize() * 11}px);
  }
`;

interface OwnProps {}

interface PropsFromState {
  flags: FlagType[];
}

interface PropsFromDispatch {
  dismissFlag: () => void;
}

type InjectedProps = InjectedIntlProps;

type Props = PropsFromState & PropsFromDispatch & OwnProps & InjectedProps;

export const FlagComponent = (props: Props) => {
  const { formatMessage } = props.intl;

  const toFlagComponent = (flag: FlagType) => {
    const icon =
      flag.type === 'success' ? (
        <SuccessIcon label="Success" primaryColor={colors.G300} />
      ) : (
        <ErrorIcon label="Error" primaryColor={colors.R300} />
      );
    return (
      <ResponsiveFlagWrapper id={flag.id} key={flag.id}>
        <Flag
          id={flag.id || ''}
          title={formatMessage(flag.title)}
          description={formatMessage(flag.description)}
          icon={icon}
          onDismissed={props.dismissFlag}
        />
      </ResponsiveFlagWrapper>
    );
  };

  return <FlagGroup children={props.flags.map(toFlagComponent)} />;
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  flags: state.flags.flags,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  dismissFlag() {
    dispatch(dismissFlag());
  },
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    mapDispatchToProps,
    withIntl: true,
  },
  FlagComponent
);
