import React from 'react';
import { wrapComponent } from '../utilities/wrap-component';
import { State as MainState } from '../reducers/index';
import { VerificationType, VerifyOrReverifyEmailError } from '../types';
import VerifyOrReverifyEmailExpiredTokenErrorPage from './VerifyOrReverifyEmailExpiredTokenErrorPage';
import VerifyOrReverifyEmailRateLimitExceededErrorPage from './VerifyOrReverifyEmailRateLimitExceededErrorPage';
import VerifyOrReverifyEmailInvalidTokenErrorPage from './VerifyOrReverifyEmailInvalidTokenErrorPage';

interface OwnProps {}

type InjectedProps = {};

interface PropsFromDispatch {}

interface PropsFromState {
  error: VerifyOrReverifyEmailError;
  recoverUrl: string;
  verificationType: VerificationType;
}

type Props = PropsFromState & OwnProps & InjectedProps;

export const VerifyOrReverifyEmailErrorPage: React.FC<Props> = ({
  error,
  recoverUrl,
  verificationType,
}) => {
  switch (error) {
    case VerifyOrReverifyEmailError.ExpiredToken:
      return (
        <VerifyOrReverifyEmailExpiredTokenErrorPage
          recoverUrl={recoverUrl}
          verificationType={verificationType}
        />
      );
    case VerifyOrReverifyEmailError.InvalidToken:
      return (
        <VerifyOrReverifyEmailInvalidTokenErrorPage
          recoverUrl={recoverUrl}
          verificationType={verificationType}
        />
      );
    case VerifyOrReverifyEmailError.RateLimitExceeded:
      return (
        <VerifyOrReverifyEmailRateLimitExceededErrorPage
          recoverUrl={recoverUrl}
          verificationType={verificationType}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  error: state.verifyEmail.error,
  recoverUrl: state.verifyEmail.url,
  verificationType: state.verifyEmail.verificationType,
});

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
  },
  VerifyOrReverifyEmailErrorPage
);
