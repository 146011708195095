import styled from '@emotion/styled';
import { media } from '../../utilities/style-utils';
import {
  linkStyles,
  listStyles,
  whiteboxWidthDesktop,
  whiteboxWidthHandheld,
} from './shared-cobranding';
import { colors, borderRadius, gridSize } from '@atlaskit/theme';

export interface FootnoteProps {
  color: string;
  linkColor: string;
}

export const FootnoteComponent = styled.div<FootnoteProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${gridSize() * 3}px;
  font-size: 12px;
  width: ${whiteboxWidthDesktop};
  text-align: center;
  align-items: center;
  border-radius: ${borderRadius()}px;
  
  color: ${({ color }) => color};
  ${({ linkColor }) => linkStyles(linkColor)}
  ${listStyles}
  
  ${media.handheld`
    padding: 0 ${gridSize()}px;
    background: transparent;
    width: ${whiteboxWidthHandheld};
    color: ${colors.N200};
    ${linkStyles(colors.B400)}
  `}
`;
