import { defineMessages } from 'react-intl';

export default defineMessages({
  mfFormPlaceholderNDigits: {
    id: 'mf.mfform.placeholder.ndigits',
    defaultMessage: '{codeLength}-digit verification code',
    description: '',
  },
  errorsInvalidOtp: {
    id: 'mf.mfform.errors.invalid_otp',
    defaultMessage: 'You entered an incorrect verification code.',
  },
  errorsInvalidOtpFormat: {
    id: 'mf.mfform.errors.invalid_otp_format',
    defaultMessage: 'You entered an invalid verification code.',
  },
  errorsInvalidOtpPattern: {
    id: 'mf.mfform.errors.invalid_otp_pattern',
    defaultMessage: 'Your verification code needs to be 6 digits.',
  },
  errorsExpiredOtp: {
    id: 'mf.mfform.errors.expired_otp',
    defaultMessage: 'Your verification code has expired.',
  },
  errorsUnknown: {
    id: 'mf.mfform.errors.unknown',
    defaultMessage: 'Unknown error occurred. Please try again.',
  },
});
