import opsgenieImageLeft from '../../../images/cobranding/opsgenie/opsgenie-left.svg';
import opsgenieImageRight from '../../../images/cobranding/opsgenie/opsgenie-right.svg';
import { colors } from '@atlaskit/theme';
import { OpsGenieLogo } from '@atlaskit/logo';
import {
  backgroundWithSideImages,
  blueLogo,
  CobrandingComponents,
  CobrandingLogoStyles,
} from '../shared-cobranding';
import { blueFooter } from '../FooterComponent';

const logoStyles: CobrandingLogoStyles = { ...blueLogo, textColor: colors.N800 };
export const OpsgenieBackground = backgroundWithSideImages(opsgenieImageLeft, opsgenieImageRight);

const OpsgenieCobranding: CobrandingComponents = {
  background: OpsgenieBackground,
  logo: {
    component: OpsGenieLogo,
    desktopStyles: logoStyles,
    mobileStyles: logoStyles,
  },
  footnoteStyles: { color: colors.N200, linkColor: colors.B400 },
  footerStyles: blueFooter,
  footerProducts: 'Opsgenie, Jira, Confluence',
};

export default OpsgenieCobranding;
