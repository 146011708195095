import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import errorImage from '../images/Error.svg';
import WhiteboxPage from '../components/WhiteboxPage';
import styled from '@emotion/styled';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

export const pageId = 'welcomeSentInvalidTokenErrorPage';

export const WelcomeSentInvalidTokenErrorPage: React.FC = () => {
  usePageViewedEvent(pageId);

  return (
    <WhiteboxPage
      pageId={pageId}
      header={
        <div>
          <MessageContainer>
            <FormattedMessage
              id="welcome.send.invalid-token.error.title"
              defaultMessage="We couldn't verify your email"
              description="Telling the user that there is an error and they're not verified. The error is caused if the user tampers with the token."
              tagName="h5"
            />
          </MessageContainer>
          <img src={errorImage} width="99" alt="" margin-top="24px" />
        </div>
      }>
      <p>
        <FormattedHTMLMessage
          id="welcome.send.invalid-token.error.main-action"
          defaultMessage="Something went wrong while trying to validate your email. Try again from a verification email in your inbox."
          description="Telling the user that there was an error while trying to send the welcome link. The error is caused if the user tampers with the token."
        />
      </p>
    </WhiteboxPage>
  );
};

export default WelcomeSentInvalidTokenErrorPage;
