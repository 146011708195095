import { wrapComponent } from '../utilities/wrap-component';
import VerifyEmailSentPageComponent from './VerifyEmailSentPage';
import { State as MainState } from '../reducers';
import ReverifyEmailSentPageComponent from './ReverifyEmailSentPage';
import React from 'react';
import { VerificationType } from '../types';

interface PropsFromState {
  verificationType: VerificationType;
}

export const VerifyOrReverifyEmailSentPage = ({ verificationType }) => {
  return verificationType === VerificationType.Verify ? (
    <VerifyEmailSentPageComponent />
  ) : (
    <ReverifyEmailSentPageComponent />
  );
};

const mapStateToProps = (state: MainState): PropsFromState => ({
  verificationType: state.verifyEmailSent.verificationType,
});

export default wrapComponent<PropsFromState>(
  {
    mapStateToProps,
  },
  VerifyOrReverifyEmailSentPage
);
