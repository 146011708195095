import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, gridSize } from '@atlaskit/theme';
import { InjectedRouterProps } from '../types';

const FormError = styled.div`
  border-radius: 3px;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.28), 0 0 1px rgba(9, 30, 66, 0.3);
  color: ${colors.N700};
  font-size: 12px;
  line-height: 20px;
  margin-bottom: ${gridSize() * 3}px;
  padding: ${gridSize() * 2}px;
  transform: rotateX(0deg);
  transform-origin: 50% 0;
  transition-property: visibility, height, margin-bottom, opacity, transform, padding;
  transition-duration: 0s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out;

  &.hidden {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
    padding: 0 ${gridSize() * 2}px;
    pointer-events: none;
    transform: rotateX(-90deg);
    visibility: hidden;
  }
`;

export default class FormErrorComponent extends Component<{
  id?: string;
  className: string;
  isHidden?: boolean;
  history?: InjectedRouterProps['history'];
}> {
  handleErrorClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = e.target;

    if (el instanceof HTMLAnchorElement && !(e.metaKey || e.shiftKey)) {
      const href = el.getAttribute('href') || '';

      if (href.startsWith('/')) {
        e.preventDefault();
        this.props.history && this.props.history.push(href);
      }
    }
  };

  render() {
    return (
      <FormError
        role="alert"
        {...this.props}
        className={this.props.isHidden ? 'hidden' : ''}
        onClick={this.handleErrorClick}
      />
    );
  }
}
