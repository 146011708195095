import React from 'react';
import styled from '@emotion/styled';
import { media } from '../../utilities/style-utils';
import { FormattedMessage } from 'react-intl';

import { colors, gridSize } from '@atlaskit/theme';
import { AtlassianLogo } from '@atlaskit/logo';
import {
  blueLogo,
  linkStyles,
  listStyles,
  whiteboxWidthDesktop,
  renderProductLogo,
  darkLogo,
  whiteboxWidthHandheld,
  CobrandingLogoStyles,
} from './shared-cobranding';
import { withAnalyticsWebClient } from '../../utilities/analytics/analytics-web-client';
import { InjectedAnalyticsProps } from '../../types';
import { LearnMoreAboutAtlassianAccountLink } from '../links';

const PageFooter = styled.footer<{ textColor: string; footerMessageTextColor: string }>`
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  width: ${whiteboxWidthDesktop}
  padding: ${gridSize() * 3}px 0;
  margin: 0 auto;
  border-top: solid 1px ${colors.N40}
  font-size: 12px;
  color: ${props => props.footerMessageTextColor};
  ${props => linkStyles(props.textColor)}
  ${listStyles}

  ${media.handheld`
     color: ${colors.N200};
     line-height: inherit;
     padding: ${gridSize() * 2}px 0;
     background-color: transparent;
     width: ${whiteboxWidthHandheld}
     ${linkStyles(colors.B400)}
  `}

`;

export interface FooterProps {
  styles: FooterStyles;
  pageId: string;
  renderText: boolean;
  footerProducts: string;
}

export const blueFooter: FooterStyles = {
  ...blueLogo,
  linkColor: blueLogo.textColor,
  footerMessageTextColor: colors.N200,
};

export const darkFooter: FooterStyles = {
  ...darkLogo,
  linkColor: colors.B400,
  footerMessageTextColor: colors.N200,
};

export type FooterStyles = CobrandingLogoStyles & {
  textColor: string;
  linkColor: string;
  footerMessageTextColor: string;
  mobileLogo?: CobrandingLogoStyles;
};

class FooterComponent extends React.Component<FooterProps & InjectedAnalyticsProps> {
  onLinkClicked = () => {
    this.props.analyticsClient.buttonClickedEvent(this.props.pageId, 'learnMoreAtlassianAccount');
  };

  render() {
    const { styles, renderText, footerProducts } = this.props;
    const mobileLogoStyles = styles.mobileLogo || blueLogo;
    return (
      <PageFooter
        textColor={styles.linkColor}
        footerMessageTextColor={styles.footerMessageTextColor}>
        {renderProductLogo({
          component: AtlassianLogo,
          desktopStyles: { ...styles, size: 'medium' },
          mobileStyles: { ...mobileLogoStyles, size: 'small' },
        })}
        {renderText && (
          <FormattedMessage
            id="footer.cobranding.text"
            defaultMessage="One account for {products} and {link}."
            description="{products} is a comma-separated list of products. {link} is a hyperlink that will be filled with a separately translated string: more"
            values={{
              products: footerProducts,
              link: <LearnMoreAboutAtlassianAccountLink onClick={this.onLinkClicked} />,
            }}
          />
        )}
      </PageFooter>
    );
  }
}

export default withAnalyticsWebClient(FooterComponent);
