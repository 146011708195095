import React, { useState } from 'react';
import { wrapComponent } from '../utilities/wrap-component';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import styled from '@emotion/styled';
import { CsrfToken, InjectedAnalyticsProps, InjectedRouterProps, OidcContext } from '../types';
import { PrivacyPolicyLink, UserNoticeLink } from '../components/links';
import { isMobileOidc } from '../utilities/oidc/oidc';
import { State as MainState } from '../reducers/index';
import Account from '../components/Account';
import { UserData } from '../reducers/logout-reducer';
import FormError from '../components/FormError';
import Helmet from 'react-helmet';
import messages from './LogoutPage.messages';
import Bull from '../components/BulletSeparator';
import { usePageViewedEvent } from '../utilities/analytics/analytics-web-client';

interface PropsFromState {
  csrfToken: CsrfToken;
  isEmbedded: boolean | undefined;
  oidcContext: OidcContext | null;
  userData?: UserData;
  formError?: string;
}

interface PropsFromDispatch {}

interface OwnProps {}

type InjectedProps = InjectedAnalyticsProps & InjectedIntlProps & InjectedRouterProps;

type Props = OwnProps & PropsFromState & PropsFromDispatch & InjectedProps;

const AccountWrapper = styled.div`
  margin-top: 8px;
`;

const pageId = 'logoutPage';

export const LogoutPage: React.ComponentType<Props> = props => {
  const { analyticsClient, isEmbedded, oidcContext, userData, formError, intl, location } = props;

  usePageViewedEvent(pageId, {
    isAuthenticatedUser: !!userData,
  });

  const buildFooter = () => {
    return isEmbedded ? undefined : (
      <div>
        <ul className="fine-print">
          <li>
            <PrivacyPolicyLink isMobileLink={isMobileOidc(oidcContext)} />
          </li>
          <Bull />
          <li>
            <UserNoticeLink isMobileLink={isMobileOidc(oidcContext)} />
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.logoutTitle)}</title>
      </Helmet>
      <WhiteboxPage
        pageId={pageId}
        header={
          <FormattedMessage
            id="logout.title"
            defaultMessage="Log out of your Atlassian account"
            tagName="h5"
          />
        }
        footer={buildFooter()}
        whiteboxFooter={
          <a
            href={'/login/select-account' + location.search}
            onClick={() => {
              analyticsClient.linkClickedEvent(pageId, 'selectAccount');
            }}>
            <FormattedMessage
              id="logout.switch-account"
              defaultMessage="Log in to another account"
            />
          </a>
        }>
        {!!formError && (
          <FormError className="form-error" data-testid="form-error">
            {formError}
          </FormError>
        )}
        {!!userData && (
          <AccountWrapper>
            <Account
              primary={true}
              avatarUrl={userData.avatarUrl}
              displayName={userData.displayName}
              email={userData.email}
            />
          </AccountWrapper>
        )}
        <LogoutForm {...props} />
      </WhiteboxPage>
    </>
  );
};

const LogoutForm = ({
  analyticsClient,
  csrfToken,
}: Pick<Props, 'analyticsClient' | 'csrfToken'>) => {
  const [submitting, setSubmitting] = useState(false);

  const registerFormSubmit = () => {
    setSubmitting(true);
    analyticsClient.formSubmittedEvent(pageId, 'logout');
  };

  return (
    <form method="POST" onSubmit={registerFormSubmit} data-testid="logout-form">
      <input
        hidden
        type="text"
        name="csrfToken"
        value={csrfToken}
        data-testid="csrf-token"
        readOnly
      />
      <TallButton
        appearance="primary"
        id="logout-submit"
        type="submit"
        isFullWidth
        isLoading={submitting}
        testId="logout-button">
        <FormattedMessage id="logout.confirm" defaultMessage="Log out" />
      </TallButton>
    </form>
  );
};

const mapStateToProps = (state: MainState): PropsFromState => {
  const {
    csrfToken,
    microbranding: { isEmbedded, oidcContext },
    logout: { userData, formError },
  } = state;
  return {
    csrfToken,
    isEmbedded,
    oidcContext,
    userData,
    formError,
  };
};

export default wrapComponent<PropsFromState, PropsFromDispatch, OwnProps, InjectedProps>(
  {
    mapStateToProps,
    withAnalyticsClient: true,
    withIntl: true,
  },
  LogoutPage
);
